import type { SVGProps } from 'react';
import * as React from 'react';
export const KeyboardLeftColor = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    viewBox="0 0 24 24"
    {...props}>
    <path
      fill="#E9EAF2"
      d="M2 5.74a2 2 0 0 1 .481-1.302l1.92-2.24A2 2 0 0 1 5.92 1.5h11.424a2 2 0 0 1 1.338.513l2.656 2.391A2 2 0 0 1 22 5.891V20.5a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2z"
    />
    <path
      fill="url(#Keyboard-left-color_svg__a)"
      d="M6 5V3.5a2 2 0 0 1 2-2h10.08a2 2 0 0 1 1.519.698l1.92 2.24A2 2 0 0 1 22 5.74V20.5a2 2 0 0 1-2 2H8a2 2 0 0 1-2-2z"
    />
    <path
      fill="url(#Keyboard-left-color_svg__b)"
      d="M4 22.5h16c.693 0 1.303-.353 1.662-.888l-1.884-4.946H4.222l-1.884 4.946c.359.535.97.887 1.662.887"
    />
    <path
      fill="#fff"
      d="M4 3.5a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2z"
    />
    <path
      fill="url(#Keyboard-left-color_svg__c)"
      fillRule="evenodd"
      d="M18 2.5H6a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-12a1 1 0 0 0-1-1m-12-1a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2v-12a2 2 0 0 0-2-2z"
      clipRule="evenodd"
    />
    <path
      fill={props.fill || `currentColor`}
      stroke="#E9EAF2"
      strokeMiterlimit={10}
      d="M13.26 14.101a1 1 0 0 0 1.64-.768V5.468a1 1 0 0 0-1.64-.768L8.541 8.632a1 1 0 0 0 0 1.537z"
    />
    <defs>
      <linearGradient
        id="Keyboard-left-color_svg__a"
        x1={19.5}
        x2={22}
        y1={9.5}
        y2={9.5}
        gradientUnits="userSpaceOnUse">
        <stop stopColor="#B8BECD" />
        <stop offset={1} stopColor="#D5D9E0" />
      </linearGradient>
      <linearGradient
        id="Keyboard-left-color_svg__b"
        x1={12}
        x2={12}
        y1={16.666}
        y2={22.499}
        gradientUnits="userSpaceOnUse">
        <stop stopColor="#A5ACB8" />
        <stop offset={1} stopColor="#BEC3CC" />
      </linearGradient>
      <linearGradient
        id="Keyboard-left-color_svg__c"
        x1={12}
        x2={12}
        y1={1.5}
        y2={17.5}
        gradientUnits="userSpaceOnUse">
        <stop stopColor="#DDDFEA" />
        <stop offset={1} stopColor="#EAECF5" />
      </linearGradient>
    </defs>
  </svg>
);
