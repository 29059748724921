import { PropsWithChildren, SyntheticEvent } from 'react';
import classNames from 'classnames';

export const DialogHeader = ({
  topSlot,
  leftSlot,
  rightSlot,
  className,
  onClose,
  children,
}: PropsWithChildren<DialogHeaderProps>) => (
  <header className={classNames('flex flex-col gap-xs p-md', className)}>
    {topSlot && (
      <span className="flex items-center gap-xs text-xl font-semibold leading-[1.4]">
        {topSlot}
      </span>
    )}
    <div className="flex items-center gap-xs">
      {leftSlot && (
        <div className="flex h-full flex-none items-start gap-[10px] leading-[1]">
          {leftSlot}
        </div>
      )}
      <div className="flex h-full flex-1 items-start gap-[10px] leading-[1]">
        {children}
      </div>
      {(rightSlot || onClose) && (
        <div className="flex h-full flex-none items-start gap-[10px] leading-[1]">
          {rightSlot && rightSlot}
          {onClose && (
            <img
              className="size-[20px] cursor-pointer focus-visible:rounded focus-visible:outline-none"
              onClick={onClose}
              src="/assets/icon_close_gray.svg"
              alt="close"
            />
          )}
        </div>
      )}
    </div>
  </header>
);

type DialogHeaderProps = {
  topSlot?: React.ReactNode;
  leftSlot?: React.ReactNode;
  rightSlot?: React.ReactNode;
  className?: string;
  onClose?: (e: SyntheticEvent) => void;
};
