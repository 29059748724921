import { isAxiosError } from 'axios';
import { useRouter } from 'next/router';

import { clearStorageUser } from '../lib/auth';

export const useApiError = () => {
  const router = useRouter();

  return (error: unknown) => {
    if (isAxiosError(error)) {
      if (error.response?.status === 401) {
        console.warn('token Expired');

        clearStorageUser();
        if (router.isReady) {
          router.push({
            pathname: '/login',
          });
        }
      }
    }
  };
};
