import type { SVGProps } from 'react';
import * as React from 'react';
export const AddSmallSlashRectangleFill = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    viewBox="0 0 24 24"
    {...props}>
    <path
      fill={props.fill || `currentColor`}
      d="M2.707 2.293a1 1 0 0 0 0 1.414l16.97 16.97a1 1 0 0 0 1.415-1.413L4.122 2.293a1 1 0 0 0-1.415 0M3 19V6.05L7.952 11A1 1 0 0 0 8 13h1.95L11 14.05V16a1 1 0 0 0 1.999.048L17.95 21H5a2 2 0 0 1-2-2M6.995 3l4.295 4.295A1 1 0 0 1 13 8v1.005L14.995 11H16a1 1 0 0 1 .704 1.71L21 17.005V5a2 2 0 0 0-2-2z"
    />
  </svg>
);
