import type { SVGProps } from 'react';
import * as React from 'react';
export const ActionColor = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    viewBox="0 0 24 24"
    {...props}>
    <path
      fill="#D9D6FF"
      d="M24 12c0 6.627-5.373 12-12 12S0 18.627 0 12 5.373 0 12 0s12 5.373 12 12"
    />
    <path
      fill="url(#Action-color_svg__a)"
      d="M22 12c0 5.523-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2s10 4.477 10 10"
    />
    <path
      fill="#fff"
      fillOpacity={0.8}
      d="M7.714 13.206 11 8.526a1.215 1.215 0 0 1 2.002 0l3.285 4.68c.66.94-.325 2.17-1.35 1.684l-2.304-1.573c-.468-.261-.787-.261-1.264 0L9.063 14.89c-1.024.486-2.01-.744-1.349-1.684"
    />
    <defs>
      <linearGradient
        id="Action-color_svg__a"
        x1={0}
        x2={27.08}
        y1={0}
        y2={4.216}
        gradientUnits="userSpaceOnUse">
        <stop stopColor="#6758FF" />
        <stop offset={1} stopColor="#AF70FF" />
      </linearGradient>
    </defs>
  </svg>
);
