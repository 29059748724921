import NiceModal from '@ebay/nice-modal-react';
import { CustomAlert, CustomAlertProps } from '@zep/components/modals';

export const useAlert = () => {
  return (content: CustomAlertProps, resolveAction?: () => void) =>
    NiceModal.show(CustomAlert, content).then(() => {
      if (resolveAction) {
        // mobile 웹뷰환경에서 callback ScrollEvent 가 안먹히는 이슈로 타임아웃을 주었습니다
        setTimeout(() => resolveAction(), 150);
      }
    });
};
