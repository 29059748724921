import { PropsWithChildren, ReactNode } from 'react';
import { useIsMounted } from '@toss/react';

export const NoSSR = ({
  children,
  fallback,
}: PropsWithChildren<{ fallback?: ReactNode }>) => {
  const isMounted = useIsMounted();

  if (!isMounted) {
    return fallback ? fallback : null;
  }

  return <>{children}</>;
};
