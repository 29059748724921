import type { SVGProps } from 'react';
import * as React from 'react';
export const CurrencyYenCircleFill = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    viewBox="0 0 24 24"
    {...props}>
    <path
      fill={props.fill || `currentColor`}
      fillRule="evenodd"
      d="M12 2C6.477 2 2 6.477 2 12s4.477 10 10 10 10-4.477 10-10S17.523 2 12 2m1.475 5L12 10.89 10.525 7H8l2.27 5.272H8.496V13.7h2.234v.7H8.496v1.427h2.234V18h2.467v-2.173h2.03V14.4h-2.03V13.7h2.03v-1.428h-1.512L16 7z"
      clipRule="evenodd"
    />
  </svg>
);
