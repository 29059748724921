import { ComponentPropsWithoutRef } from 'react';
import NiceModal from '@ebay/nice-modal-react';
import { NoSSR } from '@zep/components';
import { CodeEnter } from '@zep/components/modals';
import { GnbHeaderShareClass, trackGnbAnalytics } from '@zep/layout/Header';
import { useUser } from '@zep/lib/auth';
import { Button } from '@zep/ui';
import { cn } from '@zep/utils';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';

export const EnterCodeButton = ({ type }: { type: 'drawer' | 'normal' }) => {
  const { t } = useTranslation();

  const handleEnterCodeModalOpen = () => {
    NiceModal.show(CodeEnter);
    trackGnbAnalytics('code', 'landing');
  };

  if (type === 'drawer') {
    return (
      <Button className={'w-full'} onClick={handleEnterCodeModalOpen}>
        <img src="/assets/icon_enter_white.svg" alt="entericon" />
        {t('landing.enterCode')}
      </Button>
    );
  }

  return (
    <Button
      size={'md'}
      intent={'alertNative'}
      onClick={handleEnterCodeModalOpen}>
      {t('landing.enterCode')}
    </Button>
  );
};

export const _ExploreIconButton = () => {
  return (
    <Button intent={'neutral'} transParented size={'sm'} asChild>
      <Link
        prefetch={false}
        href={'/public'}
        onClick={() => trackGnbAnalytics('public', 'Header')}>
        <img src="/assets/icon_magnifying_glass.svg" alt="search" />
      </Link>
    </Button>
  );
};

const _ExploreNormalButton = () => {
  const { t } = useTranslation();

  return (
    <Button intent={'neutral'} asChild>
      <Link
        prefetch={false}
        href={'/public'}
        onClick={() => trackGnbAnalytics('public', 'Header')}>
        {t('common.header.explore')}
      </Link>
    </Button>
  );
};

export const MyQuizButtonDrawer = (
  props: ComponentPropsWithoutRef<'button'>,
) => {
  const { t } = useTranslation();
  return (
    <Button intent={'neutral'} className={'w-full'} asChild {...props}>
      <Link
        prefetch={false}
        href={'/me/quiz'}
        onClick={() => trackGnbAnalytics('mylist', 'Header')}>
        {t('header.quizList.button')}
      </Link>
    </Button>
  );
};

export const _ExploreDrawerButton = (
  props: ComponentPropsWithoutRef<'button'>,
) => {
  const { t } = useTranslation();

  return (
    <Button
      asChild
      intent={'neutral'}
      className={cn('p-0 w-full justify-start')}
      {...props}>
      <Link
        href={'/public'}
        onClick={() => trackGnbAnalytics('public', 'Header')}
        className="px-md py-[13.5px] text-[15px]">
        {t('common.header.explore')}
      </Link>
    </Button>
  );
};

const MyQuizButtonNormal = () => {
  const { t } = useTranslation();

  return (
    <Button
      asChild
      intent={'neutral'}
      className={cn('px-0 flex justify-start h-[36px] w-full  min-h-[36px]')}>
      <Link
        href={'/me/quiz'}
        className="flex items-center justify-start gap-3xs px-2xs py-[10px]"
        onClick={() => trackGnbAnalytics('mylist', 'Header')}>
        <img src="/assets/icon_paper.svg" alt="myquiz" />
        {t('header.quizList.button')}
      </Link>
    </Button>
  );
};

const UserGuideDrawerButton = () => {
  const { isShow, buttonText, goUserGuide } = useGnbUserGuideButtonInfo();

  if (!isShow) return null;

  return (
    <Button className={cn('p-0 w-full justify-start')} onClick={goUserGuide}>
      <span className="px-md py-[13.5px] text-[15px]">{buttonText}</span>
    </Button>
  );
};

const UserGuideNormalButton = () => {
  const { isShow, buttonText, goUserGuide } = useGnbUserGuideButtonInfo();

  if (!isShow) return null;

  return (
    <Button className={GnbHeaderShareClass} onClick={goUserGuide}>
      {buttonText}
    </Button>
  );
};

export const PricingButton = (props: ComponentPropsWithoutRef<'button'>) => {
  const { t } = useTranslation();

  return (
    <Button
      {...props}
      className={cn(GnbHeaderShareClass, props.className)}
      asChild>
      <Link prefetch={false} href={'/pricing'}>
        {t('common.header.pricing')}
      </Link>
    </Button>
  );
};

const useGnbUserGuideButtonInfo = () => {
  const { t, i18n } = useTranslation();
  const goUserGuide = () => window.open('https://docs.channel.io/zepquiz/ko');

  return {
    buttonText: t('common.header.userGuide', '이용 가이드'),
    goUserGuide,
    isShow: i18n.resolvedLanguage?.toLowerCase() === 'ko',
  };
};

export const HeaderAdminButton = () => {
  const router = useRouter();
  const { isAuthenticated, user } = useUser();

  return (
    <NoSSR>
      {isAuthenticated && user?.admin && (
        <Button
          className={cn('p-0 w-fit hidden md:flex')}
          onClick={() => router.push('/admin')}>
          <span className="px-[8.5px] py-sm">{'어드민'}</span>
        </Button>
      )}
    </NoSSR>
  );
};

const _ExploreButton = () => <></>;
const _UserGuideButton = () => <></>;
const _MyQuizButton = () => <></>;

export const ExploreButton = Object.assign(_ExploreButton, {
  Drawer: _ExploreDrawerButton,
  Normal: _ExploreNormalButton,
  Icon: _ExploreIconButton,
});

export const UserGuideButton = Object.assign(_UserGuideButton, {
  Drawer: UserGuideDrawerButton,
  Normal: UserGuideNormalButton,
});

export const MyQuizButton = Object.assign(_MyQuizButton, {
  Drawer: MyQuizButtonDrawer,
  Normal: MyQuizButtonNormal,
});
