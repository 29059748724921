import type { SVGProps } from 'react';
import * as React from 'react';
export const Hammer = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    viewBox="0 0 24 24"
    {...props}>
    <path
      fill={props.fill || `currentColor`}
      fillRule="evenodd"
      d="M18 2.987a2 2 0 0 1 .7 3.71l-2.574 1.485 5.499 9.525a2 2 0 0 1-.732 2.732l-1.732 1a2 2 0 0 1-2.732-.732l-5.5-9.525-.457.264a2 2 0 0 1-2.732-.732l-.046-.08-.408.237.045.078a2 2 0 0 1-.732 2.732l-.866.5A2 2 0 0 1 3 13.449l-1-1.732a2 2 0 0 1 .732-2.732l.866-.5a2 2 0 0 1 2.685.655l.412-.238a2 2 0 0 1 .776-2.652l6.062-3.5a2 2 0 0 1 1.302-.245zm-4.533 4.42-.074.042-1.732 1-.073.044-2.116 1.221-1-1.732 6.063-3.5 3.165.482zm-.805 2.775 5.499 9.525 1.732-1-5.5-9.525zm-8.063.035 1 1.732-.866.5-1-1.732z"
      clipRule="evenodd"
    />
  </svg>
);
