import { useEffect, useState } from 'react';

export const useBodyAttribute = (name: string) => {
  const [value, setValue] = useState<string | null>(null);

  useEffect(() => {
    if (typeof document !== 'undefined') {
      const updateValue = () => {
        setValue(document.body.getAttribute(name));
      };

      const observer = new MutationObserver(updateValue);

      observer.observe(document.body, {
        attributes: true,
        attributeFilter: [name],
      });

      updateValue();

      return () => {
        observer.disconnect();
      };
    }
  }, [name]);

  return value;
};
