/**
 * Generated by orval v7.1.1 🍺
 * Do not edit manually.
 * ZEP QUIZ
 * Zep Quiz API
 * OpenAPI spec version: 0.0.1
 */
import type {
  DefinedInitialDataOptions,
  DefinedUseQueryResult,
  MutationFunction,
  QueryFunction,
  QueryKey,
  UndefinedInitialDataOptions,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
  UseSuspenseQueryOptions,
  UseSuspenseQueryResult,
} from '@tanstack/react-query';
import { useMutation, useQuery, useSuspenseQuery } from '@tanstack/react-query';

import type { ErrorType } from '../../httpClient';
import { httpsInstance } from '../../httpClient';

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

export const modifyWorkspaceOwner = (
  options?: SecondParameter<typeof httpsInstance>,
) => {
  return httpsInstance<void>(
    { url: `/workspaces/users/owner`, method: 'PUT' },
    options,
  );
};

export const getModifyWorkspaceOwnerMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof modifyWorkspaceOwner>>,
    TError,
    void,
    TContext
  >;
  request?: SecondParameter<typeof httpsInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof modifyWorkspaceOwner>>,
  TError,
  void,
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof modifyWorkspaceOwner>>,
    void
  > = () => {
    return modifyWorkspaceOwner(requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type ModifyWorkspaceOwnerMutationResult = NonNullable<
  Awaited<ReturnType<typeof modifyWorkspaceOwner>>
>;

export type ModifyWorkspaceOwnerMutationError = ErrorType<unknown>;

export const useModifyWorkspaceOwner = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof modifyWorkspaceOwner>>,
    TError,
    void,
    TContext
  >;
  request?: SecondParameter<typeof httpsInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof modifyWorkspaceOwner>>,
  TError,
  void,
  TContext
> => {
  const mutationOptions = getModifyWorkspaceOwnerMutationOptions(options);

  return useMutation(mutationOptions);
};
export const inviteWorkspaceUser = (
  options?: SecondParameter<typeof httpsInstance>,
) => {
  return httpsInstance<void>(
    { url: `/workspaces/users/invite`, method: 'POST' },
    options,
  );
};

export const getInviteWorkspaceUserMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof inviteWorkspaceUser>>,
    TError,
    void,
    TContext
  >;
  request?: SecondParameter<typeof httpsInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof inviteWorkspaceUser>>,
  TError,
  void,
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof inviteWorkspaceUser>>,
    void
  > = () => {
    return inviteWorkspaceUser(requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type InviteWorkspaceUserMutationResult = NonNullable<
  Awaited<ReturnType<typeof inviteWorkspaceUser>>
>;

export type InviteWorkspaceUserMutationError = ErrorType<unknown>;

export const useInviteWorkspaceUser = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof inviteWorkspaceUser>>,
    TError,
    void,
    TContext
  >;
  request?: SecondParameter<typeof httpsInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof inviteWorkspaceUser>>,
  TError,
  void,
  TContext
> => {
  const mutationOptions = getInviteWorkspaceUserMutationOptions(options);

  return useMutation(mutationOptions);
};
export const getWorkspaceUsers = (
  options?: SecondParameter<typeof httpsInstance>,
  signal?: AbortSignal,
) => {
  return httpsInstance<void>(
    { url: `/workspaces/users`, method: 'GET', signal },
    options,
  );
};

export const getGetWorkspaceUsersQueryKey = () => {
  return [`/workspaces/users`] as const;
};

export const getGetWorkspaceUsersQueryOptions = <
  TData = Awaited<ReturnType<typeof getWorkspaceUsers>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getWorkspaceUsers>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof httpsInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetWorkspaceUsersQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getWorkspaceUsers>>
  > = ({ signal }) => getWorkspaceUsers(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getWorkspaceUsers>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetWorkspaceUsersQueryResult = NonNullable<
  Awaited<ReturnType<typeof getWorkspaceUsers>>
>;
export type GetWorkspaceUsersQueryError = ErrorType<unknown>;

export function useGetWorkspaceUsers<
  TData = Awaited<ReturnType<typeof getWorkspaceUsers>>,
  TError = ErrorType<unknown>,
>(options: {
  query: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getWorkspaceUsers>>,
      TError,
      TData
    >
  > &
    Pick<
      DefinedInitialDataOptions<
        Awaited<ReturnType<typeof getWorkspaceUsers>>,
        TError,
        TData
      >,
      'initialData'
    >;
  request?: SecondParameter<typeof httpsInstance>;
}): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetWorkspaceUsers<
  TData = Awaited<ReturnType<typeof getWorkspaceUsers>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getWorkspaceUsers>>,
      TError,
      TData
    >
  > &
    Pick<
      UndefinedInitialDataOptions<
        Awaited<ReturnType<typeof getWorkspaceUsers>>,
        TError,
        TData
      >,
      'initialData'
    >;
  request?: SecondParameter<typeof httpsInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetWorkspaceUsers<
  TData = Awaited<ReturnType<typeof getWorkspaceUsers>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getWorkspaceUsers>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof httpsInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useGetWorkspaceUsers<
  TData = Awaited<ReturnType<typeof getWorkspaceUsers>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getWorkspaceUsers>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof httpsInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetWorkspaceUsersQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getGetWorkspaceUsersSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof getWorkspaceUsers>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseSuspenseQueryOptions<
      Awaited<ReturnType<typeof getWorkspaceUsers>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof httpsInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetWorkspaceUsersQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getWorkspaceUsers>>
  > = ({ signal }) => getWorkspaceUsers(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof getWorkspaceUsers>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetWorkspaceUsersSuspenseQueryResult = NonNullable<
  Awaited<ReturnType<typeof getWorkspaceUsers>>
>;
export type GetWorkspaceUsersSuspenseQueryError = ErrorType<unknown>;

export function useGetWorkspaceUsersSuspense<
  TData = Awaited<ReturnType<typeof getWorkspaceUsers>>,
  TError = ErrorType<unknown>,
>(options: {
  query: Partial<
    UseSuspenseQueryOptions<
      Awaited<ReturnType<typeof getWorkspaceUsers>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof httpsInstance>;
}): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetWorkspaceUsersSuspense<
  TData = Awaited<ReturnType<typeof getWorkspaceUsers>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseSuspenseQueryOptions<
      Awaited<ReturnType<typeof getWorkspaceUsers>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof httpsInstance>;
}): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetWorkspaceUsersSuspense<
  TData = Awaited<ReturnType<typeof getWorkspaceUsers>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseSuspenseQueryOptions<
      Awaited<ReturnType<typeof getWorkspaceUsers>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof httpsInstance>;
}): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useGetWorkspaceUsersSuspense<
  TData = Awaited<ReturnType<typeof getWorkspaceUsers>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseSuspenseQueryOptions<
      Awaited<ReturnType<typeof getWorkspaceUsers>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof httpsInstance>;
}): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetWorkspaceUsersSuspenseQueryOptions(options);

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const deleteUserFromWorkspace = (
  options?: SecondParameter<typeof httpsInstance>,
) => {
  return httpsInstance<void>(
    { url: `/workspaces/users`, method: 'DELETE' },
    options,
  );
};

export const getDeleteUserFromWorkspaceMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteUserFromWorkspace>>,
    TError,
    void,
    TContext
  >;
  request?: SecondParameter<typeof httpsInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteUserFromWorkspace>>,
  TError,
  void,
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteUserFromWorkspace>>,
    void
  > = () => {
    return deleteUserFromWorkspace(requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteUserFromWorkspaceMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteUserFromWorkspace>>
>;

export type DeleteUserFromWorkspaceMutationError = ErrorType<unknown>;

export const useDeleteUserFromWorkspace = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteUserFromWorkspace>>,
    TError,
    void,
    TContext
  >;
  request?: SecondParameter<typeof httpsInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof deleteUserFromWorkspace>>,
  TError,
  void,
  TContext
> => {
  const mutationOptions = getDeleteUserFromWorkspaceMutationOptions(options);

  return useMutation(mutationOptions);
};
