import type { SVGProps } from 'react';
import * as React from 'react';
export const ArrowsLeftright = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    viewBox="0 0 24 24"
    {...props}>
    <path
      fill={props.fill || `currentColor`}
      d="M7.881 5.256A.875.875 0 1 1 9.12 6.494L7.987 7.625h9.263a.875.875 0 0 1 0 1.75H7.987l1.132 1.131a.875.875 0 1 1-1.238 1.238L5.256 9.119a.875.875 0 0 1 0-1.238zM16.119 12.256a.875.875 0 1 0-1.238 1.238l1.132 1.131H6.75a.875.875 0 0 0 0 1.75h9.263l-1.132 1.131a.875.875 0 1 0 1.238 1.238l2.625-2.625a.875.875 0 0 0 0-1.238z"
    />
  </svg>
);
