import { ComponentPropsWithoutRef } from 'react';
import { ExclamationmarkSmallTriangleFillIcon } from '@zep/icons';
import { Button } from '@zep/ui';
import { ICON_COLOR } from '@zep/ui/ui-tokens/color';
import { cn } from '@zep/utils';
import { useTranslation } from 'next-i18next';

//www.figma.com/design/2C2ZPxnoDDelvM5zM863RQ/%F0%9F%8F%8C%EF%B8%8F%E2%80%8D%E2%99%82%EF%B8%8F-Quiz-WEB?node-id=8898-41795&m=dev
// 에러 기본 파운더리
export const ErrorBoundaryFallback = (
  props: ComponentPropsWithoutRef<'div'> & { size?: 'sm' | 'md' | 'lg' },
) => {
  const { className, size = 'lg', ...rest } = props;
  const { t, ready } = useTranslation();

  if (!ready) return null;

  const notLoadContent = t(
    'common.error.notLoadContent',
    '요청하신 내용을 불러오지 못했습니다',
  );

  if (size === 'sm') return <ErrorBoundaryFallbackSmall {...props} />;
  if (size === 'md')
    return (
      <ErrorBoundaryFallbackMedium {...props}>
        {notLoadContent}
      </ErrorBoundaryFallbackMedium>
    );

  return (
    <div
      className={cn('flex w-full flex-col items-center gap-md', className)}
      {...rest}>
      <ExclamationmarkSmallTriangleFillIcon
        width={48}
        height={48}
        fill={ICON_COLOR.disabled}
      />
      <span className={'text-heading-lg-extrabold text-default'}>
        {notLoadContent}
      </span>
      <span className={'text-center text-body-lg-medium text-neutral'}>
        {t(
          'common.error.notLoadContentDetail',
          '잠시 후 다시 접속을 시도해 주세요. 문제가 지속될 경우, 우하단 [문의하기]버튼을 눌러 버그를 제보해 주세요.',
        )}
      </span>
      <Button
        className={'mt-xs'}
        size={'lg'}
        onClick={() => window.location.reload()}>
        {t('common.error.reload', '새로고침')}
      </Button>
    </div>
  );
};

const ErrorBoundaryFallbackMedium = (
  props: ComponentPropsWithoutRef<'div'>,
) => {
  const { className, children, ...rest } = props;
  return (
    <div className={'flex w-full justify-center'}>
      <div
        className={cn(
          'w-full max-w-[80%] flex justify-center items-center py-md  bg-neutral rounded-md gap-[10px]',
          'text-alternative text-body-sm-bold',
          className,
        )}
        {...rest}>
        <ExclamationmarkSmallTriangleFillIcon
          size={'lg'}
          fill={ICON_COLOR.disabled}
        />
        {children}
      </div>
    </div>
  );
};

const ErrorBoundaryFallbackSmall = (props: ComponentPropsWithoutRef<'div'>) => {
  const { className, ...rest } = props;

  return (
    <div
      className={cn(
        'w-[94px] p-md flex justify-center items-center rounded-md bg-neutral',
        className,
      )}
      {...rest}>
      <ExclamationmarkSmallTriangleFillIcon
        size={'lg'}
        fill={ICON_COLOR.disabled}
      />
    </div>
  );
};
