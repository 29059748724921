import type { SVGProps } from 'react';
import * as React from 'react';
export const Untitled = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    viewBox="0 0 24 24"
    {...props}>
    <path
      fill={props.fill || `currentColor`}
      fillRule="evenodd"
      d="M11.5 2.004a9.5 9.5 0 1 0 3.702 18.251l3.507.942c1.24.332 2.297-.942 1.741-2.098l-1.086-2.263A9.5 9.5 0 0 0 11.5 2.004m.13 5.865c-.893-.043-1.642.456-1.776 1.263a.933.933 0 1 1-1.841-.307c.332-1.994 2.15-2.895 3.707-2.82.797.037 1.616.324 2.247.913.647.604 1.033 1.47 1.033 2.527 0 1.205-.474 2.099-1.29 2.643-.464.309-.992.474-1.51.555v.068a.933.933 0 0 1-1.867 0v-.933c0-.515.418-.933.934-.933.626 0 1.107-.11 1.407-.31.234-.156.46-.429.46-1.09 0-.577-.198-.936-.441-1.163-.258-.242-.635-.393-1.062-.413m-.363 6.71a1.4 1.4 0 1 0 0 2.8 1.4 1.4 0 0 0 0-2.8"
      clipRule="evenodd"
    />
  </svg>
);
