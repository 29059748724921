/**
 * Generated by orval v7.1.1 🍺
 * Do not edit manually.
 * ZEP QUIZ
 * Zep Quiz API
 * OpenAPI spec version: 0.0.1
 */
import type {
  DefinedInitialDataOptions,
  DefinedUseQueryResult,
  MutationFunction,
  QueryFunction,
  QueryKey,
  UndefinedInitialDataOptions,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
  UseSuspenseQueryOptions,
  UseSuspenseQueryResult,
} from '@tanstack/react-query';
import { useMutation, useQuery, useSuspenseQuery } from '@tanstack/react-query';

import type { ErrorType } from '../../httpClient';
import { httpsInstance } from '../../httpClient';
import type { SuccessResponse } from '.././_model';

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

export const checkGarbageQuizItems = (
  options?: SecondParameter<typeof httpsInstance>,
  signal?: AbortSignal,
) => {
  return httpsInstance<SuccessResponse>(
    { url: `/admin/quizzes/garbage`, method: 'GET', signal },
    options,
  );
};

export const getCheckGarbageQuizItemsQueryKey = () => {
  return [`/admin/quizzes/garbage`] as const;
};

export const getCheckGarbageQuizItemsQueryOptions = <
  TData = Awaited<ReturnType<typeof checkGarbageQuizItems>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof checkGarbageQuizItems>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof httpsInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getCheckGarbageQuizItemsQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof checkGarbageQuizItems>>
  > = ({ signal }) => checkGarbageQuizItems(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof checkGarbageQuizItems>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type CheckGarbageQuizItemsQueryResult = NonNullable<
  Awaited<ReturnType<typeof checkGarbageQuizItems>>
>;
export type CheckGarbageQuizItemsQueryError = ErrorType<unknown>;

export function useCheckGarbageQuizItems<
  TData = Awaited<ReturnType<typeof checkGarbageQuizItems>>,
  TError = ErrorType<unknown>,
>(options: {
  query: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof checkGarbageQuizItems>>,
      TError,
      TData
    >
  > &
    Pick<
      DefinedInitialDataOptions<
        Awaited<ReturnType<typeof checkGarbageQuizItems>>,
        TError,
        TData
      >,
      'initialData'
    >;
  request?: SecondParameter<typeof httpsInstance>;
}): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useCheckGarbageQuizItems<
  TData = Awaited<ReturnType<typeof checkGarbageQuizItems>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof checkGarbageQuizItems>>,
      TError,
      TData
    >
  > &
    Pick<
      UndefinedInitialDataOptions<
        Awaited<ReturnType<typeof checkGarbageQuizItems>>,
        TError,
        TData
      >,
      'initialData'
    >;
  request?: SecondParameter<typeof httpsInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useCheckGarbageQuizItems<
  TData = Awaited<ReturnType<typeof checkGarbageQuizItems>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof checkGarbageQuizItems>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof httpsInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useCheckGarbageQuizItems<
  TData = Awaited<ReturnType<typeof checkGarbageQuizItems>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof checkGarbageQuizItems>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof httpsInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getCheckGarbageQuizItemsQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getCheckGarbageQuizItemsSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof checkGarbageQuizItems>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseSuspenseQueryOptions<
      Awaited<ReturnType<typeof checkGarbageQuizItems>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof httpsInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getCheckGarbageQuizItemsQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof checkGarbageQuizItems>>
  > = ({ signal }) => checkGarbageQuizItems(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof checkGarbageQuizItems>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type CheckGarbageQuizItemsSuspenseQueryResult = NonNullable<
  Awaited<ReturnType<typeof checkGarbageQuizItems>>
>;
export type CheckGarbageQuizItemsSuspenseQueryError = ErrorType<unknown>;

export function useCheckGarbageQuizItemsSuspense<
  TData = Awaited<ReturnType<typeof checkGarbageQuizItems>>,
  TError = ErrorType<unknown>,
>(options: {
  query: Partial<
    UseSuspenseQueryOptions<
      Awaited<ReturnType<typeof checkGarbageQuizItems>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof httpsInstance>;
}): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useCheckGarbageQuizItemsSuspense<
  TData = Awaited<ReturnType<typeof checkGarbageQuizItems>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseSuspenseQueryOptions<
      Awaited<ReturnType<typeof checkGarbageQuizItems>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof httpsInstance>;
}): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useCheckGarbageQuizItemsSuspense<
  TData = Awaited<ReturnType<typeof checkGarbageQuizItems>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseSuspenseQueryOptions<
      Awaited<ReturnType<typeof checkGarbageQuizItems>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof httpsInstance>;
}): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useCheckGarbageQuizItemsSuspense<
  TData = Awaited<ReturnType<typeof checkGarbageQuizItems>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseSuspenseQueryOptions<
      Awaited<ReturnType<typeof checkGarbageQuizItems>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof httpsInstance>;
}): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getCheckGarbageQuizItemsSuspenseQueryOptions(options);

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const removeGarbageQuizItems = (
  options?: SecondParameter<typeof httpsInstance>,
) => {
  return httpsInstance<SuccessResponse>(
    { url: `/admin/quizzes/garbage`, method: 'DELETE' },
    options,
  );
};

export const getRemoveGarbageQuizItemsMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof removeGarbageQuizItems>>,
    TError,
    void,
    TContext
  >;
  request?: SecondParameter<typeof httpsInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof removeGarbageQuizItems>>,
  TError,
  void,
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof removeGarbageQuizItems>>,
    void
  > = () => {
    return removeGarbageQuizItems(requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type RemoveGarbageQuizItemsMutationResult = NonNullable<
  Awaited<ReturnType<typeof removeGarbageQuizItems>>
>;

export type RemoveGarbageQuizItemsMutationError = ErrorType<unknown>;

export const useRemoveGarbageQuizItems = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof removeGarbageQuizItems>>,
    TError,
    void,
    TContext
  >;
  request?: SecondParameter<typeof httpsInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof removeGarbageQuizItems>>,
  TError,
  void,
  TContext
> => {
  const mutationOptions = getRemoveGarbageQuizItemsMutationOptions(options);

  return useMutation(mutationOptions);
};
