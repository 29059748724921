import type { SVGProps } from 'react';
import * as React from 'react';
export const WindowDown = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    viewBox="0 0 24 24"
    {...props}>
    <path
      fill={props.fill || `currentColor`}
      d="M4 21a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h16a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2zm16-2v-4H4v4zM4 13h16V5H4zm6 4a1 1 0 1 0-2 0 1 1 0 0 0 2 0m-4-1a1 1 0 1 1 0 2 1 1 0 0 1 0-2"
    />
  </svg>
);
