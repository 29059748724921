import React, { ComponentPropsWithoutRef, ReactNode, useRef } from 'react';
import * as SelectPrimitive from '@radix-ui/react-select';
import { useBooleanState } from '@toss/react';
import * as Multiple from '@zep/ui/components/multiSelect';

type DefaultMultiProps = ComponentPropsWithoutRef<typeof Multiple.Select>;

const MultiSelectComponent = (props: DefaultMultiProps & MultiSelectProps) => {
  const [isOpen, handleOpen, handleClose] = useBooleanState(false);
  const ref = useRef<React.ElementRef<typeof SelectPrimitive.Trigger>>(null);

  const values = props.values || [];

  const SelectValues = props.options
    .filter(d => (values || []).includes(d.value))
    .map(d => d.text)
    .join(', ');

  return (
    <Multiple.Select {...props} open={isOpen} onOpenChange={handleOpen}>
      <Multiple.SelectTrigger
        ref={ref}
        className={props.className}
        open={isOpen}>
        <Multiple.SelectValues
          placeholder={props.placeholder}
          text={
            SelectValues ? (
              <div
                style={{
                  maxWidth: (ref.current?.clientWidth || 0) - 50,
                }}
                className={'truncate text-[16px]'}>
                {SelectValues}
              </div>
            ) : undefined
          }
        />
      </Multiple.SelectTrigger>
      <Multiple.SelectContent
        style={{
          width: ref.current?.clientWidth || 0,
        }}
        open={isOpen}
        handleClose={handleClose}>
        {props.options.map((option, i) => (
          <Multiple.SelectItem
            key={i}
            disabled={
              props.values.length === props.maxLength &&
              !values.includes(option.value)
            }
            value={option.value}
            values={values}>
            {option.text}
          </Multiple.SelectItem>
        ))}
      </Multiple.SelectContent>
    </Multiple.Select>
  );
};

export type MultiSelectProps = {
  values: string[];
  options: { text: ReactNode; value: string }[];
  onValuesChange: (values: string[]) => void;
  maxLength: number;
  className?: string;
  placeholder?: string;
};

export const MultiSelect = (props: MultiSelectProps) => {
  return <MultiSelectComponent className={'w-full'} {...props} />;
};
