import type { SVGProps } from 'react';
import * as React from 'react';
export const HouseFill = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    viewBox="0 0 24 24"
    {...props}>
    <path
      fill={props.fill || `currentColor`}
      d="M12.664 2.253a1 1 0 0 0-1.328 0l-9 8a1 1 0 1 0 1.328 1.494L4 11.45V19a2 2 0 0 0 2 2h4v-6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v6h4a2 2 0 0 0 2-2v-7.55l.336.297a1 1 0 0 0 1.328-1.494z"
    />
  </svg>
);
