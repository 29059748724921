/**
 * Generated by orval v7.1.1 🍺
 * Do not edit manually.
 * ZEP QUIZ
 * Zep Quiz API
 * OpenAPI spec version: 0.0.1
 */

export type WorkspaceUserDtoRole =
  (typeof WorkspaceUserDtoRole)[keyof typeof WorkspaceUserDtoRole];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const WorkspaceUserDtoRole = {
  UNKNOWN: 'UNKNOWN',
  MEMBER: 'MEMBER',
  OWNER: 'OWNER',
} as const;
