/**
 * Generated by orval v7.1.1 🍺
 * Do not edit manually.
 * ZEP QUIZ
 * Zep Quiz API
 * OpenAPI spec version: 0.0.1
 */
import type {
  DefinedInitialDataOptions,
  DefinedUseQueryResult,
  MutationFunction,
  QueryFunction,
  QueryKey,
  UndefinedInitialDataOptions,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
  UseSuspenseQueryOptions,
  UseSuspenseQueryResult,
} from '@tanstack/react-query';
import { useMutation, useQuery, useSuspenseQuery } from '@tanstack/react-query';

import type { ErrorType } from '../../httpClient';
import { httpsInstance } from '../../httpClient';
import type { BaseResponseGetQuizPlanOptionsResponse } from '.././_model';

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

export const updateQuizPlan = (
  options?: SecondParameter<typeof httpsInstance>,
) => {
  return httpsInstance<void>({ url: `/quiz-plans`, method: 'PUT' }, options);
};

export const getUpdateQuizPlanMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateQuizPlan>>,
    TError,
    void,
    TContext
  >;
  request?: SecondParameter<typeof httpsInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateQuizPlan>>,
  TError,
  void,
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateQuizPlan>>,
    void
  > = () => {
    return updateQuizPlan(requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateQuizPlanMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateQuizPlan>>
>;

export type UpdateQuizPlanMutationError = ErrorType<unknown>;

export const useUpdateQuizPlan = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateQuizPlan>>,
    TError,
    void,
    TContext
  >;
  request?: SecondParameter<typeof httpsInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof updateQuizPlan>>,
  TError,
  void,
  TContext
> => {
  const mutationOptions = getUpdateQuizPlanMutationOptions(options);

  return useMutation(mutationOptions);
};
export const createQuizPlan = (
  options?: SecondParameter<typeof httpsInstance>,
) => {
  return httpsInstance<void>({ url: `/quiz-plans`, method: 'POST' }, options);
};

export const getCreateQuizPlanMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createQuizPlan>>,
    TError,
    void,
    TContext
  >;
  request?: SecondParameter<typeof httpsInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof createQuizPlan>>,
  TError,
  void,
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createQuizPlan>>,
    void
  > = () => {
    return createQuizPlan(requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type CreateQuizPlanMutationResult = NonNullable<
  Awaited<ReturnType<typeof createQuizPlan>>
>;

export type CreateQuizPlanMutationError = ErrorType<unknown>;

export const useCreateQuizPlan = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createQuizPlan>>,
    TError,
    void,
    TContext
  >;
  request?: SecondParameter<typeof httpsInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof createQuizPlan>>,
  TError,
  void,
  TContext
> => {
  const mutationOptions = getCreateQuizPlanMutationOptions(options);

  return useMutation(mutationOptions);
};
export const createQuizPlanQuotation = (
  options?: SecondParameter<typeof httpsInstance>,
) => {
  return httpsInstance<void>(
    { url: `/quiz-plans/quotation`, method: 'POST' },
    options,
  );
};

export const getCreateQuizPlanQuotationMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createQuizPlanQuotation>>,
    TError,
    void,
    TContext
  >;
  request?: SecondParameter<typeof httpsInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof createQuizPlanQuotation>>,
  TError,
  void,
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createQuizPlanQuotation>>,
    void
  > = () => {
    return createQuizPlanQuotation(requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type CreateQuizPlanQuotationMutationResult = NonNullable<
  Awaited<ReturnType<typeof createQuizPlanQuotation>>
>;

export type CreateQuizPlanQuotationMutationError = ErrorType<unknown>;

export const useCreateQuizPlanQuotation = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createQuizPlanQuotation>>,
    TError,
    void,
    TContext
  >;
  request?: SecondParameter<typeof httpsInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof createQuizPlanQuotation>>,
  TError,
  void,
  TContext
> => {
  const mutationOptions = getCreateQuizPlanQuotationMutationOptions(options);

  return useMutation(mutationOptions);
};
export const getFreeTrialPlan = (
  options?: SecondParameter<typeof httpsInstance>,
  signal?: AbortSignal,
) => {
  return httpsInstance<string>(
    { url: `/quiz-plans/free-trial`, method: 'GET', signal },
    options,
  );
};

export const getGetFreeTrialPlanQueryKey = () => {
  return [`/quiz-plans/free-trial`] as const;
};

export const getGetFreeTrialPlanQueryOptions = <
  TData = Awaited<ReturnType<typeof getFreeTrialPlan>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof getFreeTrialPlan>>, TError, TData>
  >;
  request?: SecondParameter<typeof httpsInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetFreeTrialPlanQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getFreeTrialPlan>>
  > = ({ signal }) => getFreeTrialPlan(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getFreeTrialPlan>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetFreeTrialPlanQueryResult = NonNullable<
  Awaited<ReturnType<typeof getFreeTrialPlan>>
>;
export type GetFreeTrialPlanQueryError = ErrorType<unknown>;

export function useGetFreeTrialPlan<
  TData = Awaited<ReturnType<typeof getFreeTrialPlan>>,
  TError = ErrorType<unknown>,
>(options: {
  query: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof getFreeTrialPlan>>, TError, TData>
  > &
    Pick<
      DefinedInitialDataOptions<
        Awaited<ReturnType<typeof getFreeTrialPlan>>,
        TError,
        TData
      >,
      'initialData'
    >;
  request?: SecondParameter<typeof httpsInstance>;
}): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetFreeTrialPlan<
  TData = Awaited<ReturnType<typeof getFreeTrialPlan>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof getFreeTrialPlan>>, TError, TData>
  > &
    Pick<
      UndefinedInitialDataOptions<
        Awaited<ReturnType<typeof getFreeTrialPlan>>,
        TError,
        TData
      >,
      'initialData'
    >;
  request?: SecondParameter<typeof httpsInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetFreeTrialPlan<
  TData = Awaited<ReturnType<typeof getFreeTrialPlan>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof getFreeTrialPlan>>, TError, TData>
  >;
  request?: SecondParameter<typeof httpsInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useGetFreeTrialPlan<
  TData = Awaited<ReturnType<typeof getFreeTrialPlan>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof getFreeTrialPlan>>, TError, TData>
  >;
  request?: SecondParameter<typeof httpsInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetFreeTrialPlanQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getGetFreeTrialPlanSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof getFreeTrialPlan>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseSuspenseQueryOptions<
      Awaited<ReturnType<typeof getFreeTrialPlan>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof httpsInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetFreeTrialPlanQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getFreeTrialPlan>>
  > = ({ signal }) => getFreeTrialPlan(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof getFreeTrialPlan>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetFreeTrialPlanSuspenseQueryResult = NonNullable<
  Awaited<ReturnType<typeof getFreeTrialPlan>>
>;
export type GetFreeTrialPlanSuspenseQueryError = ErrorType<unknown>;

export function useGetFreeTrialPlanSuspense<
  TData = Awaited<ReturnType<typeof getFreeTrialPlan>>,
  TError = ErrorType<unknown>,
>(options: {
  query: Partial<
    UseSuspenseQueryOptions<
      Awaited<ReturnType<typeof getFreeTrialPlan>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof httpsInstance>;
}): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetFreeTrialPlanSuspense<
  TData = Awaited<ReturnType<typeof getFreeTrialPlan>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseSuspenseQueryOptions<
      Awaited<ReturnType<typeof getFreeTrialPlan>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof httpsInstance>;
}): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetFreeTrialPlanSuspense<
  TData = Awaited<ReturnType<typeof getFreeTrialPlan>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseSuspenseQueryOptions<
      Awaited<ReturnType<typeof getFreeTrialPlan>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof httpsInstance>;
}): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useGetFreeTrialPlanSuspense<
  TData = Awaited<ReturnType<typeof getFreeTrialPlan>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseSuspenseQueryOptions<
      Awaited<ReturnType<typeof getFreeTrialPlan>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof httpsInstance>;
}): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetFreeTrialPlanSuspenseQueryOptions(options);

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const createFreeTrialPlan = (
  options?: SecondParameter<typeof httpsInstance>,
) => {
  return httpsInstance<string>(
    { url: `/quiz-plans/free-trial`, method: 'POST' },
    options,
  );
};

export const getCreateFreeTrialPlanMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createFreeTrialPlan>>,
    TError,
    void,
    TContext
  >;
  request?: SecondParameter<typeof httpsInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof createFreeTrialPlan>>,
  TError,
  void,
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createFreeTrialPlan>>,
    void
  > = () => {
    return createFreeTrialPlan(requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type CreateFreeTrialPlanMutationResult = NonNullable<
  Awaited<ReturnType<typeof createFreeTrialPlan>>
>;

export type CreateFreeTrialPlanMutationError = ErrorType<unknown>;

export const useCreateFreeTrialPlan = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createFreeTrialPlan>>,
    TError,
    void,
    TContext
  >;
  request?: SecondParameter<typeof httpsInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof createFreeTrialPlan>>,
  TError,
  void,
  TContext
> => {
  const mutationOptions = getCreateFreeTrialPlanMutationOptions(options);

  return useMutation(mutationOptions);
};
export const getQuizPlanQuotationPdf = (
  options?: SecondParameter<typeof httpsInstance>,
  signal?: AbortSignal,
) => {
  return httpsInstance<void>(
    { url: `/quiz-plans/quotations/pdf`, method: 'GET', signal },
    options,
  );
};

export const getGetQuizPlanQuotationPdfQueryKey = () => {
  return [`/quiz-plans/quotations/pdf`] as const;
};

export const getGetQuizPlanQuotationPdfQueryOptions = <
  TData = Awaited<ReturnType<typeof getQuizPlanQuotationPdf>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getQuizPlanQuotationPdf>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof httpsInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetQuizPlanQuotationPdfQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getQuizPlanQuotationPdf>>
  > = ({ signal }) => getQuizPlanQuotationPdf(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getQuizPlanQuotationPdf>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetQuizPlanQuotationPdfQueryResult = NonNullable<
  Awaited<ReturnType<typeof getQuizPlanQuotationPdf>>
>;
export type GetQuizPlanQuotationPdfQueryError = ErrorType<unknown>;

export function useGetQuizPlanQuotationPdf<
  TData = Awaited<ReturnType<typeof getQuizPlanQuotationPdf>>,
  TError = ErrorType<unknown>,
>(options: {
  query: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getQuizPlanQuotationPdf>>,
      TError,
      TData
    >
  > &
    Pick<
      DefinedInitialDataOptions<
        Awaited<ReturnType<typeof getQuizPlanQuotationPdf>>,
        TError,
        TData
      >,
      'initialData'
    >;
  request?: SecondParameter<typeof httpsInstance>;
}): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetQuizPlanQuotationPdf<
  TData = Awaited<ReturnType<typeof getQuizPlanQuotationPdf>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getQuizPlanQuotationPdf>>,
      TError,
      TData
    >
  > &
    Pick<
      UndefinedInitialDataOptions<
        Awaited<ReturnType<typeof getQuizPlanQuotationPdf>>,
        TError,
        TData
      >,
      'initialData'
    >;
  request?: SecondParameter<typeof httpsInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetQuizPlanQuotationPdf<
  TData = Awaited<ReturnType<typeof getQuizPlanQuotationPdf>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getQuizPlanQuotationPdf>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof httpsInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useGetQuizPlanQuotationPdf<
  TData = Awaited<ReturnType<typeof getQuizPlanQuotationPdf>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getQuizPlanQuotationPdf>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof httpsInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetQuizPlanQuotationPdfQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getGetQuizPlanQuotationPdfSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof getQuizPlanQuotationPdf>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseSuspenseQueryOptions<
      Awaited<ReturnType<typeof getQuizPlanQuotationPdf>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof httpsInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetQuizPlanQuotationPdfQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getQuizPlanQuotationPdf>>
  > = ({ signal }) => getQuizPlanQuotationPdf(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof getQuizPlanQuotationPdf>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetQuizPlanQuotationPdfSuspenseQueryResult = NonNullable<
  Awaited<ReturnType<typeof getQuizPlanQuotationPdf>>
>;
export type GetQuizPlanQuotationPdfSuspenseQueryError = ErrorType<unknown>;

export function useGetQuizPlanQuotationPdfSuspense<
  TData = Awaited<ReturnType<typeof getQuizPlanQuotationPdf>>,
  TError = ErrorType<unknown>,
>(options: {
  query: Partial<
    UseSuspenseQueryOptions<
      Awaited<ReturnType<typeof getQuizPlanQuotationPdf>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof httpsInstance>;
}): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetQuizPlanQuotationPdfSuspense<
  TData = Awaited<ReturnType<typeof getQuizPlanQuotationPdf>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseSuspenseQueryOptions<
      Awaited<ReturnType<typeof getQuizPlanQuotationPdf>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof httpsInstance>;
}): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetQuizPlanQuotationPdfSuspense<
  TData = Awaited<ReturnType<typeof getQuizPlanQuotationPdf>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseSuspenseQueryOptions<
      Awaited<ReturnType<typeof getQuizPlanQuotationPdf>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof httpsInstance>;
}): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useGetQuizPlanQuotationPdfSuspense<
  TData = Awaited<ReturnType<typeof getQuizPlanQuotationPdf>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseSuspenseQueryOptions<
      Awaited<ReturnType<typeof getQuizPlanQuotationPdf>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof httpsInstance>;
}): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetQuizPlanQuotationPdfSuspenseQueryOptions(options);

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getQuizPlanPrice = (
  options?: SecondParameter<typeof httpsInstance>,
  signal?: AbortSignal,
) => {
  return httpsInstance<void>(
    { url: `/quiz-plans/price`, method: 'GET', signal },
    options,
  );
};

export const getGetQuizPlanPriceQueryKey = () => {
  return [`/quiz-plans/price`] as const;
};

export const getGetQuizPlanPriceQueryOptions = <
  TData = Awaited<ReturnType<typeof getQuizPlanPrice>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof getQuizPlanPrice>>, TError, TData>
  >;
  request?: SecondParameter<typeof httpsInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetQuizPlanPriceQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getQuizPlanPrice>>
  > = ({ signal }) => getQuizPlanPrice(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getQuizPlanPrice>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetQuizPlanPriceQueryResult = NonNullable<
  Awaited<ReturnType<typeof getQuizPlanPrice>>
>;
export type GetQuizPlanPriceQueryError = ErrorType<unknown>;

export function useGetQuizPlanPrice<
  TData = Awaited<ReturnType<typeof getQuizPlanPrice>>,
  TError = ErrorType<unknown>,
>(options: {
  query: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof getQuizPlanPrice>>, TError, TData>
  > &
    Pick<
      DefinedInitialDataOptions<
        Awaited<ReturnType<typeof getQuizPlanPrice>>,
        TError,
        TData
      >,
      'initialData'
    >;
  request?: SecondParameter<typeof httpsInstance>;
}): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetQuizPlanPrice<
  TData = Awaited<ReturnType<typeof getQuizPlanPrice>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof getQuizPlanPrice>>, TError, TData>
  > &
    Pick<
      UndefinedInitialDataOptions<
        Awaited<ReturnType<typeof getQuizPlanPrice>>,
        TError,
        TData
      >,
      'initialData'
    >;
  request?: SecondParameter<typeof httpsInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetQuizPlanPrice<
  TData = Awaited<ReturnType<typeof getQuizPlanPrice>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof getQuizPlanPrice>>, TError, TData>
  >;
  request?: SecondParameter<typeof httpsInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useGetQuizPlanPrice<
  TData = Awaited<ReturnType<typeof getQuizPlanPrice>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof getQuizPlanPrice>>, TError, TData>
  >;
  request?: SecondParameter<typeof httpsInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetQuizPlanPriceQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getGetQuizPlanPriceSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof getQuizPlanPrice>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseSuspenseQueryOptions<
      Awaited<ReturnType<typeof getQuizPlanPrice>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof httpsInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetQuizPlanPriceQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getQuizPlanPrice>>
  > = ({ signal }) => getQuizPlanPrice(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof getQuizPlanPrice>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetQuizPlanPriceSuspenseQueryResult = NonNullable<
  Awaited<ReturnType<typeof getQuizPlanPrice>>
>;
export type GetQuizPlanPriceSuspenseQueryError = ErrorType<unknown>;

export function useGetQuizPlanPriceSuspense<
  TData = Awaited<ReturnType<typeof getQuizPlanPrice>>,
  TError = ErrorType<unknown>,
>(options: {
  query: Partial<
    UseSuspenseQueryOptions<
      Awaited<ReturnType<typeof getQuizPlanPrice>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof httpsInstance>;
}): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetQuizPlanPriceSuspense<
  TData = Awaited<ReturnType<typeof getQuizPlanPrice>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseSuspenseQueryOptions<
      Awaited<ReturnType<typeof getQuizPlanPrice>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof httpsInstance>;
}): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetQuizPlanPriceSuspense<
  TData = Awaited<ReturnType<typeof getQuizPlanPrice>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseSuspenseQueryOptions<
      Awaited<ReturnType<typeof getQuizPlanPrice>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof httpsInstance>;
}): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useGetQuizPlanPriceSuspense<
  TData = Awaited<ReturnType<typeof getQuizPlanPrice>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseSuspenseQueryOptions<
      Awaited<ReturnType<typeof getQuizPlanPrice>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof httpsInstance>;
}): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetQuizPlanPriceSuspenseQueryOptions(options);

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getQuizPlanTemplates = (
  options?: SecondParameter<typeof httpsInstance>,
  signal?: AbortSignal,
) => {
  return httpsInstance<void>(
    { url: `/quiz-plan-templates`, method: 'GET', signal },
    options,
  );
};

export const getGetQuizPlanTemplatesQueryKey = () => {
  return [`/quiz-plan-templates`] as const;
};

export const getGetQuizPlanTemplatesQueryOptions = <
  TData = Awaited<ReturnType<typeof getQuizPlanTemplates>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getQuizPlanTemplates>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof httpsInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetQuizPlanTemplatesQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getQuizPlanTemplates>>
  > = ({ signal }) => getQuizPlanTemplates(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getQuizPlanTemplates>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetQuizPlanTemplatesQueryResult = NonNullable<
  Awaited<ReturnType<typeof getQuizPlanTemplates>>
>;
export type GetQuizPlanTemplatesQueryError = ErrorType<unknown>;

export function useGetQuizPlanTemplates<
  TData = Awaited<ReturnType<typeof getQuizPlanTemplates>>,
  TError = ErrorType<unknown>,
>(options: {
  query: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getQuizPlanTemplates>>,
      TError,
      TData
    >
  > &
    Pick<
      DefinedInitialDataOptions<
        Awaited<ReturnType<typeof getQuizPlanTemplates>>,
        TError,
        TData
      >,
      'initialData'
    >;
  request?: SecondParameter<typeof httpsInstance>;
}): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetQuizPlanTemplates<
  TData = Awaited<ReturnType<typeof getQuizPlanTemplates>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getQuizPlanTemplates>>,
      TError,
      TData
    >
  > &
    Pick<
      UndefinedInitialDataOptions<
        Awaited<ReturnType<typeof getQuizPlanTemplates>>,
        TError,
        TData
      >,
      'initialData'
    >;
  request?: SecondParameter<typeof httpsInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetQuizPlanTemplates<
  TData = Awaited<ReturnType<typeof getQuizPlanTemplates>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getQuizPlanTemplates>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof httpsInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useGetQuizPlanTemplates<
  TData = Awaited<ReturnType<typeof getQuizPlanTemplates>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getQuizPlanTemplates>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof httpsInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetQuizPlanTemplatesQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getGetQuizPlanTemplatesSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof getQuizPlanTemplates>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseSuspenseQueryOptions<
      Awaited<ReturnType<typeof getQuizPlanTemplates>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof httpsInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetQuizPlanTemplatesQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getQuizPlanTemplates>>
  > = ({ signal }) => getQuizPlanTemplates(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof getQuizPlanTemplates>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetQuizPlanTemplatesSuspenseQueryResult = NonNullable<
  Awaited<ReturnType<typeof getQuizPlanTemplates>>
>;
export type GetQuizPlanTemplatesSuspenseQueryError = ErrorType<unknown>;

export function useGetQuizPlanTemplatesSuspense<
  TData = Awaited<ReturnType<typeof getQuizPlanTemplates>>,
  TError = ErrorType<unknown>,
>(options: {
  query: Partial<
    UseSuspenseQueryOptions<
      Awaited<ReturnType<typeof getQuizPlanTemplates>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof httpsInstance>;
}): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetQuizPlanTemplatesSuspense<
  TData = Awaited<ReturnType<typeof getQuizPlanTemplates>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseSuspenseQueryOptions<
      Awaited<ReturnType<typeof getQuizPlanTemplates>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof httpsInstance>;
}): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetQuizPlanTemplatesSuspense<
  TData = Awaited<ReturnType<typeof getQuizPlanTemplates>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseSuspenseQueryOptions<
      Awaited<ReturnType<typeof getQuizPlanTemplates>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof httpsInstance>;
}): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useGetQuizPlanTemplatesSuspense<
  TData = Awaited<ReturnType<typeof getQuizPlanTemplates>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseSuspenseQueryOptions<
      Awaited<ReturnType<typeof getQuizPlanTemplates>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof httpsInstance>;
}): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetQuizPlanTemplatesSuspenseQueryOptions(options);

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getQuizPlanOptions = (
  options?: SecondParameter<typeof httpsInstance>,
  signal?: AbortSignal,
) => {
  return httpsInstance<BaseResponseGetQuizPlanOptionsResponse>(
    { url: `/quiz-plan-options`, method: 'GET', signal },
    options,
  );
};

export const getGetQuizPlanOptionsQueryKey = () => {
  return [`/quiz-plan-options`] as const;
};

export const getGetQuizPlanOptionsQueryOptions = <
  TData = Awaited<ReturnType<typeof getQuizPlanOptions>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getQuizPlanOptions>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof httpsInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetQuizPlanOptionsQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getQuizPlanOptions>>
  > = ({ signal }) => getQuizPlanOptions(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getQuizPlanOptions>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetQuizPlanOptionsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getQuizPlanOptions>>
>;
export type GetQuizPlanOptionsQueryError = ErrorType<unknown>;

export function useGetQuizPlanOptions<
  TData = Awaited<ReturnType<typeof getQuizPlanOptions>>,
  TError = ErrorType<unknown>,
>(options: {
  query: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getQuizPlanOptions>>,
      TError,
      TData
    >
  > &
    Pick<
      DefinedInitialDataOptions<
        Awaited<ReturnType<typeof getQuizPlanOptions>>,
        TError,
        TData
      >,
      'initialData'
    >;
  request?: SecondParameter<typeof httpsInstance>;
}): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetQuizPlanOptions<
  TData = Awaited<ReturnType<typeof getQuizPlanOptions>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getQuizPlanOptions>>,
      TError,
      TData
    >
  > &
    Pick<
      UndefinedInitialDataOptions<
        Awaited<ReturnType<typeof getQuizPlanOptions>>,
        TError,
        TData
      >,
      'initialData'
    >;
  request?: SecondParameter<typeof httpsInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetQuizPlanOptions<
  TData = Awaited<ReturnType<typeof getQuizPlanOptions>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getQuizPlanOptions>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof httpsInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useGetQuizPlanOptions<
  TData = Awaited<ReturnType<typeof getQuizPlanOptions>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getQuizPlanOptions>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof httpsInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetQuizPlanOptionsQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getGetQuizPlanOptionsSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof getQuizPlanOptions>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseSuspenseQueryOptions<
      Awaited<ReturnType<typeof getQuizPlanOptions>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof httpsInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetQuizPlanOptionsQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getQuizPlanOptions>>
  > = ({ signal }) => getQuizPlanOptions(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof getQuizPlanOptions>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetQuizPlanOptionsSuspenseQueryResult = NonNullable<
  Awaited<ReturnType<typeof getQuizPlanOptions>>
>;
export type GetQuizPlanOptionsSuspenseQueryError = ErrorType<unknown>;

export function useGetQuizPlanOptionsSuspense<
  TData = Awaited<ReturnType<typeof getQuizPlanOptions>>,
  TError = ErrorType<unknown>,
>(options: {
  query: Partial<
    UseSuspenseQueryOptions<
      Awaited<ReturnType<typeof getQuizPlanOptions>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof httpsInstance>;
}): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetQuizPlanOptionsSuspense<
  TData = Awaited<ReturnType<typeof getQuizPlanOptions>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseSuspenseQueryOptions<
      Awaited<ReturnType<typeof getQuizPlanOptions>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof httpsInstance>;
}): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetQuizPlanOptionsSuspense<
  TData = Awaited<ReturnType<typeof getQuizPlanOptions>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseSuspenseQueryOptions<
      Awaited<ReturnType<typeof getQuizPlanOptions>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof httpsInstance>;
}): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useGetQuizPlanOptionsSuspense<
  TData = Awaited<ReturnType<typeof getQuizPlanOptions>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseSuspenseQueryOptions<
      Awaited<ReturnType<typeof getQuizPlanOptions>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof httpsInstance>;
}): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetQuizPlanOptionsSuspenseQueryOptions(options);

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}
