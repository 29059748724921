import { DependencyList, useEffect } from 'react';

/**
 * @description body 엘리먼트에 attribute 혹은 className 을 추가하거나 제거합니다.
 */
export const useBody = (
  {
    attributes,
    classNames,
  }: {
    attributes?: {
      name: string;
      value: string;
    }[];
    classNames?: string[];
  },
  deps?: DependencyList,
) => {
  useEffect(() => {
    if (!attributes) return;
    attributes.forEach(({ name, value }) => {
      document.body.setAttribute(name, value);
    });

    return () => {
      attributes.forEach(({ name }) => {
        document.body.removeAttribute(name);
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);

  useEffect(() => {
    if (!classNames) return;
    classNames.forEach(className => {
      const hasClassName = document.body.classList.contains(className);
      if (hasClassName) {
        document.body.classList.remove(className);
      } else {
        document.body.classList.add(className);
      }
    });

    return () => {
      classNames.forEach(className => {
        document.body.classList.remove(className);
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
};
