import type { SVGProps } from 'react';
import * as React from 'react';
export const RectangleStackFill = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    viewBox="0 0 24 24"
    {...props}>
    <path
      fill={props.fill || `currentColor`}
      d="M2 5a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v9a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2z"
    />
    <path
      fill={props.fill || `currentColor`}
      d="M6 17.5V19a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2v-9a2 2 0 0 0-2-2h-.5v7c0 1.105-1.395 2.5-2.5 2.5z"
    />
  </svg>
);
