import { ComponentType, FC } from 'react';
import { useUser } from '@zep/lib/auth';
import { useRouter } from 'next/router';

export function withAuth<P extends object>(
  WrappedComponent: ComponentType<P>,
  callbackUrl: string = '',
  redirectPath: string = '/login',
) {
  const HOC: FC<P> = (props: any) => {
    const router = useRouter();
    const { isAuthenticated, isInitialization } = useUser();

    if (router.isReady && isInitialization) {
      if (!isAuthenticated) {
        router.push(
          {
            pathname: redirectPath,
            query: { callbackUrl },
          },
          undefined,
        );
        return null;
      }
    }

    return <WrappedComponent {...(props as P)} />;
  };

  return HOC;
}
