import { PropsWithChildren, ReactElement } from 'react';

import { StickyBanner } from '../components/StickyBanner/';

import { GnbHeader } from './';

const RootLayout = (props: PropsWithChildren) => {
  return (
    <>
      <StickyBanner />
      <GnbHeader />
      {props.children}
    </>
  );
};

export function getRootLayout(page: ReactElement) {
  return <RootLayout>{page}</RootLayout>;
}

export function getLightLayout(page: ReactElement) {
  return (
    <>
      <GnbHeader />
      {page}
    </>
  );
}
