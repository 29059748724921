import { useEffectOnce } from '@zep/hooks/useEffectOnce';
import { EventProperties, zepAnalytics } from '@zep/utils/analytics';

export const useEnterTrack = (
  eventName: string,
  eventProperties?: EventProperties,
) => {
  useEffectOnce(() => {
    zepAnalytics.track(eventName, eventProperties);
  });
};
