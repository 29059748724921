import { Dialog } from './Dialog';
import { DialogContent } from './DialogContent';
import { DialogFooter } from './DialogFooter';
import { DialogHeader } from './DialogHeader';
import { DialogOverlay } from './DialogOverlay';
import { DialogPanel } from './DialogPanel';
import { DialogTitle } from './DialogTitle';

const dialog = () => {};

/**
 *  젭 코어의 의 다이어로그
 * */
export const ZDialog = Object.assign(dialog, {
  Dialog,
  DialogOverlay,
  DialogPanel,
  DialogHeader,
  DialogTitle,
  DialogContent,
  DialogFooter,
});

export {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogOverlay,
  DialogPanel,
  DialogTitle,
};
