import type { SVGProps } from 'react';
import * as React from 'react';
export const GameFill = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    viewBox="0 0 24 24"
    {...props}>
    <path
      fill={props.fill || `currentColor`}
      fillRule="evenodd"
      d="M8 5a7 7 0 0 0 0 14h8a7 7 0 1 0 0-14zm1 5a1 1 0 1 0-2 0v1H6a1 1 0 1 0 0 2h1v1a1 1 0 1 0 2 0v-1h1a1 1 0 1 0 0-2H9zm10 .5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0M14.5 15a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3"
      clipRule="evenodd"
    />
  </svg>
);
