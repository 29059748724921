import type { SVGProps } from 'react';
import * as React from 'react';
export const BellFill = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    viewBox="0 0 24 24"
    {...props}>
    <path
      fill={props.fill || `currentColor`}
      d="M12 2a2 2 0 0 0-1.854 1.248A7 7 0 0 0 5 10v4.697l-1.832 2.748A1 1 0 0 0 4 19h16a1 1 0 0 0 .832-1.555L19 14.697V10c0-3.224-2.18-5.94-5.146-6.752A2 2 0 0 0 12 2M8.722 19.8C9.12 21.068 10.436 22 12 22s2.881-.931 3.278-2.2z"
    />
  </svg>
);
