import type { SVGProps } from 'react';
import * as React from 'react';
export const Music = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    viewBox="0 0 24 24"
    {...props}>
    <path
      fill={props.fill || `currentColor`}
      fillRule="evenodd"
      d="M8 6.938a1 1 0 0 1 .705-.956l10-3.083A1 1 0 0 1 20 3.854V16a3 3 0 1 1-2-2.829V8.583l-8 2.333V18a3 3 0 1 1-2-2.829zm10-.322V5.083l-8 2.333v1.667zM18 16a1 1 0 1 0-2 0 1 1 0 0 0 2 0M7 17a1 1 0 1 1 0 2 1 1 0 0 1 0-2"
      clipRule="evenodd"
    />
  </svg>
);
