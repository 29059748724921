import type { SVGProps } from 'react';
import * as React from 'react';
export const Youtube = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    viewBox="0 0 24 24"
    {...props}>
    <path
      fill={props.fill || `currentColor`}
      fillRule="evenodd"
      d="M21.816 7.049a2.57 2.57 0 0 0-1.807-1.819c-1.598-.43-7.985-.43-7.985-.43s-6.387 0-7.985.43A2.57 2.57 0 0 0 2.232 7.05c-.427 1.605-.427 4.952-.427 4.952s0 3.347.427 4.952a2.57 2.57 0 0 0 1.807 1.818c1.598.43 7.985.43 7.985.43s6.387 0 7.985-.43a2.57 2.57 0 0 0 1.807-1.819c.427-1.604.427-4.951.427-4.951s0-3.347-.427-4.952m-2.15 1.513a1.75 1.75 0 0 0-.517-.8 2.1 2.1 0 0 0-.898-.463c-1.25-.298-6.25-.298-6.25-.298s-5 0-6.252.298c-.34.082-.649.242-.897.464a1.75 1.75 0 0 0-.517.8C4 9.676 4 12 4 12s0 2.324.335 3.439c.09.302.268.577.517.799.248.221.557.381.897.463C7 17.001 12 17.001 12 17.001s5 0 6.251-.299c.34-.082.65-.242.898-.463s.426-.498.517-.8C20 14.326 20 12.002 20 12.002s0-2.324-.334-3.439"
      clipRule="evenodd"
    />
    <path
      fill={props.fill || `currentColor`}
      d="m15.25 12-5.325 3.075V8.926z"
    />
  </svg>
);
