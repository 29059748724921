import type { SVGProps } from 'react';
import * as React from 'react';
export const Window = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    viewBox="0 0 24 24"
    {...props}>
    <path
      fill={props.fill || `currentColor`}
      fillRule="evenodd"
      d="M2 5a2 2 0 0 1 2-2h16a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2zm18 0H4v14h16z"
      clipRule="evenodd"
    />
    <path fill={props.fill || `currentColor`} d="M3 7h19v2H3z" />
  </svg>
);
