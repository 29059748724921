import { GetServerSidePropsContext, GetStaticProps } from 'next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';

export const getDefaultServerSideProps = async (
  context: GetServerSidePropsContext,
) => {
  const { locale = 'ko' } = context;

  return {
    props: {
      ...(await serverSideTranslations(locale, ['common'])),
    },
  };
};

export const getDefaultStaticProps: GetStaticProps = async ({
  locale = 'ko',
}) => {
  return {
    props: {
      ...(await serverSideTranslations(locale, ['common'])),
    },
  };
};
