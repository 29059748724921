import {
  createInfiniteQuery,
  createMutation,
  createQuery,
  createSuspenseQuery,
} from 'react-query-kit';
import type { SearchResponseListSearchQuizResV1 } from '@zep/api/gen/_model';

import { Quiz, QuizHint, QuizList, QuizSet, QuizType } from '../types';
import { apiClient } from '../utils/api';

import { ApiRoute } from './apiRoute.const';
import { getAllQuizzesV2 } from './index';

type QuizDetailVariables = {
  id: string;
};

export type QuizDetailResponse = {
  data: QuizSet & {
    quizList: Quiz[];
  };
  status: string;
};

export const useQuizDetail = createQuery<
  QuizDetailResponse,
  QuizDetailVariables
>({
  queryKey: ['quiz-detail'],
  fetcher: (variables: QuizDetailVariables) =>
    apiClient
      .get<QuizDetailResponse>(ApiRoute.API_DETAIL_QUIZ, {
        params: { id: variables.id },
      })
      .then(res => res.data),
});

export type Category = {
  id: string;
  categoryId: number;
  name: {
    en: string;
    ko: string;
    ja: string;
  };
  description: {
    en: string;
    ko: string;
    ja: string;
  };
};

/**
 *  locale 기준으로만 가져오는 fetch hooks
 * */
export const useSuspenseQuizCategories = createSuspenseQuery<
  { status: string; data: Category[] },
  { locale?: string }
>({
  queryKey: ['categories'],
  fetcher: variables =>
    apiClient
      .get<{ status: string; data: Category[] }>(ApiRoute.API_CATEGORIES, {
        params: {
          locale: variables?.locale,
        },
      })
      .then(d => d.data),
});

export const useQuizCategories = createQuery<
  { data: Category[] },
  { locale?: string }
>({
  queryKey: ['categories'],
  fetcher: async variables => {
    const res = await apiClient.get<{ data: Category[] }>(
      ApiRoute.API_CATEGORIES,
      {
        params: {
          locale: variables?.locale,
        },
      },
    );
    return res.data;
  },
});
/////////////////////////////////////////////////////

type QuizSetVariables = {
  entryMapHashId: string;
};

export type SimpleQuiz = {
  choiceList: string[] | null | undefined;
  createdAt: string;
  hint: Partial<QuizHint>;
  id: string;
  isMultipleAnswer: boolean;
  order: number;
  question: string;
  questionImageUrl: string | undefined;
  type: QuizType;
};

export type QuizSetResponse = {
  status: string;
  data: QuizSet & { quizList: SimpleQuiz[] };
};
export const fetchEnterMap = async ({ entryMapHashId }: QuizSetVariables) => {
  const res = await apiClient.get<QuizSetResponse>(ApiRoute.API_ENTRY_MAP, {
    params: { entryMapHashId },
  });
  return res.data;
};

export const useEnterMap = createQuery<QuizSetResponse, QuizSetVariables>({
  queryKey: ['enter-map'],
  fetcher: fetchEnterMap,
  staleTime: 0,
});

/////////////////////////////////////////////////////

type QuizListVariables = {
  limit: number;
  isMyQuizzes?: boolean;
  category?: string;
  query?: string;
  order?: boolean;
  locale: string;
  type?: string;
};

type QuizListResponse = {
  status: string;
  data: QuizSet[];
  currentPage: number;
  limit: number;
  totalCount: number;
  totalPages: number;
};
export const useMyQuizList = createInfiniteQuery<
  QuizListResponse,
  QuizListVariables
>({
  fetcher: (_, { pageParam }) => {
    return apiClient
      .post<QuizListResponse>(
        ApiRoute.API_MY_QUIZ,
        {},
        {
          params: {
            page: pageParam,
            limit: 20,
          },
        },
      )
      .then(res => res.data);
  },
  queryKey: ['my-quizes'],
  getNextPageParam: lastPage => {
    return lastPage.currentPage !== lastPage.totalPages
      ? lastPage.currentPage + 1
      : undefined;
  },
  initialPageParam: 1,
});

export const getPrefetchQuizFetch = (variables: QuizListVariables) => {
  const { order = false, ...rest } = variables;

  return useQuizFetch.getFetchOptions({
    ...rest,
    order,
  });
};
export const CategoryId = {
  All: '9999',
  BEST: '1111',
  PUBLIC: 'public',
};

export const useQuizFetch = createInfiniteQuery<
  SearchResponseListSearchQuizResV1,
  QuizListVariables
>({
  queryKey: ['quizList'],
  fetcher: async (variables: QuizListVariables, { pageParam }) => {
    if (variables.category === 'all') variables.category = CategoryId.All;
    if (!variables.category) variables.category = CategoryId.BEST;
    if (variables.category === CategoryId.PUBLIC) {
      variables.type = 'AMBASSADOR';
      variables.category = undefined;
    }

    return getAllQuizzesV2({
      page: pageParam,
      locale: variables.locale,
      title: variables.query,
      limit: variables?.limit || 10,
      categoryId: variables.category ? Number(variables.category) : undefined,
      order: variables?.order ? 'play_count' : '',
      type: variables.type,
    });
  },
  refetchOnWindowFocus: false,
  getNextPageParam: lastPage =>
    lastPage.currentPage !== lastPage.totalPages
      ? lastPage.currentPage + 1
      : undefined,
  initialPageParam: 1,
});

type EnterCodeVariables = {
  enterCode: string;
};

type EnterCodeResponse = {
  status: string;
  type: string;
  data: QuizSet;
};

export const useEnterCode = createMutation<
  EnterCodeResponse,
  EnterCodeVariables
>({
  mutationFn: async (variables: EnterCodeVariables) =>
    apiClient
      .post<EnterCodeResponse>(ApiRoute.API_CODE_ENTER, {
        enterCode: variables.enterCode,
      })
      .then(res => res.data),
});

/////////////////////////////////////////////////////

export type CreateQuizSetVariables = {
  title: string;
  imageUrl: string;
  categoryId: number[];
  templateId: string;
  quizList: QuizList[];
  isPublic: boolean;
  isAllowPass: boolean;
  showIncorrectAnswer: boolean;
  originQuizSetId?: string;
};

type CreateQuizSetResponse = {
  data: QuizSet;
  status: string;
};

export const useCreateQuizSet = createMutation<
  CreateQuizSetResponse,
  CreateQuizSetVariables
>({
  mutationFn: (variables: CreateQuizSetVariables) =>
    apiClient
      .post<CreateQuizSetResponse>(ApiRoute.API_CREATE_QUIZ, {
        ...variables,
      })
      .then(res => res.data),
});

/////////////////////////////////////////////////////

export type UpdateQuizSetVariables = CreateQuizSetVariables & {
  quizSetId: string;
};

type UpdateQuizSetResponse = {
  data: QuizSet;
  status: string;
};

export const useUpdateQuizSet = createMutation<
  UpdateQuizSetResponse,
  UpdateQuizSetVariables
>({
  mutationFn: (variables: UpdateQuizSetVariables) =>
    apiClient
      .patch<UpdateQuizSetResponse>(ApiRoute.API_EDIT_QUIZ, variables)
      .then(res => res.data),
});

/////////////////////////////////////////////////////

type DeleteQuizSetVariables = {
  id: string;
};

export const useDeleteQuizSet = createMutation<unknown, DeleteQuizSetVariables>(
  {
    mutationFn: async (variables: DeleteQuizSetVariables) =>
      apiClient
        .patch(ApiRoute.API_QUIZ_DELETE, {
          id: variables.id,
        })
        .then(res => res.data),
  },
);

/////////////////////////////////////////////////////

type UploadFileVariables = {
  file: File;
};

type UploadFileResponse = {
  data: {
    path: string;
  };
};

export const useUploadFile = createMutation<
  UploadFileResponse,
  UploadFileVariables
>({
  mutationFn: async (variables: UploadFileVariables) => {
    return apiClient
      .postForm<UploadFileResponse>(
        ApiRoute.API_UPLOAD_IMAGE,
        {
          file: variables.file,
        },
        {},
      )
      .then(res => res.data);
  },
});

type UseFetchMyQuizVariables = {
  id: string;
};
type UseFetchMyQuizResponse = { status: string; data: { isAuthor: boolean } };

export const useFetchMyQuiz = createQuery<
  UseFetchMyQuizResponse,
  UseFetchMyQuizVariables
>({
  queryKey: ['check-my-quiz'],
  fetcher: async (variable: UseFetchMyQuizVariables) => {
    return apiClient
      .get<UseFetchMyQuizResponse>(ApiRoute.API_CHECK_MY_QUIZ, {
        params: {
          id: variable.id,
        },
      })
      .then(d => d.data);
  },
  refetchOnWindowFocus: false,
});
