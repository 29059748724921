/**
 * Generated by orval v7.1.1 🍺
 * Do not edit manually.
 * ZEP QUIZ
 * Zep Quiz API
 * OpenAPI spec version: 0.0.1
 */
import type {
  DefinedInitialDataOptions,
  DefinedUseQueryResult,
  QueryFunction,
  QueryKey,
  UndefinedInitialDataOptions,
  UseQueryOptions,
  UseQueryResult,
  UseSuspenseQueryOptions,
  UseSuspenseQueryResult,
} from '@tanstack/react-query';
import { useQuery, useSuspenseQuery } from '@tanstack/react-query';

import type { ErrorType } from '../../httpClient';
import { httpsInstance } from '../../httpClient';

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

export const getQuizPlanPayments = (
  options?: SecondParameter<typeof httpsInstance>,
  signal?: AbortSignal,
) => {
  return httpsInstance<void>(
    { url: `/quiz-plans/payments`, method: 'GET', signal },
    options,
  );
};

export const getGetQuizPlanPaymentsQueryKey = () => {
  return [`/quiz-plans/payments`] as const;
};

export const getGetQuizPlanPaymentsQueryOptions = <
  TData = Awaited<ReturnType<typeof getQuizPlanPayments>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getQuizPlanPayments>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof httpsInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetQuizPlanPaymentsQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getQuizPlanPayments>>
  > = ({ signal }) => getQuizPlanPayments(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getQuizPlanPayments>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetQuizPlanPaymentsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getQuizPlanPayments>>
>;
export type GetQuizPlanPaymentsQueryError = ErrorType<unknown>;

export function useGetQuizPlanPayments<
  TData = Awaited<ReturnType<typeof getQuizPlanPayments>>,
  TError = ErrorType<unknown>,
>(options: {
  query: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getQuizPlanPayments>>,
      TError,
      TData
    >
  > &
    Pick<
      DefinedInitialDataOptions<
        Awaited<ReturnType<typeof getQuizPlanPayments>>,
        TError,
        TData
      >,
      'initialData'
    >;
  request?: SecondParameter<typeof httpsInstance>;
}): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetQuizPlanPayments<
  TData = Awaited<ReturnType<typeof getQuizPlanPayments>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getQuizPlanPayments>>,
      TError,
      TData
    >
  > &
    Pick<
      UndefinedInitialDataOptions<
        Awaited<ReturnType<typeof getQuizPlanPayments>>,
        TError,
        TData
      >,
      'initialData'
    >;
  request?: SecondParameter<typeof httpsInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetQuizPlanPayments<
  TData = Awaited<ReturnType<typeof getQuizPlanPayments>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getQuizPlanPayments>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof httpsInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useGetQuizPlanPayments<
  TData = Awaited<ReturnType<typeof getQuizPlanPayments>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getQuizPlanPayments>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof httpsInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetQuizPlanPaymentsQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getGetQuizPlanPaymentsSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof getQuizPlanPayments>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseSuspenseQueryOptions<
      Awaited<ReturnType<typeof getQuizPlanPayments>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof httpsInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetQuizPlanPaymentsQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getQuizPlanPayments>>
  > = ({ signal }) => getQuizPlanPayments(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof getQuizPlanPayments>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetQuizPlanPaymentsSuspenseQueryResult = NonNullable<
  Awaited<ReturnType<typeof getQuizPlanPayments>>
>;
export type GetQuizPlanPaymentsSuspenseQueryError = ErrorType<unknown>;

export function useGetQuizPlanPaymentsSuspense<
  TData = Awaited<ReturnType<typeof getQuizPlanPayments>>,
  TError = ErrorType<unknown>,
>(options: {
  query: Partial<
    UseSuspenseQueryOptions<
      Awaited<ReturnType<typeof getQuizPlanPayments>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof httpsInstance>;
}): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetQuizPlanPaymentsSuspense<
  TData = Awaited<ReturnType<typeof getQuizPlanPayments>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseSuspenseQueryOptions<
      Awaited<ReturnType<typeof getQuizPlanPayments>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof httpsInstance>;
}): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetQuizPlanPaymentsSuspense<
  TData = Awaited<ReturnType<typeof getQuizPlanPayments>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseSuspenseQueryOptions<
      Awaited<ReturnType<typeof getQuizPlanPayments>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof httpsInstance>;
}): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useGetQuizPlanPaymentsSuspense<
  TData = Awaited<ReturnType<typeof getQuizPlanPayments>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseSuspenseQueryOptions<
      Awaited<ReturnType<typeof getQuizPlanPayments>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof httpsInstance>;
}): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetQuizPlanPaymentsSuspenseQueryOptions(options);

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getQuizPlanPaymentInvoice = (
  options?: SecondParameter<typeof httpsInstance>,
  signal?: AbortSignal,
) => {
  return httpsInstance<void>(
    { url: `/quiz-plans/payments/invoice`, method: 'GET', signal },
    options,
  );
};

export const getGetQuizPlanPaymentInvoiceQueryKey = () => {
  return [`/quiz-plans/payments/invoice`] as const;
};

export const getGetQuizPlanPaymentInvoiceQueryOptions = <
  TData = Awaited<ReturnType<typeof getQuizPlanPaymentInvoice>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getQuizPlanPaymentInvoice>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof httpsInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetQuizPlanPaymentInvoiceQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getQuizPlanPaymentInvoice>>
  > = ({ signal }) => getQuizPlanPaymentInvoice(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getQuizPlanPaymentInvoice>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetQuizPlanPaymentInvoiceQueryResult = NonNullable<
  Awaited<ReturnType<typeof getQuizPlanPaymentInvoice>>
>;
export type GetQuizPlanPaymentInvoiceQueryError = ErrorType<unknown>;

export function useGetQuizPlanPaymentInvoice<
  TData = Awaited<ReturnType<typeof getQuizPlanPaymentInvoice>>,
  TError = ErrorType<unknown>,
>(options: {
  query: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getQuizPlanPaymentInvoice>>,
      TError,
      TData
    >
  > &
    Pick<
      DefinedInitialDataOptions<
        Awaited<ReturnType<typeof getQuizPlanPaymentInvoice>>,
        TError,
        TData
      >,
      'initialData'
    >;
  request?: SecondParameter<typeof httpsInstance>;
}): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetQuizPlanPaymentInvoice<
  TData = Awaited<ReturnType<typeof getQuizPlanPaymentInvoice>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getQuizPlanPaymentInvoice>>,
      TError,
      TData
    >
  > &
    Pick<
      UndefinedInitialDataOptions<
        Awaited<ReturnType<typeof getQuizPlanPaymentInvoice>>,
        TError,
        TData
      >,
      'initialData'
    >;
  request?: SecondParameter<typeof httpsInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetQuizPlanPaymentInvoice<
  TData = Awaited<ReturnType<typeof getQuizPlanPaymentInvoice>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getQuizPlanPaymentInvoice>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof httpsInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useGetQuizPlanPaymentInvoice<
  TData = Awaited<ReturnType<typeof getQuizPlanPaymentInvoice>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getQuizPlanPaymentInvoice>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof httpsInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetQuizPlanPaymentInvoiceQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getGetQuizPlanPaymentInvoiceSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof getQuizPlanPaymentInvoice>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseSuspenseQueryOptions<
      Awaited<ReturnType<typeof getQuizPlanPaymentInvoice>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof httpsInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetQuizPlanPaymentInvoiceQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getQuizPlanPaymentInvoice>>
  > = ({ signal }) => getQuizPlanPaymentInvoice(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof getQuizPlanPaymentInvoice>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetQuizPlanPaymentInvoiceSuspenseQueryResult = NonNullable<
  Awaited<ReturnType<typeof getQuizPlanPaymentInvoice>>
>;
export type GetQuizPlanPaymentInvoiceSuspenseQueryError = ErrorType<unknown>;

export function useGetQuizPlanPaymentInvoiceSuspense<
  TData = Awaited<ReturnType<typeof getQuizPlanPaymentInvoice>>,
  TError = ErrorType<unknown>,
>(options: {
  query: Partial<
    UseSuspenseQueryOptions<
      Awaited<ReturnType<typeof getQuizPlanPaymentInvoice>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof httpsInstance>;
}): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetQuizPlanPaymentInvoiceSuspense<
  TData = Awaited<ReturnType<typeof getQuizPlanPaymentInvoice>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseSuspenseQueryOptions<
      Awaited<ReturnType<typeof getQuizPlanPaymentInvoice>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof httpsInstance>;
}): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetQuizPlanPaymentInvoiceSuspense<
  TData = Awaited<ReturnType<typeof getQuizPlanPaymentInvoice>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseSuspenseQueryOptions<
      Awaited<ReturnType<typeof getQuizPlanPaymentInvoice>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof httpsInstance>;
}): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useGetQuizPlanPaymentInvoiceSuspense<
  TData = Awaited<ReturnType<typeof getQuizPlanPaymentInvoice>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseSuspenseQueryOptions<
      Awaited<ReturnType<typeof getQuizPlanPaymentInvoice>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof httpsInstance>;
}): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions =
    getGetQuizPlanPaymentInvoiceSuspenseQueryOptions(options);

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getQuizPlanPaymentDetails = (
  options?: SecondParameter<typeof httpsInstance>,
  signal?: AbortSignal,
) => {
  return httpsInstance<void>(
    { url: `/quiz-plans/payments/details`, method: 'GET', signal },
    options,
  );
};

export const getGetQuizPlanPaymentDetailsQueryKey = () => {
  return [`/quiz-plans/payments/details`] as const;
};

export const getGetQuizPlanPaymentDetailsQueryOptions = <
  TData = Awaited<ReturnType<typeof getQuizPlanPaymentDetails>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getQuizPlanPaymentDetails>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof httpsInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetQuizPlanPaymentDetailsQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getQuizPlanPaymentDetails>>
  > = ({ signal }) => getQuizPlanPaymentDetails(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getQuizPlanPaymentDetails>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetQuizPlanPaymentDetailsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getQuizPlanPaymentDetails>>
>;
export type GetQuizPlanPaymentDetailsQueryError = ErrorType<unknown>;

export function useGetQuizPlanPaymentDetails<
  TData = Awaited<ReturnType<typeof getQuizPlanPaymentDetails>>,
  TError = ErrorType<unknown>,
>(options: {
  query: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getQuizPlanPaymentDetails>>,
      TError,
      TData
    >
  > &
    Pick<
      DefinedInitialDataOptions<
        Awaited<ReturnType<typeof getQuizPlanPaymentDetails>>,
        TError,
        TData
      >,
      'initialData'
    >;
  request?: SecondParameter<typeof httpsInstance>;
}): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetQuizPlanPaymentDetails<
  TData = Awaited<ReturnType<typeof getQuizPlanPaymentDetails>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getQuizPlanPaymentDetails>>,
      TError,
      TData
    >
  > &
    Pick<
      UndefinedInitialDataOptions<
        Awaited<ReturnType<typeof getQuizPlanPaymentDetails>>,
        TError,
        TData
      >,
      'initialData'
    >;
  request?: SecondParameter<typeof httpsInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetQuizPlanPaymentDetails<
  TData = Awaited<ReturnType<typeof getQuizPlanPaymentDetails>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getQuizPlanPaymentDetails>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof httpsInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useGetQuizPlanPaymentDetails<
  TData = Awaited<ReturnType<typeof getQuizPlanPaymentDetails>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getQuizPlanPaymentDetails>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof httpsInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetQuizPlanPaymentDetailsQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getGetQuizPlanPaymentDetailsSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof getQuizPlanPaymentDetails>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseSuspenseQueryOptions<
      Awaited<ReturnType<typeof getQuizPlanPaymentDetails>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof httpsInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetQuizPlanPaymentDetailsQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getQuizPlanPaymentDetails>>
  > = ({ signal }) => getQuizPlanPaymentDetails(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof getQuizPlanPaymentDetails>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetQuizPlanPaymentDetailsSuspenseQueryResult = NonNullable<
  Awaited<ReturnType<typeof getQuizPlanPaymentDetails>>
>;
export type GetQuizPlanPaymentDetailsSuspenseQueryError = ErrorType<unknown>;

export function useGetQuizPlanPaymentDetailsSuspense<
  TData = Awaited<ReturnType<typeof getQuizPlanPaymentDetails>>,
  TError = ErrorType<unknown>,
>(options: {
  query: Partial<
    UseSuspenseQueryOptions<
      Awaited<ReturnType<typeof getQuizPlanPaymentDetails>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof httpsInstance>;
}): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetQuizPlanPaymentDetailsSuspense<
  TData = Awaited<ReturnType<typeof getQuizPlanPaymentDetails>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseSuspenseQueryOptions<
      Awaited<ReturnType<typeof getQuizPlanPaymentDetails>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof httpsInstance>;
}): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetQuizPlanPaymentDetailsSuspense<
  TData = Awaited<ReturnType<typeof getQuizPlanPaymentDetails>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseSuspenseQueryOptions<
      Awaited<ReturnType<typeof getQuizPlanPaymentDetails>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof httpsInstance>;
}): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useGetQuizPlanPaymentDetailsSuspense<
  TData = Awaited<ReturnType<typeof getQuizPlanPaymentDetails>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseSuspenseQueryOptions<
      Awaited<ReturnType<typeof getQuizPlanPaymentDetails>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof httpsInstance>;
}): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions =
    getGetQuizPlanPaymentDetailsSuspenseQueryOptions(options);

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}
