import type { SVGProps } from 'react';
import * as React from 'react';
export const Link = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    viewBox="0 0 24 24"
    {...props}>
    <path
      fill={props.fill || `currentColor`}
      d="M16.2 6a1 1 0 0 1 1-1c.637 0 1.262.128 1.871.372.663.265 1.173.658 1.636 1.12.463.464.856.974 1.121 1.637.244.609.372 1.234.372 1.871s-.128 1.262-.372 1.871c-.261.655-.648 1.16-1.104 1.619l-.984 1.083-.033.034-3.2 3.2c-.463.463-.973.856-1.636 1.122A5 5 0 0 1 13 19.3a5 5 0 0 1-1.871-.372c-.663-.265-1.173-.658-1.636-1.12-.463-.464-.856-.974-1.121-1.637A5.15 5.15 0 0 1 8 14.2c0-.637.128-1.262.372-1.871.265-.663.658-1.173 1.12-1.636l1.1-1.1a1 1 0 1 1 1.415 1.414l-1.1 1.1c-.337.337-.544.627-.678.964A3 3 0 0 0 10 14.2c0 .476.077.85.229 1.229.134.337.341.627.678.964s.627.544.964.678c.391.157.766.229 1.129.229s.738-.072 1.129-.229c.337-.134.627-.341.964-.678l3.183-3.183.984-1.083.033-.034c.337-.337.544-.627.678-.964.157-.391.229-.766.229-1.129s-.072-.738-.229-1.129c-.134-.337-.341-.627-.678-.964s-.627-.544-.964-.679A3 3 0 0 0 17.2 7a1 1 0 0 1-1-1m-4.9 1.5c-.363 0-.738.072-1.129.228-.337.135-.627.342-.964.68L5.924 11.69l-.984 1.083-.033.034c-.337.337-.544.627-.679.964A3 3 0 0 0 4 14.9c0 .363.072.738.228 1.129.135.337.342.627.68.964.336.337.626.544.963.679.391.156.766.228 1.129.228a1 1 0 1 1 0 2 5 5 0 0 1-1.871-.371c-.663-.266-1.173-.659-1.636-1.122s-.856-.973-1.121-1.636A5 5 0 0 1 2 14.9c0-.637.128-1.262.372-1.871.261-.655.648-1.16 1.104-1.619l.984-1.083.033-.034 3.3-3.3c.463-.463.973-.856 1.636-1.121A5 5 0 0 1 11.3 5.5c.637 0 1.262.128 1.871.372.663.265 1.173.658 1.636 1.12.463.464.856.974 1.121 1.637.244.609.372 1.234.372 1.871s-.128 1.262-.372 1.871c-.262.656-.649 1.162-1.105 1.62l-1.086 1.185a1 1 0 0 1-1.474-1.352l1.1-1.2.03-.031c.337-.337.544-.627.678-.964.157-.391.229-.766.229-1.129s-.072-.738-.229-1.129c-.134-.337-.341-.627-.678-.964s-.627-.544-.964-.679A3 3 0 0 0 11.3 7.5"
    />
  </svg>
);
