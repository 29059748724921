import type { SVGProps } from 'react';
import * as React from 'react';
export const ArrowTurnDownleft = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    viewBox="0 0 24 24"
    {...props}>
    <path
      fill={props.fill || `currentColor`}
      d="M5.076 14.617a1 1 0 0 0 .217 1.09l3 3a1 1 0 0 0 1.414-1.414L8.414 16H13.5a5 5 0 0 0 5-5V5.5a1 1 0 1 0-2 0V11a3 3 0 0 1-3 3H8.414l1.293-1.293a1 1 0 0 0-1.414-1.414l-3 3M5.076 14.617a1 1 0 0 1 .215-.322z"
    />
  </svg>
);
