import { useEffect, useState } from 'react';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { useChangeUsername } from '@zep/api';
import { setStorageUser } from '@zep/lib/auth';
import { zepAnalytics } from '@zep/utils/analytics';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';

import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogPanel,
  DialogTitle,
} from '../ui/dialog';
import { TextField } from '../ui/TextField';

type NameEditModalProps = {
  initialName?: string;
};

const _NameEditModal = ({ initialName }: NameEditModalProps) => {
  const modal = useModal();
  const router = useRouter();
  const [nameInputValue, setNameInputValue] = useState('');
  const { t } = useTranslation();
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNameInputValue(e.target.value);
  };

  const { mutate, isPending } = useChangeUsername({
    onSuccess: (_, variables) => {
      setStorageUser({
        username: variables.username,
      });
      router.reload();
      zepAnalytics.track('edit_name');
      modal.remove();
    },
    onError: () => {
      alert(t('nameChangeModal.error'));
    },
  });

  useEffect(() => {
    setNameInputValue(initialName ?? '');
  }, [initialName]);

  const handleSubmit = () => {
    if (!nameInputValue.trim().length) {
      return;
    }

    mutate({ username: nameInputValue.trim().slice(0, 14) });
  };

  return (
    <Dialog onClick={modal.remove}>
      <DialogPanel className="max-w-[720px]">
        <DialogHeader>
          <DialogTitle>{t('nameChangeModal.title')}</DialogTitle>
        </DialogHeader>
        <DialogContent className="py-0">
          <TextField
            className="w-full"
            autoFocus
            maxLength={14}
            type="text"
            value={nameInputValue}
            onChange={handleChange}
          />
        </DialogContent>
        <DialogFooter className="flex-row-reverse">
          <button
            className="rounded bg-primary px-md py-[13px] font-bold text-white"
            disabled={isPending}
            type="submit"
            onClick={handleSubmit}>
            {isPending
              ? t('nameChangeModal.changing')
              : t('global.confirm.button')}
          </button>
          <button
            className="rounded bg-gray-100 px-md py-[13px] font-bold text-gray-600"
            onClick={modal.remove}>
            {t('global.cancel.button')}
          </button>
        </DialogFooter>
      </DialogPanel>
    </Dialog>
  );
};

export const NameEditModal = NiceModal.create(_NameEditModal);
