/**
 * Generated by orval v7.1.1 🍺
 * Do not edit manually.
 * ZEP QUIZ
 * Zep Quiz API
 * OpenAPI spec version: 0.0.1
 */
import type {
  DefinedInitialDataOptions,
  DefinedUseQueryResult,
  MutationFunction,
  QueryFunction,
  QueryKey,
  UndefinedInitialDataOptions,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
  UseSuspenseQueryOptions,
  UseSuspenseQueryResult,
} from '@tanstack/react-query';
import { useMutation, useQuery, useSuspenseQuery } from '@tanstack/react-query';

import type { BodyType, ErrorType } from '../../httpClient';
import { httpsInstance } from '../../httpClient';
import type {
  BaseResponseByte,
  CreateDiscountCouponsRequest,
  DownloadDiscountCouponListAsCsvParams,
  SearchDiscountCouponsParams,
  SearchResponseListDiscountCouponDto,
} from '.././_model';

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

export const searchDiscountCoupons = (
  params: SearchDiscountCouponsParams,
  options?: SecondParameter<typeof httpsInstance>,
  signal?: AbortSignal,
) => {
  return httpsInstance<SearchResponseListDiscountCouponDto>(
    { url: `/admin/discount-coupons`, method: 'GET', params, signal },
    options,
  );
};

export const getSearchDiscountCouponsQueryKey = (
  params: SearchDiscountCouponsParams,
) => {
  return [`/admin/discount-coupons`, ...(params ? [params] : [])] as const;
};

export const getSearchDiscountCouponsQueryOptions = <
  TData = Awaited<ReturnType<typeof searchDiscountCoupons>>,
  TError = ErrorType<unknown>,
>(
  params: SearchDiscountCouponsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof searchDiscountCoupons>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof httpsInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getSearchDiscountCouponsQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof searchDiscountCoupons>>
  > = ({ signal }) => searchDiscountCoupons(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof searchDiscountCoupons>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type SearchDiscountCouponsQueryResult = NonNullable<
  Awaited<ReturnType<typeof searchDiscountCoupons>>
>;
export type SearchDiscountCouponsQueryError = ErrorType<unknown>;

export function useSearchDiscountCoupons<
  TData = Awaited<ReturnType<typeof searchDiscountCoupons>>,
  TError = ErrorType<unknown>,
>(
  params: SearchDiscountCouponsParams,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof searchDiscountCoupons>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof searchDiscountCoupons>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof httpsInstance>;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useSearchDiscountCoupons<
  TData = Awaited<ReturnType<typeof searchDiscountCoupons>>,
  TError = ErrorType<unknown>,
>(
  params: SearchDiscountCouponsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof searchDiscountCoupons>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof searchDiscountCoupons>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof httpsInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useSearchDiscountCoupons<
  TData = Awaited<ReturnType<typeof searchDiscountCoupons>>,
  TError = ErrorType<unknown>,
>(
  params: SearchDiscountCouponsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof searchDiscountCoupons>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof httpsInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useSearchDiscountCoupons<
  TData = Awaited<ReturnType<typeof searchDiscountCoupons>>,
  TError = ErrorType<unknown>,
>(
  params: SearchDiscountCouponsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof searchDiscountCoupons>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof httpsInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getSearchDiscountCouponsQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getSearchDiscountCouponsSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof searchDiscountCoupons>>,
  TError = ErrorType<unknown>,
>(
  params: SearchDiscountCouponsParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof searchDiscountCoupons>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof httpsInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getSearchDiscountCouponsQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof searchDiscountCoupons>>
  > = ({ signal }) => searchDiscountCoupons(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof searchDiscountCoupons>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type SearchDiscountCouponsSuspenseQueryResult = NonNullable<
  Awaited<ReturnType<typeof searchDiscountCoupons>>
>;
export type SearchDiscountCouponsSuspenseQueryError = ErrorType<unknown>;

export function useSearchDiscountCouponsSuspense<
  TData = Awaited<ReturnType<typeof searchDiscountCoupons>>,
  TError = ErrorType<unknown>,
>(
  params: SearchDiscountCouponsParams,
  options: {
    query: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof searchDiscountCoupons>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof httpsInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useSearchDiscountCouponsSuspense<
  TData = Awaited<ReturnType<typeof searchDiscountCoupons>>,
  TError = ErrorType<unknown>,
>(
  params: SearchDiscountCouponsParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof searchDiscountCoupons>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof httpsInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useSearchDiscountCouponsSuspense<
  TData = Awaited<ReturnType<typeof searchDiscountCoupons>>,
  TError = ErrorType<unknown>,
>(
  params: SearchDiscountCouponsParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof searchDiscountCoupons>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof httpsInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useSearchDiscountCouponsSuspense<
  TData = Awaited<ReturnType<typeof searchDiscountCoupons>>,
  TError = ErrorType<unknown>,
>(
  params: SearchDiscountCouponsParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof searchDiscountCoupons>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof httpsInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getSearchDiscountCouponsSuspenseQueryOptions(
    params,
    options,
  );

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const createDiscountCoupons = (
  createDiscountCouponsRequest: BodyType<CreateDiscountCouponsRequest>,
  options?: SecondParameter<typeof httpsInstance>,
) => {
  return httpsInstance<BaseResponseByte>(
    {
      url: `/admin/discount-coupons`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: createDiscountCouponsRequest,
    },
    options,
  );
};

export const getCreateDiscountCouponsMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createDiscountCoupons>>,
    TError,
    { data: BodyType<CreateDiscountCouponsRequest> },
    TContext
  >;
  request?: SecondParameter<typeof httpsInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof createDiscountCoupons>>,
  TError,
  { data: BodyType<CreateDiscountCouponsRequest> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createDiscountCoupons>>,
    { data: BodyType<CreateDiscountCouponsRequest> }
  > = props => {
    const { data } = props ?? {};

    return createDiscountCoupons(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type CreateDiscountCouponsMutationResult = NonNullable<
  Awaited<ReturnType<typeof createDiscountCoupons>>
>;
export type CreateDiscountCouponsMutationBody =
  BodyType<CreateDiscountCouponsRequest>;
export type CreateDiscountCouponsMutationError = ErrorType<unknown>;

export const useCreateDiscountCoupons = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createDiscountCoupons>>,
    TError,
    { data: BodyType<CreateDiscountCouponsRequest> },
    TContext
  >;
  request?: SecondParameter<typeof httpsInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof createDiscountCoupons>>,
  TError,
  { data: BodyType<CreateDiscountCouponsRequest> },
  TContext
> => {
  const mutationOptions = getCreateDiscountCouponsMutationOptions(options);

  return useMutation(mutationOptions);
};
export const downloadDiscountCouponListAsCsv = (
  params: DownloadDiscountCouponListAsCsvParams,
  options?: SecondParameter<typeof httpsInstance>,
  signal?: AbortSignal,
) => {
  return httpsInstance<BaseResponseByte>(
    { url: `/admin/discount-coupons/csv`, method: 'GET', params, signal },
    options,
  );
};

export const getDownloadDiscountCouponListAsCsvQueryKey = (
  params: DownloadDiscountCouponListAsCsvParams,
) => {
  return [`/admin/discount-coupons/csv`, ...(params ? [params] : [])] as const;
};

export const getDownloadDiscountCouponListAsCsvQueryOptions = <
  TData = Awaited<ReturnType<typeof downloadDiscountCouponListAsCsv>>,
  TError = ErrorType<unknown>,
>(
  params: DownloadDiscountCouponListAsCsvParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof downloadDiscountCouponListAsCsv>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof httpsInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getDownloadDiscountCouponListAsCsvQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof downloadDiscountCouponListAsCsv>>
  > = ({ signal }) =>
    downloadDiscountCouponListAsCsv(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof downloadDiscountCouponListAsCsv>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type DownloadDiscountCouponListAsCsvQueryResult = NonNullable<
  Awaited<ReturnType<typeof downloadDiscountCouponListAsCsv>>
>;
export type DownloadDiscountCouponListAsCsvQueryError = ErrorType<unknown>;

export function useDownloadDiscountCouponListAsCsv<
  TData = Awaited<ReturnType<typeof downloadDiscountCouponListAsCsv>>,
  TError = ErrorType<unknown>,
>(
  params: DownloadDiscountCouponListAsCsvParams,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof downloadDiscountCouponListAsCsv>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof downloadDiscountCouponListAsCsv>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof httpsInstance>;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useDownloadDiscountCouponListAsCsv<
  TData = Awaited<ReturnType<typeof downloadDiscountCouponListAsCsv>>,
  TError = ErrorType<unknown>,
>(
  params: DownloadDiscountCouponListAsCsvParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof downloadDiscountCouponListAsCsv>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof downloadDiscountCouponListAsCsv>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof httpsInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useDownloadDiscountCouponListAsCsv<
  TData = Awaited<ReturnType<typeof downloadDiscountCouponListAsCsv>>,
  TError = ErrorType<unknown>,
>(
  params: DownloadDiscountCouponListAsCsvParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof downloadDiscountCouponListAsCsv>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof httpsInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useDownloadDiscountCouponListAsCsv<
  TData = Awaited<ReturnType<typeof downloadDiscountCouponListAsCsv>>,
  TError = ErrorType<unknown>,
>(
  params: DownloadDiscountCouponListAsCsvParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof downloadDiscountCouponListAsCsv>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof httpsInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getDownloadDiscountCouponListAsCsvQueryOptions(
    params,
    options,
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getDownloadDiscountCouponListAsCsvSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof downloadDiscountCouponListAsCsv>>,
  TError = ErrorType<unknown>,
>(
  params: DownloadDiscountCouponListAsCsvParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof downloadDiscountCouponListAsCsv>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof httpsInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getDownloadDiscountCouponListAsCsvQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof downloadDiscountCouponListAsCsv>>
  > = ({ signal }) =>
    downloadDiscountCouponListAsCsv(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof downloadDiscountCouponListAsCsv>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type DownloadDiscountCouponListAsCsvSuspenseQueryResult = NonNullable<
  Awaited<ReturnType<typeof downloadDiscountCouponListAsCsv>>
>;
export type DownloadDiscountCouponListAsCsvSuspenseQueryError =
  ErrorType<unknown>;

export function useDownloadDiscountCouponListAsCsvSuspense<
  TData = Awaited<ReturnType<typeof downloadDiscountCouponListAsCsv>>,
  TError = ErrorType<unknown>,
>(
  params: DownloadDiscountCouponListAsCsvParams,
  options: {
    query: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof downloadDiscountCouponListAsCsv>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof httpsInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useDownloadDiscountCouponListAsCsvSuspense<
  TData = Awaited<ReturnType<typeof downloadDiscountCouponListAsCsv>>,
  TError = ErrorType<unknown>,
>(
  params: DownloadDiscountCouponListAsCsvParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof downloadDiscountCouponListAsCsv>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof httpsInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useDownloadDiscountCouponListAsCsvSuspense<
  TData = Awaited<ReturnType<typeof downloadDiscountCouponListAsCsv>>,
  TError = ErrorType<unknown>,
>(
  params: DownloadDiscountCouponListAsCsvParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof downloadDiscountCouponListAsCsv>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof httpsInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useDownloadDiscountCouponListAsCsvSuspense<
  TData = Awaited<ReturnType<typeof downloadDiscountCouponListAsCsv>>,
  TError = ErrorType<unknown>,
>(
  params: DownloadDiscountCouponListAsCsvParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof downloadDiscountCouponListAsCsv>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof httpsInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getDownloadDiscountCouponListAsCsvSuspenseQueryOptions(
    params,
    options,
  );

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}
