import React, { ComponentPropsWithoutRef } from 'react';
import { cn } from '@zep/utils';

export const StrongText = (
  props: ComponentPropsWithoutRef<'span'> & { text: string },
) => {
  const { className, ...rest } = props;
  return (
    <span className={cn('text-strong', className)} {...rest}>
      {props.text}
    </span>
  );
};
