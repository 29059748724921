import { ComponentPropsWithoutRef, ElementRef, forwardRef } from 'react';
import * as CheckboxPrimitive from '@radix-ui/react-checkbox';
import { CheckIcon } from '@radix-ui/react-icons';
import { MinusSmallIcon } from '@zep/icons';
import { cn } from '@zep/utils';

const Checkbox = forwardRef<
  ElementRef<typeof CheckboxPrimitive.Root>,
  ComponentPropsWithoutRef<typeof CheckboxPrimitive.Root>
>(({ className, ...props }, ref) => {
  const isIndeterminate = props.checked === 'indeterminate';

  return (
    <CheckboxPrimitive.Root
      ref={ref}
      className={cn(
        [
          'peer',
          'inline-flex',
          'items-center',
          'justify-center',
          'h-5',
          'w-5',
          'border-2',
          'bg-white',
          'rounded-[3px]',
          'align-middle',
          'border-gray-300',
          'text-gray-300',

          'ring-offset-background',
          'focus-visible:outline-none',
          'focus-visible:ring-2',
          'focus-visible:ring-ring',
          'focus-visible:ring-offset-2',
          'disabled:cursor-not-allowed',

          'data-[state=checked]:border-primary',
          'data-[state=checked]:bg-primary',
          'data-[state=checked]:text-white',

          'data-[state=indeterminate]:border-primary',
          'data-[state=indeterminate]:bg-primary',
          'data-[state=indeterminate]:text-white',

          'data-[disabled]:border-gray-200',
          'data-[disabled]:bg-gray-50',
          'data-[disabled]:text-gray-200',

          'data-[state=checked][disabled]:border-primary-disabled',
          'data-[state=checked][disabled]:bg-primary-disabled',
          'data-[state=checked][disabled]:text-gray-50',

          'data-[state=indeterminate][disabled]:border-primary-disabled',
          'data-[state=indeterminate][disabled]:bg-primary-disabled',
          'data-[state=indeterminate][disabled]:text-gray-50',
        ],
        className,
      )}
      {...props}>
      {isIndeterminate ? (
        <MinusSmallIcon width={24} height={24} fill={'#5D5E69'} />
      ) : (
        <CheckIcon />
      )}
    </CheckboxPrimitive.Root>
  );
});
Checkbox.displayName = CheckboxPrimitive.Root.displayName;

export { Checkbox };
