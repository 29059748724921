import type { SVGProps } from 'react';
import * as React from 'react';
export const FunnelFill = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    viewBox="0 0 24 24"
    {...props}>
    <path
      fill={props.fill || `currentColor`}
      fillRule="evenodd"
      d="M9.782 19.444a2.1 2.1 0 0 1-.615-1.485v-4.805l-5.23-4.982C1.977 6.304 3.298 3 6.006 3h12.322c2.707 0 4.03 3.304 2.069 5.172l-5.23 4.982v6.605c0 1.87-2.262 2.808-3.585 1.485z"
      clipRule="evenodd"
    />
  </svg>
);
