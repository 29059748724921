import type { SVGProps } from 'react';
import * as React from 'react';
export const Dice = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    viewBox="0 0 24 24"
    {...props}>
    <path
      fill={props.fill || `currentColor`}
      d="M8.85 9.3a.45.45 0 1 0 0-.9.45.45 0 0 0 0 .9M15.15 15.6a.45.45 0 1 0 0-.9.45.45 0 0 0 0 .9M12 12.45a.45.45 0 1 0 0-.9.45.45 0 0 0 0 .9"
    />
    <path
      fill={props.fill || `currentColor`}
      fillRule="evenodd"
      d="M5.7 4.8a.9.9 0 0 0-.9.9v12.6a.9.9 0 0 0 .9.9h12.6a.9.9 0 0 0 .9-.9V5.7a.9.9 0 0 0-.9-.9zM3.79 3.79A2.7 2.7 0 0 1 5.7 3h12.6A2.7 2.7 0 0 1 21 5.7v12.6a2.7 2.7 0 0 1-2.7 2.7H5.7A2.7 2.7 0 0 1 3 18.3V5.7a2.7 2.7 0 0 1 .79-1.91M8.85 9.3a.45.45 0 1 0 0-.9.45.45 0 0 0 0 .9M7.5 8.85a1.35 1.35 0 1 1 2.7 0 1.35 1.35 0 0 1-2.7 0m4.5 3.6a.45.45 0 1 0 0-.9.45.45 0 0 0 0 .9M10.65 12a1.35 1.35 0 1 1 2.7 0 1.35 1.35 0 0 1-2.7 0m4.5 3.6a.45.45 0 1 0 0-.9.45.45 0 0 0 0 .9m-1.35-.45a1.35 1.35 0 1 1 2.7 0 1.35 1.35 0 0 1-2.7 0"
      clipRule="evenodd"
    />
  </svg>
);
