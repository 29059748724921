import clsx, { type ClassValue } from 'clsx';
import config from 'tailwind.config';
import { extendTailwindMerge } from 'tailwind-merge';
import resolveConfig from 'tailwindcss/resolveConfig';

const twMerge = extendTailwindMerge({
  extend: {
    theme: {
      borderRadius: Object.keys(config.theme?.extend?.borderRadius ?? {}),
      spacing: Object.keys(config.theme?.extend?.spacing ?? {}),
    },
    classGroups: {
      'font-size': [
        {
          text: Object.keys(config.theme?.extend?.fontSize ?? {}),
        },
      ],
      'font-weight': [
        {
          font: Object.keys(config.theme?.extend?.fontWeight ?? {}),
        },
      ],
      shadow: [
        {
          shadow: Object.keys(resolveConfig(config).theme.boxShadow),
        },
      ],
    },
  },
});

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}
