export type ValidateFileParams = {
  file: File;
  validation: { mbSize: number; extensions: string[] };
};

export const validateFile = ({ validation, file }: ValidateFileParams) => {
  const { mbSize, extensions } = validation;
  const fileSize = file.size;
  const fileExtension = file.type.split('/').pop() || '';

  if (fileSize > mbSize * 1024 * 1024) {
    return {
      error: true,
      message: 'File size is too large',
      type: 'size',
    };
  }

  if (!extensions.includes(fileExtension)) {
    return {
      error: true,
      message: 'File extension is not supported',
      type: 'extension',
    };
  }

  return {
    error: false,
    message: '',
    type: '',
  };
};
