/**
 * Generated by orval v7.1.1 🍺
 * Do not edit manually.
 * ZEP QUIZ
 * Zep Quiz API
 * OpenAPI spec version: 0.0.1
 */
import type {
  DefinedInitialDataOptions,
  DefinedUseQueryResult,
  MutationFunction,
  QueryFunction,
  QueryKey,
  UndefinedInitialDataOptions,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
  UseSuspenseQueryOptions,
  UseSuspenseQueryResult,
} from '@tanstack/react-query';
import { useMutation, useQuery, useSuspenseQuery } from '@tanstack/react-query';

import type { BodyType, ErrorType } from '../../httpClient';
import { httpsInstance } from '../../httpClient';
import type {
  BaseResponseListPlayerHistoryDto,
  BaseResponseListPlayerScoreInfo,
  BaseResponseLong,
  BaseResponsePlayerHistoryDto,
  BaseResponseSavePlayerHistoriesResponse,
  DeletePlayerHistoriesRequest,
  GetAllPlayerHistoriesParams,
  GetPlayerHistoriesParams,
  GetPlayerScoreInfoParams,
  GetPlayerScoreInfosParams,
  SavePlayerHistoriesRequest,
  SavePlayerHistoryRequest,
  SearchResponseListPlayerHistoryDto,
  SuccessResponse,
} from '.././_model';

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

export const getPlayerScoreInfo = (
  params?: GetPlayerScoreInfoParams,
  options?: SecondParameter<typeof httpsInstance>,
  signal?: AbortSignal,
) => {
  return httpsInstance<BaseResponsePlayerHistoryDto>(
    { url: `/player-history`, method: 'GET', params, signal },
    options,
  );
};

export const getGetPlayerScoreInfoQueryKey = (
  params?: GetPlayerScoreInfoParams,
) => {
  return [`/player-history`, ...(params ? [params] : [])] as const;
};

export const getGetPlayerScoreInfoQueryOptions = <
  TData = Awaited<ReturnType<typeof getPlayerScoreInfo>>,
  TError = ErrorType<unknown>,
>(
  params?: GetPlayerScoreInfoParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getPlayerScoreInfo>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof httpsInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetPlayerScoreInfoQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getPlayerScoreInfo>>
  > = ({ signal }) => getPlayerScoreInfo(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getPlayerScoreInfo>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetPlayerScoreInfoQueryResult = NonNullable<
  Awaited<ReturnType<typeof getPlayerScoreInfo>>
>;
export type GetPlayerScoreInfoQueryError = ErrorType<unknown>;

export function useGetPlayerScoreInfo<
  TData = Awaited<ReturnType<typeof getPlayerScoreInfo>>,
  TError = ErrorType<unknown>,
>(
  params: undefined | GetPlayerScoreInfoParams,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getPlayerScoreInfo>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getPlayerScoreInfo>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof httpsInstance>;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetPlayerScoreInfo<
  TData = Awaited<ReturnType<typeof getPlayerScoreInfo>>,
  TError = ErrorType<unknown>,
>(
  params?: GetPlayerScoreInfoParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getPlayerScoreInfo>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getPlayerScoreInfo>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof httpsInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetPlayerScoreInfo<
  TData = Awaited<ReturnType<typeof getPlayerScoreInfo>>,
  TError = ErrorType<unknown>,
>(
  params?: GetPlayerScoreInfoParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getPlayerScoreInfo>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof httpsInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useGetPlayerScoreInfo<
  TData = Awaited<ReturnType<typeof getPlayerScoreInfo>>,
  TError = ErrorType<unknown>,
>(
  params?: GetPlayerScoreInfoParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getPlayerScoreInfo>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof httpsInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetPlayerScoreInfoQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getGetPlayerScoreInfoSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof getPlayerScoreInfo>>,
  TError = ErrorType<unknown>,
>(
  params?: GetPlayerScoreInfoParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof getPlayerScoreInfo>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof httpsInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetPlayerScoreInfoQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getPlayerScoreInfo>>
  > = ({ signal }) => getPlayerScoreInfo(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof getPlayerScoreInfo>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetPlayerScoreInfoSuspenseQueryResult = NonNullable<
  Awaited<ReturnType<typeof getPlayerScoreInfo>>
>;
export type GetPlayerScoreInfoSuspenseQueryError = ErrorType<unknown>;

export function useGetPlayerScoreInfoSuspense<
  TData = Awaited<ReturnType<typeof getPlayerScoreInfo>>,
  TError = ErrorType<unknown>,
>(
  params: undefined | GetPlayerScoreInfoParams,
  options: {
    query: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof getPlayerScoreInfo>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof httpsInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetPlayerScoreInfoSuspense<
  TData = Awaited<ReturnType<typeof getPlayerScoreInfo>>,
  TError = ErrorType<unknown>,
>(
  params?: GetPlayerScoreInfoParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof getPlayerScoreInfo>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof httpsInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetPlayerScoreInfoSuspense<
  TData = Awaited<ReturnType<typeof getPlayerScoreInfo>>,
  TError = ErrorType<unknown>,
>(
  params?: GetPlayerScoreInfoParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof getPlayerScoreInfo>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof httpsInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useGetPlayerScoreInfoSuspense<
  TData = Awaited<ReturnType<typeof getPlayerScoreInfo>>,
  TError = ErrorType<unknown>,
>(
  params?: GetPlayerScoreInfoParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof getPlayerScoreInfo>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof httpsInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetPlayerScoreInfoSuspenseQueryOptions(
    params,
    options,
  );

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const saveplayerHistory = (
  savePlayerHistoryRequest: BodyType<SavePlayerHistoryRequest>,
  options?: SecondParameter<typeof httpsInstance>,
) => {
  return httpsInstance<SuccessResponse>(
    {
      url: `/player-history`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: savePlayerHistoryRequest,
    },
    options,
  );
};

export const getSaveplayerHistoryMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof saveplayerHistory>>,
    TError,
    { data: BodyType<SavePlayerHistoryRequest> },
    TContext
  >;
  request?: SecondParameter<typeof httpsInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof saveplayerHistory>>,
  TError,
  { data: BodyType<SavePlayerHistoryRequest> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof saveplayerHistory>>,
    { data: BodyType<SavePlayerHistoryRequest> }
  > = props => {
    const { data } = props ?? {};

    return saveplayerHistory(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type SaveplayerHistoryMutationResult = NonNullable<
  Awaited<ReturnType<typeof saveplayerHistory>>
>;
export type SaveplayerHistoryMutationBody = BodyType<SavePlayerHistoryRequest>;
export type SaveplayerHistoryMutationError = ErrorType<unknown>;

export const useSaveplayerHistory = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof saveplayerHistory>>,
    TError,
    { data: BodyType<SavePlayerHistoryRequest> },
    TContext
  >;
  request?: SecondParameter<typeof httpsInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof saveplayerHistory>>,
  TError,
  { data: BodyType<SavePlayerHistoryRequest> },
  TContext
> => {
  const mutationOptions = getSaveplayerHistoryMutationOptions(options);

  return useMutation(mutationOptions);
};
export const getPlayerHistories = (
  params?: GetPlayerHistoriesParams,
  options?: SecondParameter<typeof httpsInstance>,
  signal?: AbortSignal,
) => {
  return httpsInstance<SearchResponseListPlayerHistoryDto>(
    { url: `/player-histories`, method: 'GET', params, signal },
    options,
  );
};

export const getGetPlayerHistoriesQueryKey = (
  params?: GetPlayerHistoriesParams,
) => {
  return [`/player-histories`, ...(params ? [params] : [])] as const;
};

export const getGetPlayerHistoriesQueryOptions = <
  TData = Awaited<ReturnType<typeof getPlayerHistories>>,
  TError = ErrorType<unknown>,
>(
  params?: GetPlayerHistoriesParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getPlayerHistories>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof httpsInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetPlayerHistoriesQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getPlayerHistories>>
  > = ({ signal }) => getPlayerHistories(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getPlayerHistories>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetPlayerHistoriesQueryResult = NonNullable<
  Awaited<ReturnType<typeof getPlayerHistories>>
>;
export type GetPlayerHistoriesQueryError = ErrorType<unknown>;

export function useGetPlayerHistories<
  TData = Awaited<ReturnType<typeof getPlayerHistories>>,
  TError = ErrorType<unknown>,
>(
  params: undefined | GetPlayerHistoriesParams,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getPlayerHistories>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getPlayerHistories>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof httpsInstance>;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetPlayerHistories<
  TData = Awaited<ReturnType<typeof getPlayerHistories>>,
  TError = ErrorType<unknown>,
>(
  params?: GetPlayerHistoriesParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getPlayerHistories>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getPlayerHistories>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof httpsInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetPlayerHistories<
  TData = Awaited<ReturnType<typeof getPlayerHistories>>,
  TError = ErrorType<unknown>,
>(
  params?: GetPlayerHistoriesParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getPlayerHistories>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof httpsInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useGetPlayerHistories<
  TData = Awaited<ReturnType<typeof getPlayerHistories>>,
  TError = ErrorType<unknown>,
>(
  params?: GetPlayerHistoriesParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getPlayerHistories>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof httpsInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetPlayerHistoriesQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getGetPlayerHistoriesSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof getPlayerHistories>>,
  TError = ErrorType<unknown>,
>(
  params?: GetPlayerHistoriesParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof getPlayerHistories>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof httpsInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetPlayerHistoriesQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getPlayerHistories>>
  > = ({ signal }) => getPlayerHistories(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof getPlayerHistories>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetPlayerHistoriesSuspenseQueryResult = NonNullable<
  Awaited<ReturnType<typeof getPlayerHistories>>
>;
export type GetPlayerHistoriesSuspenseQueryError = ErrorType<unknown>;

export function useGetPlayerHistoriesSuspense<
  TData = Awaited<ReturnType<typeof getPlayerHistories>>,
  TError = ErrorType<unknown>,
>(
  params: undefined | GetPlayerHistoriesParams,
  options: {
    query: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof getPlayerHistories>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof httpsInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetPlayerHistoriesSuspense<
  TData = Awaited<ReturnType<typeof getPlayerHistories>>,
  TError = ErrorType<unknown>,
>(
  params?: GetPlayerHistoriesParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof getPlayerHistories>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof httpsInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetPlayerHistoriesSuspense<
  TData = Awaited<ReturnType<typeof getPlayerHistories>>,
  TError = ErrorType<unknown>,
>(
  params?: GetPlayerHistoriesParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof getPlayerHistories>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof httpsInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useGetPlayerHistoriesSuspense<
  TData = Awaited<ReturnType<typeof getPlayerHistories>>,
  TError = ErrorType<unknown>,
>(
  params?: GetPlayerHistoriesParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof getPlayerHistories>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof httpsInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetPlayerHistoriesSuspenseQueryOptions(
    params,
    options,
  );

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const savePlayerHistories = (
  savePlayerHistoriesRequest: BodyType<SavePlayerHistoriesRequest>,
  options?: SecondParameter<typeof httpsInstance>,
) => {
  return httpsInstance<BaseResponseSavePlayerHistoriesResponse>(
    {
      url: `/player-histories`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: savePlayerHistoriesRequest,
    },
    options,
  );
};

export const getSavePlayerHistoriesMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof savePlayerHistories>>,
    TError,
    { data: BodyType<SavePlayerHistoriesRequest> },
    TContext
  >;
  request?: SecondParameter<typeof httpsInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof savePlayerHistories>>,
  TError,
  { data: BodyType<SavePlayerHistoriesRequest> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof savePlayerHistories>>,
    { data: BodyType<SavePlayerHistoriesRequest> }
  > = props => {
    const { data } = props ?? {};

    return savePlayerHistories(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type SavePlayerHistoriesMutationResult = NonNullable<
  Awaited<ReturnType<typeof savePlayerHistories>>
>;
export type SavePlayerHistoriesMutationBody =
  BodyType<SavePlayerHistoriesRequest>;
export type SavePlayerHistoriesMutationError = ErrorType<unknown>;

export const useSavePlayerHistories = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof savePlayerHistories>>,
    TError,
    { data: BodyType<SavePlayerHistoriesRequest> },
    TContext
  >;
  request?: SecondParameter<typeof httpsInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof savePlayerHistories>>,
  TError,
  { data: BodyType<SavePlayerHistoriesRequest> },
  TContext
> => {
  const mutationOptions = getSavePlayerHistoriesMutationOptions(options);

  return useMutation(mutationOptions);
};
export const deletePlayerHistories = (
  deletePlayerHistoriesRequest: BodyType<DeletePlayerHistoriesRequest>,
  options?: SecondParameter<typeof httpsInstance>,
) => {
  return httpsInstance<BaseResponseLong>(
    {
      url: `/player-histories/delete`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: deletePlayerHistoriesRequest,
    },
    options,
  );
};

export const getDeletePlayerHistoriesMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deletePlayerHistories>>,
    TError,
    { data: BodyType<DeletePlayerHistoriesRequest> },
    TContext
  >;
  request?: SecondParameter<typeof httpsInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof deletePlayerHistories>>,
  TError,
  { data: BodyType<DeletePlayerHistoriesRequest> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deletePlayerHistories>>,
    { data: BodyType<DeletePlayerHistoriesRequest> }
  > = props => {
    const { data } = props ?? {};

    return deletePlayerHistories(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeletePlayerHistoriesMutationResult = NonNullable<
  Awaited<ReturnType<typeof deletePlayerHistories>>
>;
export type DeletePlayerHistoriesMutationBody =
  BodyType<DeletePlayerHistoriesRequest>;
export type DeletePlayerHistoriesMutationError = ErrorType<unknown>;

export const useDeletePlayerHistories = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deletePlayerHistories>>,
    TError,
    { data: BodyType<DeletePlayerHistoriesRequest> },
    TContext
  >;
  request?: SecondParameter<typeof httpsInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof deletePlayerHistories>>,
  TError,
  { data: BodyType<DeletePlayerHistoriesRequest> },
  TContext
> => {
  const mutationOptions = getDeletePlayerHistoriesMutationOptions(options);

  return useMutation(mutationOptions);
};
export const getPlayerScoreInfos = (
  params?: GetPlayerScoreInfosParams,
  options?: SecondParameter<typeof httpsInstance>,
  signal?: AbortSignal,
) => {
  return httpsInstance<BaseResponseListPlayerScoreInfo>(
    { url: `/player-history/score`, method: 'GET', params, signal },
    options,
  );
};

export const getGetPlayerScoreInfosQueryKey = (
  params?: GetPlayerScoreInfosParams,
) => {
  return [`/player-history/score`, ...(params ? [params] : [])] as const;
};

export const getGetPlayerScoreInfosQueryOptions = <
  TData = Awaited<ReturnType<typeof getPlayerScoreInfos>>,
  TError = ErrorType<unknown>,
>(
  params?: GetPlayerScoreInfosParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getPlayerScoreInfos>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof httpsInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetPlayerScoreInfosQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getPlayerScoreInfos>>
  > = ({ signal }) => getPlayerScoreInfos(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getPlayerScoreInfos>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetPlayerScoreInfosQueryResult = NonNullable<
  Awaited<ReturnType<typeof getPlayerScoreInfos>>
>;
export type GetPlayerScoreInfosQueryError = ErrorType<unknown>;

export function useGetPlayerScoreInfos<
  TData = Awaited<ReturnType<typeof getPlayerScoreInfos>>,
  TError = ErrorType<unknown>,
>(
  params: undefined | GetPlayerScoreInfosParams,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getPlayerScoreInfos>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getPlayerScoreInfos>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof httpsInstance>;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetPlayerScoreInfos<
  TData = Awaited<ReturnType<typeof getPlayerScoreInfos>>,
  TError = ErrorType<unknown>,
>(
  params?: GetPlayerScoreInfosParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getPlayerScoreInfos>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getPlayerScoreInfos>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof httpsInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetPlayerScoreInfos<
  TData = Awaited<ReturnType<typeof getPlayerScoreInfos>>,
  TError = ErrorType<unknown>,
>(
  params?: GetPlayerScoreInfosParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getPlayerScoreInfos>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof httpsInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useGetPlayerScoreInfos<
  TData = Awaited<ReturnType<typeof getPlayerScoreInfos>>,
  TError = ErrorType<unknown>,
>(
  params?: GetPlayerScoreInfosParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getPlayerScoreInfos>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof httpsInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetPlayerScoreInfosQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getGetPlayerScoreInfosSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof getPlayerScoreInfos>>,
  TError = ErrorType<unknown>,
>(
  params?: GetPlayerScoreInfosParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof getPlayerScoreInfos>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof httpsInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetPlayerScoreInfosQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getPlayerScoreInfos>>
  > = ({ signal }) => getPlayerScoreInfos(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof getPlayerScoreInfos>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetPlayerScoreInfosSuspenseQueryResult = NonNullable<
  Awaited<ReturnType<typeof getPlayerScoreInfos>>
>;
export type GetPlayerScoreInfosSuspenseQueryError = ErrorType<unknown>;

export function useGetPlayerScoreInfosSuspense<
  TData = Awaited<ReturnType<typeof getPlayerScoreInfos>>,
  TError = ErrorType<unknown>,
>(
  params: undefined | GetPlayerScoreInfosParams,
  options: {
    query: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof getPlayerScoreInfos>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof httpsInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetPlayerScoreInfosSuspense<
  TData = Awaited<ReturnType<typeof getPlayerScoreInfos>>,
  TError = ErrorType<unknown>,
>(
  params?: GetPlayerScoreInfosParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof getPlayerScoreInfos>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof httpsInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetPlayerScoreInfosSuspense<
  TData = Awaited<ReturnType<typeof getPlayerScoreInfos>>,
  TError = ErrorType<unknown>,
>(
  params?: GetPlayerScoreInfosParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof getPlayerScoreInfos>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof httpsInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useGetPlayerScoreInfosSuspense<
  TData = Awaited<ReturnType<typeof getPlayerScoreInfos>>,
  TError = ErrorType<unknown>,
>(
  params?: GetPlayerScoreInfosParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof getPlayerScoreInfos>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof httpsInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetPlayerScoreInfosSuspenseQueryOptions(
    params,
    options,
  );

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getAllPlayerHistories = (
  params?: GetAllPlayerHistoriesParams,
  options?: SecondParameter<typeof httpsInstance>,
  signal?: AbortSignal,
) => {
  return httpsInstance<BaseResponseListPlayerHistoryDto>(
    { url: `/player-histories/all`, method: 'GET', params, signal },
    options,
  );
};

export const getGetAllPlayerHistoriesQueryKey = (
  params?: GetAllPlayerHistoriesParams,
) => {
  return [`/player-histories/all`, ...(params ? [params] : [])] as const;
};

export const getGetAllPlayerHistoriesQueryOptions = <
  TData = Awaited<ReturnType<typeof getAllPlayerHistories>>,
  TError = ErrorType<unknown>,
>(
  params?: GetAllPlayerHistoriesParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getAllPlayerHistories>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof httpsInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetAllPlayerHistoriesQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getAllPlayerHistories>>
  > = ({ signal }) => getAllPlayerHistories(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getAllPlayerHistories>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetAllPlayerHistoriesQueryResult = NonNullable<
  Awaited<ReturnType<typeof getAllPlayerHistories>>
>;
export type GetAllPlayerHistoriesQueryError = ErrorType<unknown>;

export function useGetAllPlayerHistories<
  TData = Awaited<ReturnType<typeof getAllPlayerHistories>>,
  TError = ErrorType<unknown>,
>(
  params: undefined | GetAllPlayerHistoriesParams,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getAllPlayerHistories>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getAllPlayerHistories>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof httpsInstance>;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetAllPlayerHistories<
  TData = Awaited<ReturnType<typeof getAllPlayerHistories>>,
  TError = ErrorType<unknown>,
>(
  params?: GetAllPlayerHistoriesParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getAllPlayerHistories>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getAllPlayerHistories>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof httpsInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetAllPlayerHistories<
  TData = Awaited<ReturnType<typeof getAllPlayerHistories>>,
  TError = ErrorType<unknown>,
>(
  params?: GetAllPlayerHistoriesParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getAllPlayerHistories>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof httpsInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useGetAllPlayerHistories<
  TData = Awaited<ReturnType<typeof getAllPlayerHistories>>,
  TError = ErrorType<unknown>,
>(
  params?: GetAllPlayerHistoriesParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getAllPlayerHistories>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof httpsInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetAllPlayerHistoriesQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getGetAllPlayerHistoriesSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof getAllPlayerHistories>>,
  TError = ErrorType<unknown>,
>(
  params?: GetAllPlayerHistoriesParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof getAllPlayerHistories>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof httpsInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetAllPlayerHistoriesQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getAllPlayerHistories>>
  > = ({ signal }) => getAllPlayerHistories(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof getAllPlayerHistories>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetAllPlayerHistoriesSuspenseQueryResult = NonNullable<
  Awaited<ReturnType<typeof getAllPlayerHistories>>
>;
export type GetAllPlayerHistoriesSuspenseQueryError = ErrorType<unknown>;

export function useGetAllPlayerHistoriesSuspense<
  TData = Awaited<ReturnType<typeof getAllPlayerHistories>>,
  TError = ErrorType<unknown>,
>(
  params: undefined | GetAllPlayerHistoriesParams,
  options: {
    query: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof getAllPlayerHistories>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof httpsInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetAllPlayerHistoriesSuspense<
  TData = Awaited<ReturnType<typeof getAllPlayerHistories>>,
  TError = ErrorType<unknown>,
>(
  params?: GetAllPlayerHistoriesParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof getAllPlayerHistories>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof httpsInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetAllPlayerHistoriesSuspense<
  TData = Awaited<ReturnType<typeof getAllPlayerHistories>>,
  TError = ErrorType<unknown>,
>(
  params?: GetAllPlayerHistoriesParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof getAllPlayerHistories>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof httpsInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useGetAllPlayerHistoriesSuspense<
  TData = Awaited<ReturnType<typeof getAllPlayerHistories>>,
  TError = ErrorType<unknown>,
>(
  params?: GetAllPlayerHistoriesParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof getAllPlayerHistories>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof httpsInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetAllPlayerHistoriesSuspenseQueryOptions(
    params,
    options,
  );

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}
