import type { SVGProps } from 'react';
import * as React from 'react';
export const PinSlash = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    viewBox="0 0 24 24"
    {...props}>
    <path
      fill={props.fill || `currentColor`}
      d="M6 9.05v1.55c-.932 1.02-1.432 2.034-1.699 2.834-.146.438-.221.81-.26 1.08a4 4 0 0 0-.041.464V15a1 1 0 0 0 1 1h6v5a1 1 0 0 0 2 0v-4.95L10.95 14H6.221c.215-.615.639-1.446 1.486-2.293a1 1 0 0 0 .292-.659zM8 4.005 5.995 2H18a1 1 0 1 1 0 2v6.6c.932 1.02 1.432 2.034 1.698 2.834.147.438.222.81.26 1.08a4 4 0 0 1 .04.43l.002.024V15s-.005-.131 0 .001a1 1 0 0 1-.296.71l-2.064-2.065c-.244-.562-.654-1.245-1.347-1.938A1 1 0 0 1 16 11V4H8zM2.707 3.707a1 1 0 1 1 1.414-1.414l16.97 16.97a1 1 0 1 1-1.414 1.415z"
    />
  </svg>
);
