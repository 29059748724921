import { useIsMounted } from '@toss/react';

import { useViewportDevice } from './useViewportDevice';

export const useIsomorphicViewportDevice = () => {
  const { isLaptop, isTablet, isMobile, isPortraitMobile, isPortableDevice } =
    useViewportDevice();

  const isMounted = useIsMounted();

  if (!isMounted) {
    return {
      isPortableDevice: false,
      isLaptop: false,
      isTablet: false,
      isMobile: false,
      isPortraitMobile: false,
    };
  }
  return {
    isPortableDevice,
    isLaptop,
    isTablet,
    isMobile,
    isPortraitMobile,
  };
};
