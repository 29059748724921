import { ComponentPropsWithoutRef } from 'react';
import { cn } from '@zep/utils';

export const QuizLogo = (
  props: ComponentPropsWithoutRef<'picture'> & { onlySymBole?: boolean },
) => {
  const { onlySymBole, ...rest } = props;

  //
  return (
    <picture
      className={cn([
        'size-[36px] min-h-[32px] min-w-[32px] cursor-pointer',
        !onlySymBole &&
          'lg:h-[32px] lg:min-h-[28px] lg:w-[146.125px] lg:min-w-[146.125px] ',
      ])}
      {...rest}>
      {onlySymBole ? (
        <>
          <source
            className={props.className}
            srcSet="/assets/logo_small_q.svg"
          />
          <img src="/assets/logo_small_q.svg" alt="Logo" />
        </>
      ) : (
        <>
          <source
            className={props.className}
            srcSet="/assets/logo_small_q.svg"
            media="(max-width: 1023px)"
          />
          <source
            className={props.className}
            srcSet="/assets/ZEPQuiz-logo.svg"
            media="(min-width: 1024px)"
          />
          <img
            className={props.className}
            src="/assets/ZEPQuiz-logo.svg"
            alt="Logo"
          />
        </>
      )}
    </picture>
  );
};
