/**
 * Generated by orval v7.1.1 🍺
 * Do not edit manually.
 * ZEP QUIZ
 * Zep Quiz API
 * OpenAPI spec version: 0.0.1
 */

export type QuizPlanDtoPlanType =
  (typeof QuizPlanDtoPlanType)[keyof typeof QuizPlanDtoPlanType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const QuizPlanDtoPlanType = {
  FREE: 'FREE',
  PRO: 'PRO',
} as const;
