import { useMediaQuery } from 'react-responsive';

import { Breakpoints } from '../ui';

export const useViewportDeviceInternal = () => {
  const isLaptop = useMediaQuery({
    query: `(min-width: ${Breakpoints.Tablet + 1}px) and (max-width: ${
      Breakpoints.Laptop
    }px)`,
  });

  const isTablet = useMediaQuery({
    query: `(min-width: ${Breakpoints.Mobile + 1}px) and (max-width: ${
      Breakpoints.Tablet
    }px)`,
  });

  const isMobile = useMediaQuery({
    query: `(min-width: ${Breakpoints.MobileSm + 1}px) and (max-width: ${
      Breakpoints.Tablet
    }px)`,
  });

  const isMobileSmall = useMediaQuery({
    query: `(max-width: ${Breakpoints.MobileSm}px)`,
  });

  return {
    isLaptop,
    isTablet,
    isMobile,
    isMobileSmall,
  };
};
