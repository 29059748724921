import { ReactNode, useEffect } from 'react';
import { ErrorBoundary, FallbackProps } from 'react-error-boundary';
import { useQueryErrorResetBoundary } from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

const GlobalErrorBoundary = ({ children }: Props) => {
  const { reset } = useQueryErrorResetBoundary();

  useUnhandledRejectionErrors(event => {
    console.log(
      '%c [JL] event - event',
      'font-size: 16px; color:  red;',
      event,
    );
  });

  const Fallback: React.FC<FallbackProps> = ({ error, resetErrorBoundary }) => {
    if (isServerError(error)) {
      switch (error.response.status) {
        case 401:
        case 403:
          resetErrorBoundary();
          reset();
          return null;
      }
    }
    return <></>;
  };

  return (
    <ErrorBoundary FallbackComponent={Fallback} onError={console.error}>
      {children}
    </ErrorBoundary>
  );
};

export default GlobalErrorBoundary;

type Props = {
  children: ReactNode;
};

export interface ServerError<T = any, D = any> extends AxiosError<T, D> {
  response: AxiosResponse<T, D>;
}

export function isServerError<T>(error: unknown): error is ServerError<T> {
  return (
    error != null &&
    axios.isAxiosError(error) &&
    typeof (error as ServerError<T>).response?.status !== 'undefined'
  );
}

export const useUnhandledRejectionErrors = (handler: Handler) => {
  useEffect(() => {
    window.addEventListener('unhandledrejection', handler);

    return () => {
      window.removeEventListener('unhandledrejection', handler);
    };
  }, [handler]);
};

type Handler = (event: PromiseRejectionEvent) => void;
