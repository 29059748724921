import type { SVGProps } from 'react';
import * as React from 'react';
export const Enter = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    viewBox="0 0 24 24"
    {...props}>
    <path
      fill={props.fill || `currentColor`}
      fillRule="evenodd"
      d="m18 5.85-4 1.448v11.851l4-1.447zm-4.68-.433A2 2 0 0 0 12 7.298v13.277a1 1 0 0 0 1.34.94l5.34-1.932A2 2 0 0 0 20 17.702V5.851a2 2 0 0 0-2.68-1.881z"
      clipRule="evenodd"
    />
    <path
      fill={props.fill || `currentColor`}
      d="M14.473 5H9v1a1 1 0 0 1-2 0V5a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v.85a2 2 0 0 0-2.68-1.88zM12 17H9v-1a1 1 0 1 0-2 0v1a2 2 0 0 0 2 2h3z"
    />
    <path
      fill={props.fill || `currentColor`}
      fillRule="evenodd"
      d="m18 5.85-4 1.448v11.851l4-1.447zm-4.68-.433A2 2 0 0 0 12 7.298v13.277a1 1 0 0 0 1.34.94l5.34-1.932A2 2 0 0 0 20 17.702V5.851a2 2 0 0 0-2.68-1.881z"
      clipRule="evenodd"
    />
    <path
      fill={props.fill || `currentColor`}
      d="M6.293 8.293a1 1 0 0 1 1.414 0l1.994 1.993.006.007a.996.996 0 0 1 .007 1.408l-.008.007-1.999 2a1 1 0 0 1-1.414-1.415L6.586 12H3a1 1 0 1 1 0-2h3.586l-.293-.293a1 1 0 0 1 0-1.414"
    />
  </svg>
);
