import * as React from 'react';
import * as SwitchPrimitives from '@radix-ui/react-switch';

import { cn } from '../../utils';

const Switch = React.forwardRef<
  React.ElementRef<typeof SwitchPrimitives.Root>,
  React.ComponentPropsWithoutRef<typeof SwitchPrimitives.Root>
>(({ className, ...props }, ref) => (
  <SwitchPrimitives.Root
    className={cn(
      'data-[state=checked]:disabled:bg-primary-disabled data-[state=unchecked]:disabled:bg-gray-200 disabled:cursor-not-allowed',
      'data-[state=checked]:bg-primary data-[state=unchecked]:bg-gray-300',
      'peer inline-flex h-6 w-11 shrink-0 cursor-pointer items-center rounded-full border-2 border-transparent transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 focus-visible:ring-offset-background',
      className,
    )}
    {...props}
    ref={ref}>
    <SwitchPrimitives.Thumb
      className={cn(
        'data-[state=unchecked]:translate-x-0.5 disabled:data-[state:unchecked]:bg-gray-100',
        'disabled:data-[state:checked]:bg-gray-100',
        'pointer-events-none data-[state=unchecked]:bg-white data-[state=checked]:bg-white block h-5 w-5 rounded-full bg-background shadow-lg ring-0 transition-transform data-[state=checked]:translate-x-5',
      )}
    />
  </SwitchPrimitives.Root>
));
Switch.displayName = SwitchPrimitives.Root.displayName;

export { Switch };
