import { createQuery } from 'react-query-kit';
import { getQuizPlanOptions } from '@zep/api';

export const useCheckUserPro = createQuery<boolean, void>({
  queryKey: ['check-user-pro'],
  fetcher: async () => {
    try {
      const res = await getQuizPlanOptions();
      return res.data?.quizPlanType === 'PRO';
    } catch (e) {
      return false;
    }
  },
  refetchInterval: 1000 * 30,
  refetchOnMount: 'always',
  refetchOnWindowFocus: 'always',
  refetchIntervalInBackground: true,
});
