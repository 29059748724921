import type { SVGProps } from 'react';
import * as React from 'react';
export const Bulb = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    viewBox="0 0 24 24"
    {...props}>
    <path
      fill={props.fill || `currentColor`}
      fillRule="evenodd"
      d="M19 9A7 7 0 1 0 5 9c0 1.26.256 2.232.62 3.006.356.754.8 1.282 1.102 1.642l.012.015.176.208c.674.798 1.042 1.234 1.086 1.987A1.21 1.21 0 0 0 9.2 17h5.6c.68 0 1.17-.543 1.204-1.142.044-.753.412-1.189 1.086-1.987l.176-.208.012-.015c.302-.36.746-.888 1.102-1.642.364-.774.62-1.747.62-3.006m-7-5a5 5 0 0 0-5 5c0 .974.194 1.654.43 2.153.24.508.538.869.836 1.223q.096.114.2.235c.514.597 1.153 1.34 1.414 2.389h4.24c.26-1.049.9-1.792 1.414-2.389q.104-.12.2-.235c.298-.354.597-.715.836-1.223.236-.499.43-1.18.43-2.153a5 5 0 0 0-5-5"
      clipRule="evenodd"
    />
    <path
      fill={props.fill || `currentColor`}
      d="M9.4 19.25a.9.9 0 1 1 0-1.8h5.2a.9.9 0 1 1 0 1.8zM8.5 20.6a.9.9 0 0 0 .9.9h5.2a.9.9 0 1 0 0-1.8H9.4a.9.9 0 0 0-.9.9"
    />
  </svg>
);
