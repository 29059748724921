// eslint-disable-next-line simple-import-sort/imports
import '../styles/globals.scss';
import '../init';

import {
  HydrationBoundary,
  QueryCache,
  QueryClient,
  QueryClientProvider,
  QueryErrorResetBoundary,
} from '@tanstack/react-query';
import { useAnalytics, useRouteChangeTracker } from '../hooks';

import { AppProps } from 'next/app';
import { ChannelTalk } from '@zep/lib/channelTalk';
import GlobalErrorBoundary from '../components/GlobalErrorBoundary';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { Meta } from '../components/Meta';
import { NextPageWithLayout } from 'next';
import NiceModal from '@ebay/nice-modal-react';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { Toaster } from 'react-hot-toast';
import { UserProvider } from '@zep/lib/auth';
import { appWithTranslation } from 'next-i18next';
import { getRootLayout } from '@zep/layout';
import { useApiError } from '../hooks/useApiError';
import { useState } from 'react';

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

function CustomApp({ Component, pageProps }: AppPropsWithLayout) {
  const handleError = useApiError();
  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          mutations: {
            onError: handleError,
            throwOnError: false,
          },
          queries: {
            throwOnError: true,
            retry: false,
            staleTime: 1000 * 60 * 3,
          },
        },
        queryCache: new QueryCache({
          onError: handleError,
        }),
      }),
  );

  useRouteChangeTracker();
  useAnalytics();

  const getLayout = Component.getLayout ?? getRootLayout;

  return (
    <QueryErrorResetBoundary>
      <GlobalErrorBoundary>
        <QueryClientProvider client={queryClient}>
          <UserProvider>
            <NiceModal.Provider>
              <HydrationBoundary state={pageProps.dehydratedState}>
                <Meta
                  partialSeoContent={pageProps.seoContent}
                  noIndex={pageProps.noIndex}
                />
                <GoogleOAuthProvider
                  clientId={process.env.NEXT_PUBLIC_GOOGLE_OAUTH_ID || ''}>
                  <ChannelTalk>
                    <div id="root">
                      {getLayout(<Component {...pageProps} />)}
                    </div>
                  </ChannelTalk>
                </GoogleOAuthProvider>
                <Toaster position={'top-right'} />
                <ReactQueryDevtools
                  buttonPosition={'bottom-left'}
                  initialIsOpen={false}
                />
              </HydrationBoundary>
            </NiceModal.Provider>
          </UserProvider>
        </QueryClientProvider>
      </GlobalErrorBoundary>
    </QueryErrorResetBoundary>
  );
}

export default appWithTranslation(CustomApp);
