/**
 * Generated by orval v7.1.1 🍺
 * Do not edit manually.
 * ZEP QUIZ
 * Zep Quiz API
 * OpenAPI spec version: 0.0.1
 */
import type {
  DefinedInitialDataOptions,
  DefinedUseQueryResult,
  MutationFunction,
  QueryFunction,
  QueryKey,
  UndefinedInitialDataOptions,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
  UseSuspenseQueryOptions,
  UseSuspenseQueryResult,
} from '@tanstack/react-query';
import { useMutation, useQuery, useSuspenseQuery } from '@tanstack/react-query';

import type { BodyType, ErrorType } from '../../httpClient';
import { httpsInstance } from '../../httpClient';
import type {
  BaseResponseMemberDetailsRes,
  BaseResponseSuccessResponse,
  BaseResponseUpdateIsPublicRes,
  BaseResponseUpdateIsSelectedRes,
  CategoryDetailsRes,
  CategorySetDetailsRes,
  CreateCategoryReq,
  CreateCategorySetReq,
  GetAllQuizzes1Params,
  GetMemberInfo1Params,
  ResetUserType1Params,
  SearchResponseListSearchQuizResV1,
  SetAdmin1Params,
  SetAmbassadorMemberReq,
  SuccessResponse,
  UpdateIsPublicReq,
  UpdateIsSelectedReq,
  UpdateQuizCategoryReq,
} from '.././_model';

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

export const createCategory = (
  createCategoryReq: BodyType<CreateCategoryReq>,
  options?: SecondParameter<typeof httpsInstance>,
) => {
  return httpsInstance<CategoryDetailsRes>(
    {
      url: `/adminV2/category`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: createCategoryReq,
    },
    options,
  );
};

export const getCreateCategoryMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createCategory>>,
    TError,
    { data: BodyType<CreateCategoryReq> },
    TContext
  >;
  request?: SecondParameter<typeof httpsInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof createCategory>>,
  TError,
  { data: BodyType<CreateCategoryReq> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createCategory>>,
    { data: BodyType<CreateCategoryReq> }
  > = props => {
    const { data } = props ?? {};

    return createCategory(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type CreateCategoryMutationResult = NonNullable<
  Awaited<ReturnType<typeof createCategory>>
>;
export type CreateCategoryMutationBody = BodyType<CreateCategoryReq>;
export type CreateCategoryMutationError = ErrorType<unknown>;

export const useCreateCategory = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createCategory>>,
    TError,
    { data: BodyType<CreateCategoryReq> },
    TContext
  >;
  request?: SecondParameter<typeof httpsInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof createCategory>>,
  TError,
  { data: BodyType<CreateCategoryReq> },
  TContext
> => {
  const mutationOptions = getCreateCategoryMutationOptions(options);

  return useMutation(mutationOptions);
};
export const createCategorySet = (
  createCategorySetReq: BodyType<CreateCategorySetReq>,
  options?: SecondParameter<typeof httpsInstance>,
) => {
  return httpsInstance<CategorySetDetailsRes>(
    {
      url: `/adminV2/categorySet`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: createCategorySetReq,
    },
    options,
  );
};

export const getCreateCategorySetMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createCategorySet>>,
    TError,
    { data: BodyType<CreateCategorySetReq> },
    TContext
  >;
  request?: SecondParameter<typeof httpsInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof createCategorySet>>,
  TError,
  { data: BodyType<CreateCategorySetReq> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createCategorySet>>,
    { data: BodyType<CreateCategorySetReq> }
  > = props => {
    const { data } = props ?? {};

    return createCategorySet(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type CreateCategorySetMutationResult = NonNullable<
  Awaited<ReturnType<typeof createCategorySet>>
>;
export type CreateCategorySetMutationBody = BodyType<CreateCategorySetReq>;
export type CreateCategorySetMutationError = ErrorType<unknown>;

export const useCreateCategorySet = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createCategorySet>>,
    TError,
    { data: BodyType<CreateCategorySetReq> },
    TContext
  >;
  request?: SecondParameter<typeof httpsInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof createCategorySet>>,
  TError,
  { data: BodyType<CreateCategorySetReq> },
  TContext
> => {
  const mutationOptions = getCreateCategorySetMutationOptions(options);

  return useMutation(mutationOptions);
};
export const resetUserType1 = (
  params: ResetUserType1Params,
  options?: SecondParameter<typeof httpsInstance>,
) => {
  return httpsInstance<SuccessResponse>(
    { url: `/adminV2/reset/memberType`, method: 'PATCH', params },
    options,
  );
};

export const getResetUserType1MutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof resetUserType1>>,
    TError,
    { params: ResetUserType1Params },
    TContext
  >;
  request?: SecondParameter<typeof httpsInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof resetUserType1>>,
  TError,
  { params: ResetUserType1Params },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof resetUserType1>>,
    { params: ResetUserType1Params }
  > = props => {
    const { params } = props ?? {};

    return resetUserType1(params, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type ResetUserType1MutationResult = NonNullable<
  Awaited<ReturnType<typeof resetUserType1>>
>;

export type ResetUserType1MutationError = ErrorType<unknown>;

export const useResetUserType1 = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof resetUserType1>>,
    TError,
    { params: ResetUserType1Params },
    TContext
  >;
  request?: SecondParameter<typeof httpsInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof resetUserType1>>,
  TError,
  { params: ResetUserType1Params },
  TContext
> => {
  const mutationOptions = getResetUserType1MutationOptions(options);

  return useMutation(mutationOptions);
};
export const updateIsSelected = (
  updateIsSelectedReq: BodyType<UpdateIsSelectedReq>,
  options?: SecondParameter<typeof httpsInstance>,
) => {
  return httpsInstance<BaseResponseUpdateIsSelectedRes>(
    {
      url: `/adminV2/edit/isSelected`,
      method: 'PATCH',
      headers: { 'Content-Type': 'application/json' },
      data: updateIsSelectedReq,
    },
    options,
  );
};

export const getUpdateIsSelectedMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateIsSelected>>,
    TError,
    { data: BodyType<UpdateIsSelectedReq> },
    TContext
  >;
  request?: SecondParameter<typeof httpsInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateIsSelected>>,
  TError,
  { data: BodyType<UpdateIsSelectedReq> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateIsSelected>>,
    { data: BodyType<UpdateIsSelectedReq> }
  > = props => {
    const { data } = props ?? {};

    return updateIsSelected(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateIsSelectedMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateIsSelected>>
>;
export type UpdateIsSelectedMutationBody = BodyType<UpdateIsSelectedReq>;
export type UpdateIsSelectedMutationError = ErrorType<unknown>;

export const useUpdateIsSelected = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateIsSelected>>,
    TError,
    { data: BodyType<UpdateIsSelectedReq> },
    TContext
  >;
  request?: SecondParameter<typeof httpsInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof updateIsSelected>>,
  TError,
  { data: BodyType<UpdateIsSelectedReq> },
  TContext
> => {
  const mutationOptions = getUpdateIsSelectedMutationOptions(options);

  return useMutation(mutationOptions);
};
export const updateIsPublic = (
  updateIsPublicReq: BodyType<UpdateIsPublicReq>,
  options?: SecondParameter<typeof httpsInstance>,
) => {
  return httpsInstance<BaseResponseUpdateIsPublicRes>(
    {
      url: `/adminV2/edit/isPublic`,
      method: 'PATCH',
      headers: { 'Content-Type': 'application/json' },
      data: updateIsPublicReq,
    },
    options,
  );
};

export const getUpdateIsPublicMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateIsPublic>>,
    TError,
    { data: BodyType<UpdateIsPublicReq> },
    TContext
  >;
  request?: SecondParameter<typeof httpsInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateIsPublic>>,
  TError,
  { data: BodyType<UpdateIsPublicReq> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateIsPublic>>,
    { data: BodyType<UpdateIsPublicReq> }
  > = props => {
    const { data } = props ?? {};

    return updateIsPublic(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateIsPublicMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateIsPublic>>
>;
export type UpdateIsPublicMutationBody = BodyType<UpdateIsPublicReq>;
export type UpdateIsPublicMutationError = ErrorType<unknown>;

export const useUpdateIsPublic = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateIsPublic>>,
    TError,
    { data: BodyType<UpdateIsPublicReq> },
    TContext
  >;
  request?: SecondParameter<typeof httpsInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof updateIsPublic>>,
  TError,
  { data: BodyType<UpdateIsPublicReq> },
  TContext
> => {
  const mutationOptions = getUpdateIsPublicMutationOptions(options);

  return useMutation(mutationOptions);
};
export const updateQuizCategory = (
  updateQuizCategoryReq: BodyType<UpdateQuizCategoryReq>,
  options?: SecondParameter<typeof httpsInstance>,
) => {
  return httpsInstance<SuccessResponse>(
    {
      url: `/adminV2/edit/category`,
      method: 'PATCH',
      headers: { 'Content-Type': 'application/json' },
      data: updateQuizCategoryReq,
    },
    options,
  );
};

export const getUpdateQuizCategoryMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateQuizCategory>>,
    TError,
    { data: BodyType<UpdateQuizCategoryReq> },
    TContext
  >;
  request?: SecondParameter<typeof httpsInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateQuizCategory>>,
  TError,
  { data: BodyType<UpdateQuizCategoryReq> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateQuizCategory>>,
    { data: BodyType<UpdateQuizCategoryReq> }
  > = props => {
    const { data } = props ?? {};

    return updateQuizCategory(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateQuizCategoryMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateQuizCategory>>
>;
export type UpdateQuizCategoryMutationBody = BodyType<UpdateQuizCategoryReq>;
export type UpdateQuizCategoryMutationError = ErrorType<unknown>;

export const useUpdateQuizCategory = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateQuizCategory>>,
    TError,
    { data: BodyType<UpdateQuizCategoryReq> },
    TContext
  >;
  request?: SecondParameter<typeof httpsInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof updateQuizCategory>>,
  TError,
  { data: BodyType<UpdateQuizCategoryReq> },
  TContext
> => {
  const mutationOptions = getUpdateQuizCategoryMutationOptions(options);

  return useMutation(mutationOptions);
};
export const setAdmin1 = (
  params: SetAdmin1Params,
  options?: SecondParameter<typeof httpsInstance>,
) => {
  return httpsInstance<SuccessResponse>(
    { url: `/adminV2/edit/admin`, method: 'PATCH', params },
    options,
  );
};

export const getSetAdmin1MutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof setAdmin1>>,
    TError,
    { params: SetAdmin1Params },
    TContext
  >;
  request?: SecondParameter<typeof httpsInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof setAdmin1>>,
  TError,
  { params: SetAdmin1Params },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof setAdmin1>>,
    { params: SetAdmin1Params }
  > = props => {
    const { params } = props ?? {};

    return setAdmin1(params, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type SetAdmin1MutationResult = NonNullable<
  Awaited<ReturnType<typeof setAdmin1>>
>;

export type SetAdmin1MutationError = ErrorType<unknown>;

export const useSetAdmin1 = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof setAdmin1>>,
    TError,
    { params: SetAdmin1Params },
    TContext
  >;
  request?: SecondParameter<typeof httpsInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof setAdmin1>>,
  TError,
  { params: SetAdmin1Params },
  TContext
> => {
  const mutationOptions = getSetAdmin1MutationOptions(options);

  return useMutation(mutationOptions);
};
export const setAmbassador1 = (
  setAmbassadorMemberReq: BodyType<SetAmbassadorMemberReq>,
  options?: SecondParameter<typeof httpsInstance>,
) => {
  return httpsInstance<BaseResponseSuccessResponse>(
    {
      url: `/adminV2/ambassador`,
      method: 'PATCH',
      headers: { 'Content-Type': 'application/json' },
      data: setAmbassadorMemberReq,
    },
    options,
  );
};

export const getSetAmbassador1MutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof setAmbassador1>>,
    TError,
    { data: BodyType<SetAmbassadorMemberReq> },
    TContext
  >;
  request?: SecondParameter<typeof httpsInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof setAmbassador1>>,
  TError,
  { data: BodyType<SetAmbassadorMemberReq> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof setAmbassador1>>,
    { data: BodyType<SetAmbassadorMemberReq> }
  > = props => {
    const { data } = props ?? {};

    return setAmbassador1(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type SetAmbassador1MutationResult = NonNullable<
  Awaited<ReturnType<typeof setAmbassador1>>
>;
export type SetAmbassador1MutationBody = BodyType<SetAmbassadorMemberReq>;
export type SetAmbassador1MutationError = ErrorType<unknown>;

export const useSetAmbassador1 = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof setAmbassador1>>,
    TError,
    { data: BodyType<SetAmbassadorMemberReq> },
    TContext
  >;
  request?: SecondParameter<typeof httpsInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof setAmbassador1>>,
  TError,
  { data: BodyType<SetAmbassadorMemberReq> },
  TContext
> => {
  const mutationOptions = getSetAmbassador1MutationOptions(options);

  return useMutation(mutationOptions);
};
export const getAllQuizzes1 = (
  params?: GetAllQuizzes1Params,
  options?: SecondParameter<typeof httpsInstance>,
  signal?: AbortSignal,
) => {
  return httpsInstance<SearchResponseListSearchQuizResV1>(
    { url: `/adminV2/search`, method: 'GET', params, signal },
    options,
  );
};

export const getGetAllQuizzes1QueryKey = (params?: GetAllQuizzes1Params) => {
  return [`/adminV2/search`, ...(params ? [params] : [])] as const;
};

export const getGetAllQuizzes1QueryOptions = <
  TData = Awaited<ReturnType<typeof getAllQuizzes1>>,
  TError = ErrorType<unknown>,
>(
  params?: GetAllQuizzes1Params,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getAllQuizzes1>>, TError, TData>
    >;
    request?: SecondParameter<typeof httpsInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetAllQuizzes1QueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getAllQuizzes1>>> = ({
    signal,
  }) => getAllQuizzes1(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getAllQuizzes1>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetAllQuizzes1QueryResult = NonNullable<
  Awaited<ReturnType<typeof getAllQuizzes1>>
>;
export type GetAllQuizzes1QueryError = ErrorType<unknown>;

export function useGetAllQuizzes1<
  TData = Awaited<ReturnType<typeof getAllQuizzes1>>,
  TError = ErrorType<unknown>,
>(
  params: undefined | GetAllQuizzes1Params,
  options: {
    query: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getAllQuizzes1>>, TError, TData>
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getAllQuizzes1>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof httpsInstance>;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetAllQuizzes1<
  TData = Awaited<ReturnType<typeof getAllQuizzes1>>,
  TError = ErrorType<unknown>,
>(
  params?: GetAllQuizzes1Params,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getAllQuizzes1>>, TError, TData>
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getAllQuizzes1>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof httpsInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetAllQuizzes1<
  TData = Awaited<ReturnType<typeof getAllQuizzes1>>,
  TError = ErrorType<unknown>,
>(
  params?: GetAllQuizzes1Params,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getAllQuizzes1>>, TError, TData>
    >;
    request?: SecondParameter<typeof httpsInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useGetAllQuizzes1<
  TData = Awaited<ReturnType<typeof getAllQuizzes1>>,
  TError = ErrorType<unknown>,
>(
  params?: GetAllQuizzes1Params,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getAllQuizzes1>>, TError, TData>
    >;
    request?: SecondParameter<typeof httpsInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetAllQuizzes1QueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getGetAllQuizzes1SuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof getAllQuizzes1>>,
  TError = ErrorType<unknown>,
>(
  params?: GetAllQuizzes1Params,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof getAllQuizzes1>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof httpsInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetAllQuizzes1QueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getAllQuizzes1>>> = ({
    signal,
  }) => getAllQuizzes1(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof getAllQuizzes1>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetAllQuizzes1SuspenseQueryResult = NonNullable<
  Awaited<ReturnType<typeof getAllQuizzes1>>
>;
export type GetAllQuizzes1SuspenseQueryError = ErrorType<unknown>;

export function useGetAllQuizzes1Suspense<
  TData = Awaited<ReturnType<typeof getAllQuizzes1>>,
  TError = ErrorType<unknown>,
>(
  params: undefined | GetAllQuizzes1Params,
  options: {
    query: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof getAllQuizzes1>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof httpsInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetAllQuizzes1Suspense<
  TData = Awaited<ReturnType<typeof getAllQuizzes1>>,
  TError = ErrorType<unknown>,
>(
  params?: GetAllQuizzes1Params,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof getAllQuizzes1>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof httpsInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetAllQuizzes1Suspense<
  TData = Awaited<ReturnType<typeof getAllQuizzes1>>,
  TError = ErrorType<unknown>,
>(
  params?: GetAllQuizzes1Params,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof getAllQuizzes1>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof httpsInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useGetAllQuizzes1Suspense<
  TData = Awaited<ReturnType<typeof getAllQuizzes1>>,
  TError = ErrorType<unknown>,
>(
  params?: GetAllQuizzes1Params,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof getAllQuizzes1>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof httpsInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetAllQuizzes1SuspenseQueryOptions(params, options);

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getMemberInfo1 = (
  params: GetMemberInfo1Params,
  options?: SecondParameter<typeof httpsInstance>,
  signal?: AbortSignal,
) => {
  return httpsInstance<BaseResponseMemberDetailsRes>(
    { url: `/adminV2/memberInfo`, method: 'GET', params, signal },
    options,
  );
};

export const getGetMemberInfo1QueryKey = (params: GetMemberInfo1Params) => {
  return [`/adminV2/memberInfo`, ...(params ? [params] : [])] as const;
};

export const getGetMemberInfo1QueryOptions = <
  TData = Awaited<ReturnType<typeof getMemberInfo1>>,
  TError = ErrorType<unknown>,
>(
  params: GetMemberInfo1Params,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getMemberInfo1>>, TError, TData>
    >;
    request?: SecondParameter<typeof httpsInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetMemberInfo1QueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getMemberInfo1>>> = ({
    signal,
  }) => getMemberInfo1(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getMemberInfo1>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetMemberInfo1QueryResult = NonNullable<
  Awaited<ReturnType<typeof getMemberInfo1>>
>;
export type GetMemberInfo1QueryError = ErrorType<unknown>;

export function useGetMemberInfo1<
  TData = Awaited<ReturnType<typeof getMemberInfo1>>,
  TError = ErrorType<unknown>,
>(
  params: GetMemberInfo1Params,
  options: {
    query: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getMemberInfo1>>, TError, TData>
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getMemberInfo1>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof httpsInstance>;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetMemberInfo1<
  TData = Awaited<ReturnType<typeof getMemberInfo1>>,
  TError = ErrorType<unknown>,
>(
  params: GetMemberInfo1Params,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getMemberInfo1>>, TError, TData>
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getMemberInfo1>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof httpsInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetMemberInfo1<
  TData = Awaited<ReturnType<typeof getMemberInfo1>>,
  TError = ErrorType<unknown>,
>(
  params: GetMemberInfo1Params,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getMemberInfo1>>, TError, TData>
    >;
    request?: SecondParameter<typeof httpsInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useGetMemberInfo1<
  TData = Awaited<ReturnType<typeof getMemberInfo1>>,
  TError = ErrorType<unknown>,
>(
  params: GetMemberInfo1Params,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getMemberInfo1>>, TError, TData>
    >;
    request?: SecondParameter<typeof httpsInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetMemberInfo1QueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getGetMemberInfo1SuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof getMemberInfo1>>,
  TError = ErrorType<unknown>,
>(
  params: GetMemberInfo1Params,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof getMemberInfo1>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof httpsInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetMemberInfo1QueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getMemberInfo1>>> = ({
    signal,
  }) => getMemberInfo1(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof getMemberInfo1>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetMemberInfo1SuspenseQueryResult = NonNullable<
  Awaited<ReturnType<typeof getMemberInfo1>>
>;
export type GetMemberInfo1SuspenseQueryError = ErrorType<unknown>;

export function useGetMemberInfo1Suspense<
  TData = Awaited<ReturnType<typeof getMemberInfo1>>,
  TError = ErrorType<unknown>,
>(
  params: GetMemberInfo1Params,
  options: {
    query: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof getMemberInfo1>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof httpsInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetMemberInfo1Suspense<
  TData = Awaited<ReturnType<typeof getMemberInfo1>>,
  TError = ErrorType<unknown>,
>(
  params: GetMemberInfo1Params,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof getMemberInfo1>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof httpsInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetMemberInfo1Suspense<
  TData = Awaited<ReturnType<typeof getMemberInfo1>>,
  TError = ErrorType<unknown>,
>(
  params: GetMemberInfo1Params,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof getMemberInfo1>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof httpsInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useGetMemberInfo1Suspense<
  TData = Awaited<ReturnType<typeof getMemberInfo1>>,
  TError = ErrorType<unknown>,
>(
  params: GetMemberInfo1Params,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof getMemberInfo1>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof httpsInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetMemberInfo1SuspenseQueryOptions(params, options);

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}
