import type { SVGProps } from 'react';
import * as React from 'react';
export const CurrencyWonCircleFill = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    viewBox="0 0 24 24"
    {...props}>
    <path
      fill={props.fill || `currentColor`}
      d="M9.628 14.228h-.08l-.22-1.094h.552zM12.033 10.86l.226 1.006h-.531l.226-1.007zM14.452 14.24h-.08l-.259-1.106h.558z"
    />
    <path
      fill={props.fill || `currentColor`}
      fillRule="evenodd"
      d="M12 2C6.477 2 2 6.477 2 12s4.477 10 10 10 10-4.477 10-10S17.523 2 12 2M6 13.134h1.488L8.418 17h2.167l.863-3.866h1.09L13.402 17h2.166l.937-3.866H18v-1.268h-1.19L17.749 8H15.7l-.77 3.866h-1.11L12.924 8h-1.848l-.897 3.866H9.076L8.3 8H6.252l.93 3.866H6z"
      clipRule="evenodd"
    />
  </svg>
);
