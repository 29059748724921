/**
 * Generated by orval v7.1.1 🍺
 * Do not edit manually.
 * ZEP QUIZ
 * Zep Quiz API
 * OpenAPI spec version: 0.0.1
 */
import type {
  MutationFunction,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import { useMutation } from '@tanstack/react-query';

import type { BodyType, ErrorType } from '../../httpClient';
import { httpsInstance } from '../../httpClient';
import type {
  AdminAddWorkspaceUserRequest,
  AdminDeleteWorkspaceUserRequest,
  SuccessResponse,
} from '.././_model';

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

export const addWorkspaceUser = (
  adminAddWorkspaceUserRequest: BodyType<AdminAddWorkspaceUserRequest>,
  options?: SecondParameter<typeof httpsInstance>,
) => {
  return httpsInstance<SuccessResponse>(
    {
      url: `/admin/workspaces/users`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: adminAddWorkspaceUserRequest,
    },
    options,
  );
};

export const getAddWorkspaceUserMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof addWorkspaceUser>>,
    TError,
    { data: BodyType<AdminAddWorkspaceUserRequest> },
    TContext
  >;
  request?: SecondParameter<typeof httpsInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof addWorkspaceUser>>,
  TError,
  { data: BodyType<AdminAddWorkspaceUserRequest> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof addWorkspaceUser>>,
    { data: BodyType<AdminAddWorkspaceUserRequest> }
  > = props => {
    const { data } = props ?? {};

    return addWorkspaceUser(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type AddWorkspaceUserMutationResult = NonNullable<
  Awaited<ReturnType<typeof addWorkspaceUser>>
>;
export type AddWorkspaceUserMutationBody =
  BodyType<AdminAddWorkspaceUserRequest>;
export type AddWorkspaceUserMutationError = ErrorType<unknown>;

export const useAddWorkspaceUser = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof addWorkspaceUser>>,
    TError,
    { data: BodyType<AdminAddWorkspaceUserRequest> },
    TContext
  >;
  request?: SecondParameter<typeof httpsInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof addWorkspaceUser>>,
  TError,
  { data: BodyType<AdminAddWorkspaceUserRequest> },
  TContext
> => {
  const mutationOptions = getAddWorkspaceUserMutationOptions(options);

  return useMutation(mutationOptions);
};
export const deleteWorkspaceUser = (
  adminDeleteWorkspaceUserRequest: BodyType<AdminDeleteWorkspaceUserRequest>,
  options?: SecondParameter<typeof httpsInstance>,
) => {
  return httpsInstance<SuccessResponse>(
    {
      url: `/admin/workspaces/users`,
      method: 'DELETE',
      headers: { 'Content-Type': 'application/json' },
      data: adminDeleteWorkspaceUserRequest,
    },
    options,
  );
};

export const getDeleteWorkspaceUserMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteWorkspaceUser>>,
    TError,
    { data: BodyType<AdminDeleteWorkspaceUserRequest> },
    TContext
  >;
  request?: SecondParameter<typeof httpsInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteWorkspaceUser>>,
  TError,
  { data: BodyType<AdminDeleteWorkspaceUserRequest> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteWorkspaceUser>>,
    { data: BodyType<AdminDeleteWorkspaceUserRequest> }
  > = props => {
    const { data } = props ?? {};

    return deleteWorkspaceUser(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteWorkspaceUserMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteWorkspaceUser>>
>;
export type DeleteWorkspaceUserMutationBody =
  BodyType<AdminDeleteWorkspaceUserRequest>;
export type DeleteWorkspaceUserMutationError = ErrorType<unknown>;

export const useDeleteWorkspaceUser = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteWorkspaceUser>>,
    TError,
    { data: BodyType<AdminDeleteWorkspaceUserRequest> },
    TContext
  >;
  request?: SecondParameter<typeof httpsInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof deleteWorkspaceUser>>,
  TError,
  { data: BodyType<AdminDeleteWorkspaceUserRequest> },
  TContext
> => {
  const mutationOptions = getDeleteWorkspaceUserMutationOptions(options);

  return useMutation(mutationOptions);
};
