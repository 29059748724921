/**
 * Generated by orval v7.1.1 🍺
 * Do not edit manually.
 * ZEP QUIZ
 * Zep Quiz API
 * OpenAPI spec version: 0.0.1
 */
import type {
  DefinedInitialDataOptions,
  DefinedUseQueryResult,
  MutationFunction,
  QueryFunction,
  QueryKey,
  UndefinedInitialDataOptions,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
  UseSuspenseQueryOptions,
  UseSuspenseQueryResult,
} from '@tanstack/react-query';
import { useMutation, useQuery, useSuspenseQuery } from '@tanstack/react-query';

import type { BodyType, ErrorType } from '../../httpClient';
import { httpsInstance } from '../../httpClient';
import type {
  AdminCreateQuizPlanAndWorkspaceRequest,
  AdminDeleteQuizPlanRequest,
  AdminModifyQuizPlanRequest,
  AdminSetQuizPlanSchedulerTimeRequest,
  BaseResponseAdminCreateQuizPlanAndWorkspaceResponse,
  BaseResponseAdminGetQuizPlanDetailsResponse,
  GetQuizPlanDetailsParams,
  SearchQuizPlansParams,
  SearchResponseListQuizPlanWithWorkspaceDto,
  SuccessResponse,
} from '.././_model';

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

export const searchQuizPlans = (
  params: SearchQuizPlansParams,
  options?: SecondParameter<typeof httpsInstance>,
  signal?: AbortSignal,
) => {
  return httpsInstance<SearchResponseListQuizPlanWithWorkspaceDto>(
    { url: `/admin/quiz-plans`, method: 'GET', params, signal },
    options,
  );
};

export const getSearchQuizPlansQueryKey = (params: SearchQuizPlansParams) => {
  return [`/admin/quiz-plans`, ...(params ? [params] : [])] as const;
};

export const getSearchQuizPlansQueryOptions = <
  TData = Awaited<ReturnType<typeof searchQuizPlans>>,
  TError = ErrorType<unknown>,
>(
  params: SearchQuizPlansParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof searchQuizPlans>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof httpsInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getSearchQuizPlansQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof searchQuizPlans>>> = ({
    signal,
  }) => searchQuizPlans(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof searchQuizPlans>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type SearchQuizPlansQueryResult = NonNullable<
  Awaited<ReturnType<typeof searchQuizPlans>>
>;
export type SearchQuizPlansQueryError = ErrorType<unknown>;

export function useSearchQuizPlans<
  TData = Awaited<ReturnType<typeof searchQuizPlans>>,
  TError = ErrorType<unknown>,
>(
  params: SearchQuizPlansParams,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof searchQuizPlans>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof searchQuizPlans>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof httpsInstance>;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useSearchQuizPlans<
  TData = Awaited<ReturnType<typeof searchQuizPlans>>,
  TError = ErrorType<unknown>,
>(
  params: SearchQuizPlansParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof searchQuizPlans>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof searchQuizPlans>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof httpsInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useSearchQuizPlans<
  TData = Awaited<ReturnType<typeof searchQuizPlans>>,
  TError = ErrorType<unknown>,
>(
  params: SearchQuizPlansParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof searchQuizPlans>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof httpsInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useSearchQuizPlans<
  TData = Awaited<ReturnType<typeof searchQuizPlans>>,
  TError = ErrorType<unknown>,
>(
  params: SearchQuizPlansParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof searchQuizPlans>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof httpsInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getSearchQuizPlansQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getSearchQuizPlansSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof searchQuizPlans>>,
  TError = ErrorType<unknown>,
>(
  params: SearchQuizPlansParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof searchQuizPlans>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof httpsInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getSearchQuizPlansQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof searchQuizPlans>>> = ({
    signal,
  }) => searchQuizPlans(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof searchQuizPlans>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type SearchQuizPlansSuspenseQueryResult = NonNullable<
  Awaited<ReturnType<typeof searchQuizPlans>>
>;
export type SearchQuizPlansSuspenseQueryError = ErrorType<unknown>;

export function useSearchQuizPlansSuspense<
  TData = Awaited<ReturnType<typeof searchQuizPlans>>,
  TError = ErrorType<unknown>,
>(
  params: SearchQuizPlansParams,
  options: {
    query: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof searchQuizPlans>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof httpsInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useSearchQuizPlansSuspense<
  TData = Awaited<ReturnType<typeof searchQuizPlans>>,
  TError = ErrorType<unknown>,
>(
  params: SearchQuizPlansParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof searchQuizPlans>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof httpsInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useSearchQuizPlansSuspense<
  TData = Awaited<ReturnType<typeof searchQuizPlans>>,
  TError = ErrorType<unknown>,
>(
  params: SearchQuizPlansParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof searchQuizPlans>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof httpsInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useSearchQuizPlansSuspense<
  TData = Awaited<ReturnType<typeof searchQuizPlans>>,
  TError = ErrorType<unknown>,
>(
  params: SearchQuizPlansParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof searchQuizPlans>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof httpsInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getSearchQuizPlansSuspenseQueryOptions(params, options);

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const modifyQuizPlan = (
  adminModifyQuizPlanRequest: BodyType<AdminModifyQuizPlanRequest>,
  options?: SecondParameter<typeof httpsInstance>,
) => {
  return httpsInstance<SuccessResponse>(
    {
      url: `/admin/quiz-plans`,
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      data: adminModifyQuizPlanRequest,
    },
    options,
  );
};

export const getModifyQuizPlanMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof modifyQuizPlan>>,
    TError,
    { data: BodyType<AdminModifyQuizPlanRequest> },
    TContext
  >;
  request?: SecondParameter<typeof httpsInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof modifyQuizPlan>>,
  TError,
  { data: BodyType<AdminModifyQuizPlanRequest> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof modifyQuizPlan>>,
    { data: BodyType<AdminModifyQuizPlanRequest> }
  > = props => {
    const { data } = props ?? {};

    return modifyQuizPlan(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type ModifyQuizPlanMutationResult = NonNullable<
  Awaited<ReturnType<typeof modifyQuizPlan>>
>;
export type ModifyQuizPlanMutationBody = BodyType<AdminModifyQuizPlanRequest>;
export type ModifyQuizPlanMutationError = ErrorType<unknown>;

export const useModifyQuizPlan = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof modifyQuizPlan>>,
    TError,
    { data: BodyType<AdminModifyQuizPlanRequest> },
    TContext
  >;
  request?: SecondParameter<typeof httpsInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof modifyQuizPlan>>,
  TError,
  { data: BodyType<AdminModifyQuizPlanRequest> },
  TContext
> => {
  const mutationOptions = getModifyQuizPlanMutationOptions(options);

  return useMutation(mutationOptions);
};
export const createQuizPlanAndWorkspace = (
  adminCreateQuizPlanAndWorkspaceRequest: BodyType<AdminCreateQuizPlanAndWorkspaceRequest>,
  options?: SecondParameter<typeof httpsInstance>,
) => {
  return httpsInstance<BaseResponseAdminCreateQuizPlanAndWorkspaceResponse>(
    {
      url: `/admin/quiz-plans`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: adminCreateQuizPlanAndWorkspaceRequest,
    },
    options,
  );
};

export const getCreateQuizPlanAndWorkspaceMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createQuizPlanAndWorkspace>>,
    TError,
    { data: BodyType<AdminCreateQuizPlanAndWorkspaceRequest> },
    TContext
  >;
  request?: SecondParameter<typeof httpsInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof createQuizPlanAndWorkspace>>,
  TError,
  { data: BodyType<AdminCreateQuizPlanAndWorkspaceRequest> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createQuizPlanAndWorkspace>>,
    { data: BodyType<AdminCreateQuizPlanAndWorkspaceRequest> }
  > = props => {
    const { data } = props ?? {};

    return createQuizPlanAndWorkspace(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type CreateQuizPlanAndWorkspaceMutationResult = NonNullable<
  Awaited<ReturnType<typeof createQuizPlanAndWorkspace>>
>;
export type CreateQuizPlanAndWorkspaceMutationBody =
  BodyType<AdminCreateQuizPlanAndWorkspaceRequest>;
export type CreateQuizPlanAndWorkspaceMutationError = ErrorType<unknown>;

export const useCreateQuizPlanAndWorkspace = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createQuizPlanAndWorkspace>>,
    TError,
    { data: BodyType<AdminCreateQuizPlanAndWorkspaceRequest> },
    TContext
  >;
  request?: SecondParameter<typeof httpsInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof createQuizPlanAndWorkspace>>,
  TError,
  { data: BodyType<AdminCreateQuizPlanAndWorkspaceRequest> },
  TContext
> => {
  const mutationOptions = getCreateQuizPlanAndWorkspaceMutationOptions(options);

  return useMutation(mutationOptions);
};
export const deleteQuizPlan = (
  adminDeleteQuizPlanRequest: BodyType<AdminDeleteQuizPlanRequest>,
  options?: SecondParameter<typeof httpsInstance>,
) => {
  return httpsInstance<SuccessResponse>(
    {
      url: `/admin/quiz-plans`,
      method: 'DELETE',
      headers: { 'Content-Type': 'application/json' },
      data: adminDeleteQuizPlanRequest,
    },
    options,
  );
};

export const getDeleteQuizPlanMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteQuizPlan>>,
    TError,
    { data: BodyType<AdminDeleteQuizPlanRequest> },
    TContext
  >;
  request?: SecondParameter<typeof httpsInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteQuizPlan>>,
  TError,
  { data: BodyType<AdminDeleteQuizPlanRequest> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteQuizPlan>>,
    { data: BodyType<AdminDeleteQuizPlanRequest> }
  > = props => {
    const { data } = props ?? {};

    return deleteQuizPlan(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteQuizPlanMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteQuizPlan>>
>;
export type DeleteQuizPlanMutationBody = BodyType<AdminDeleteQuizPlanRequest>;
export type DeleteQuizPlanMutationError = ErrorType<unknown>;

export const useDeleteQuizPlan = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteQuizPlan>>,
    TError,
    { data: BodyType<AdminDeleteQuizPlanRequest> },
    TContext
  >;
  request?: SecondParameter<typeof httpsInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof deleteQuizPlan>>,
  TError,
  { data: BodyType<AdminDeleteQuizPlanRequest> },
  TContext
> => {
  const mutationOptions = getDeleteQuizPlanMutationOptions(options);

  return useMutation(mutationOptions);
};
export const setQuizPlanSchedulerTime = (
  adminSetQuizPlanSchedulerTimeRequest: BodyType<AdminSetQuizPlanSchedulerTimeRequest>,
  options?: SecondParameter<typeof httpsInstance>,
) => {
  return httpsInstance<SuccessResponse>(
    {
      url: `/admin/quiz-plans/scheduler/time-set`,
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      data: adminSetQuizPlanSchedulerTimeRequest,
    },
    options,
  );
};

export const getSetQuizPlanSchedulerTimeMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof setQuizPlanSchedulerTime>>,
    TError,
    { data: BodyType<AdminSetQuizPlanSchedulerTimeRequest> },
    TContext
  >;
  request?: SecondParameter<typeof httpsInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof setQuizPlanSchedulerTime>>,
  TError,
  { data: BodyType<AdminSetQuizPlanSchedulerTimeRequest> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof setQuizPlanSchedulerTime>>,
    { data: BodyType<AdminSetQuizPlanSchedulerTimeRequest> }
  > = props => {
    const { data } = props ?? {};

    return setQuizPlanSchedulerTime(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type SetQuizPlanSchedulerTimeMutationResult = NonNullable<
  Awaited<ReturnType<typeof setQuizPlanSchedulerTime>>
>;
export type SetQuizPlanSchedulerTimeMutationBody =
  BodyType<AdminSetQuizPlanSchedulerTimeRequest>;
export type SetQuizPlanSchedulerTimeMutationError = ErrorType<unknown>;

export const useSetQuizPlanSchedulerTime = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof setQuizPlanSchedulerTime>>,
    TError,
    { data: BodyType<AdminSetQuizPlanSchedulerTimeRequest> },
    TContext
  >;
  request?: SecondParameter<typeof httpsInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof setQuizPlanSchedulerTime>>,
  TError,
  { data: BodyType<AdminSetQuizPlanSchedulerTimeRequest> },
  TContext
> => {
  const mutationOptions = getSetQuizPlanSchedulerTimeMutationOptions(options);

  return useMutation(mutationOptions);
};
export const getQuizPlanDetails = (
  params: GetQuizPlanDetailsParams,
  options?: SecondParameter<typeof httpsInstance>,
  signal?: AbortSignal,
) => {
  return httpsInstance<BaseResponseAdminGetQuizPlanDetailsResponse>(
    { url: `/admin/quiz-plans/details`, method: 'GET', params, signal },
    options,
  );
};

export const getGetQuizPlanDetailsQueryKey = (
  params: GetQuizPlanDetailsParams,
) => {
  return [`/admin/quiz-plans/details`, ...(params ? [params] : [])] as const;
};

export const getGetQuizPlanDetailsQueryOptions = <
  TData = Awaited<ReturnType<typeof getQuizPlanDetails>>,
  TError = ErrorType<unknown>,
>(
  params: GetQuizPlanDetailsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getQuizPlanDetails>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof httpsInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetQuizPlanDetailsQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getQuizPlanDetails>>
  > = ({ signal }) => getQuizPlanDetails(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getQuizPlanDetails>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetQuizPlanDetailsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getQuizPlanDetails>>
>;
export type GetQuizPlanDetailsQueryError = ErrorType<unknown>;

export function useGetQuizPlanDetails<
  TData = Awaited<ReturnType<typeof getQuizPlanDetails>>,
  TError = ErrorType<unknown>,
>(
  params: GetQuizPlanDetailsParams,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getQuizPlanDetails>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getQuizPlanDetails>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof httpsInstance>;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetQuizPlanDetails<
  TData = Awaited<ReturnType<typeof getQuizPlanDetails>>,
  TError = ErrorType<unknown>,
>(
  params: GetQuizPlanDetailsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getQuizPlanDetails>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getQuizPlanDetails>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof httpsInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetQuizPlanDetails<
  TData = Awaited<ReturnType<typeof getQuizPlanDetails>>,
  TError = ErrorType<unknown>,
>(
  params: GetQuizPlanDetailsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getQuizPlanDetails>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof httpsInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useGetQuizPlanDetails<
  TData = Awaited<ReturnType<typeof getQuizPlanDetails>>,
  TError = ErrorType<unknown>,
>(
  params: GetQuizPlanDetailsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getQuizPlanDetails>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof httpsInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetQuizPlanDetailsQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getGetQuizPlanDetailsSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof getQuizPlanDetails>>,
  TError = ErrorType<unknown>,
>(
  params: GetQuizPlanDetailsParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof getQuizPlanDetails>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof httpsInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetQuizPlanDetailsQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getQuizPlanDetails>>
  > = ({ signal }) => getQuizPlanDetails(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof getQuizPlanDetails>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetQuizPlanDetailsSuspenseQueryResult = NonNullable<
  Awaited<ReturnType<typeof getQuizPlanDetails>>
>;
export type GetQuizPlanDetailsSuspenseQueryError = ErrorType<unknown>;

export function useGetQuizPlanDetailsSuspense<
  TData = Awaited<ReturnType<typeof getQuizPlanDetails>>,
  TError = ErrorType<unknown>,
>(
  params: GetQuizPlanDetailsParams,
  options: {
    query: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof getQuizPlanDetails>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof httpsInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetQuizPlanDetailsSuspense<
  TData = Awaited<ReturnType<typeof getQuizPlanDetails>>,
  TError = ErrorType<unknown>,
>(
  params: GetQuizPlanDetailsParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof getQuizPlanDetails>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof httpsInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetQuizPlanDetailsSuspense<
  TData = Awaited<ReturnType<typeof getQuizPlanDetails>>,
  TError = ErrorType<unknown>,
>(
  params: GetQuizPlanDetailsParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof getQuizPlanDetails>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof httpsInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useGetQuizPlanDetailsSuspense<
  TData = Awaited<ReturnType<typeof getQuizPlanDetails>>,
  TError = ErrorType<unknown>,
>(
  params: GetQuizPlanDetailsParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof getQuizPlanDetails>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof httpsInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetQuizPlanDetailsSuspenseQueryOptions(
    params,
    options,
  );

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}
