import type { SVGProps } from 'react';
import * as React from 'react';
export const ArrowTurnLeftup = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    viewBox="0 0 24 24"
    {...props}>
    <path
      fill={props.fill || `currentColor`}
      d="M8.883 5.076a1 1 0 0 0-1.09.217l-3 3a1 1 0 0 0 1.414 1.414L7.5 8.414V13.5a5 5 0 0 0 5 5H18a1 1 0 1 0 0-2h-5.5a3 3 0 0 1-3-3V8.414l1.293 1.293a1 1 0 0 0 1.414-1.414l-3-3M8.883 5.076q.178.072.322.215z"
    />
  </svg>
);
