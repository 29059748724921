import type { SVGProps } from 'react';
import * as React from 'react';
export const ArrowsUpdownleftright = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    viewBox="0 0 24 24"
    {...props}>
    <path
      fill={props.fill || `currentColor`}
      d="M15.392 6.307a.915.915 0 0 0 0-1.294l-2.745-2.745a.915.915 0 0 0-1.294 0L8.608 5.013a.915.915 0 1 0 1.294 1.294l1.183-1.183v5.961h-5.96l1.182-1.183a.915.915 0 0 0-1.294-1.294l-2.745 2.745a.915.915 0 0 0 0 1.294l2.745 2.745a.915.915 0 1 0 1.294-1.294l-1.183-1.183h5.961v5.961l-1.183-1.183a.915.915 0 0 0-1.294 1.294l2.745 2.745a.915.915 0 0 0 1.294 0l2.745-2.745a.915.915 0 1 0-1.294-1.294l-1.183 1.183v-5.961h5.96l-1.182 1.183a.915.915 0 0 0 1.294 1.294l2.745-2.745a.915.915 0 0 0 0-1.294l-2.745-2.745a.915.915 0 0 0-1.294 1.294l1.183 1.183h-5.961V5.124l1.183 1.183a.915.915 0 0 0 1.294 0"
    />
  </svg>
);
