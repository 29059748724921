import type { SVGProps } from 'react';
import * as React from 'react';
export const ArrowTurnLeftdown = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    viewBox="0 0 24 24"
    {...props}>
    <path
      fill={props.fill || `currentColor`}
      d="M8.883 18.424a1 1 0 0 1-1.09-.217l-3-3a1 1 0 1 1 1.414-1.414L7.5 15.086V10a5 5 0 0 1 5-5H18a1 1 0 1 1 0 2h-5.5a3 3 0 0 0-3 3v5.086l1.293-1.293a1 1 0 0 1 1.414 1.414l-3 3M8.883 18.424a1 1 0 0 0 .322-.215z"
    />
  </svg>
);
