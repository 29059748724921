export const inAppBrowserNameRegex =
  /naver|kakaotalk|line|snapchat|instagram|whatsApp|kakaostory|band|twitter|DaumApps|DaumDevice\/mobile|Works|FB_IAB|FB4A|FBAN|FBIOS|FBSS/i;

export const isInAppBrowser = (regex = inAppBrowserNameRegex) => {
  if (typeof window === 'undefined') {
    return false;
  }

  return window.navigator.userAgent.match(regex) !== null;
};
