import { PropsWithChildren } from 'react';
import { isServer } from '@toss/utils';
import { NextPageWithLayout } from 'next';

import { AuthWrapper } from './AuthWrapper';

export function withPageAuth<P extends object>(
  PageComponent: NextPageWithLayout<P>,
  callbackUrl: string = '',
  redirectPath: string = '/login',
) {
  return Object.assign(
    (props: PropsWithChildren<P>) =>
      isServer() ? null : (
        <AuthWrapper redirectPath={redirectPath} callbackUrl={callbackUrl}>
          <PageComponent {...props} />
        </AuthWrapper>
      ),
    {
      getLayout: PageComponent.getLayout,
    },
  );
}
