export type LogLevelDesc =
  | 'TRACE'
  | 'DEBUG'
  | 'INFO'
  | 'WARN'
  | 'ERROR'
  | 'SILENT';

// Inspired by: https://github.com/pimterry/loglevel
export class LogLevel {
  private level: LogLevelDesc;
  private static readonly levels: Record<LogLevelDesc, number> = {
    TRACE: 0,
    DEBUG: 1,
    INFO: 2,
    WARN: 3,
    ERROR: 4,
    SILENT: 5,
  };

  constructor(level: LogLevelDesc = 'INFO') {
    this.level = level;
  }

  setLevel(level: LogLevelDesc) {
    this.level = level;
  }

  shouldLog(messageLevel: LogLevelDesc): boolean {
    return LogLevel.levels[messageLevel] >= LogLevel.levels[this.level];
  }

  static getLevels(): Record<LogLevelDesc, number> {
    return { ...LogLevel.levels };
  }
}
