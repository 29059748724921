import type { SVGProps } from 'react';
import * as React from 'react';
export const Login = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    viewBox="0 0 24 24"
    {...props}>
    <path
      fill={props.fill || `currentColor`}
      d="M14 19a1 1 0 1 0 0 2h5a2 2 0 0 0 2-2V5a2 2 0 0 0-2-2h-5a1 1 0 1 0 0 2h5v14z"
    />
    <path
      fill={props.fill || `currentColor`}
      d="M15.714 12.7a1 1 0 0 0 .286-.697v-.006a1 1 0 0 0-.293-.704l-4-4a1 1 0 1 0-1.414 1.414L12.586 11H4a1 1 0 1 0 0 2h8.586l-2.293 2.293a1 1 0 1 0 1.414 1.414l4-4z"
    />
  </svg>
);
