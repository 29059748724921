import type { SVGProps } from 'react';
import * as React from 'react';
export const Teleport = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    viewBox="0 0 24 24"
    {...props}>
    <path
      fill={props.fill || `currentColor`}
      d="M9.468 4.21c-1.642-.483-3.873.148-5.687 2.415a1 1 0 1 1-1.562-1.25c2.187-2.732 5.205-3.851 7.813-3.084 2.352.692 4.039 2.812 4.397 5.935l1.882-1.492a1 1 0 1 1 1.242 1.568l-3.262 2.585a1 1 0 0 1-.962.156L8.89 9.432a1 1 0 0 1 .682-1.88l2.887 1.047c-.246-2.594-1.558-3.968-2.991-4.39"
    />
    <path
      fill={props.fill || `currentColor`}
      fillRule="evenodd"
      d="M22 16.5c0 2.486-3.582 4.5-8 4.5s-8-2.014-8-4.5c0-2.485 3.582-4.5 8-4.5s8 2.015 8 4.5m-2 0c0 .2-.165.787-1.324 1.44C17.58 18.555 15.931 19 14 19c-1.93 0-3.58-.444-4.676-1.06C8.164 17.286 8 16.7 8 16.5s.165-.787 1.324-1.438C10.42 14.445 12.069 14 14 14c1.93 0 3.58.445 4.676 1.062 1.16.652 1.324 1.24 1.324 1.438"
      clipRule="evenodd"
    />
  </svg>
);
