import type { SVGProps } from 'react';
import * as React from 'react';
export const MapFill = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    viewBox="0 0 24 24"
    {...props}>
    <path
      fill={props.fill || `currentColor`}
      d="m7 3.613-3.632 1.21A2 2 0 0 0 2 6.721v11.892a2 2 0 0 0 2.632 1.897L7 19.72zM9 19.054V3a1 1 0 0 1 .316.051L15 4.946V21a1 1 0 0 1-.316-.051zM17 20.387l3.633-1.21A2 2 0 0 0 22 17.279V5.387a2 2 0 0 0-2.633-1.897L17 4.28z"
    />
  </svg>
);
