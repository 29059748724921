import type { SVGProps } from 'react';
import * as React from 'react';
export const MusicFill = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    viewBox="0 0 24 24"
    {...props}>
    <path
      fill={props.fill || `currentColor`}
      d="M20 4a1 1 0 0 0-1.287-.958l-10 3A1 1 0 0 0 8 7v8.171a3 3 0 1 0 2 2.832V9.744l8-2.4v5.827A3 3 0 1 0 20 16V3.999"
    />
  </svg>
);
