export const White = '#FFFFFF';
export const Black = '#1C1B1F';
export const Violet50 = '#F3F2FF';
export const Violet100 = '#E7E4FF';
export const Violet200 = '#C8C3FF';
export const Violet300 = '#A49BFF';
export const Violet400 = '#8478FF';
export const Violet500 = '#6758FF';
export const Violet600 = '#5748F2';
export const Violet700 = '#4B3FCD';
export const Violet800 = '#3E33AA';
export const Violet900 = '#342C80';
export const Gray50 = '#F8F9FC';
export const Gray100 = '#F0F2F6';
export const Gray200 = '#E9EAF2';
export const Gray300 = '#CACBD8';
export const Gray400 = '#B8B9C8';
export const Gray500 = '#9E9FAF';
export const Gray600 = '#7A7B89';
export const Gray700 = '#5D5E69';
export const Gray800 = '#3E3E47';
export const Gray900 = '#27262E';
export const Mint50 = '#E5FFF9';
export const Mint100 = '#D0FAF2';
export const Mint200 = '#A8F6E8';
export const Mint300 = '#79F6DF';
export const Mint400 = '#43EFD0';
export const Mint500 = '#15E4BF';
export const Mint600 = '#18D0B4';
export const Mint700 = '#15B09C';
export const Mint800 = '#10988D';
export const Mint900 = '#0F817E';
export const Red50 = '#FFF2F7';
export const Red100 = '#FFE5EB';
export const Red200 = '#FFD4DC';
export const Red300 = '#FFACB6';
export const Red400 = '#FF737B';
export const Red500 = '#FF4848';
export const Red600 = '#D93A43';
export const Red700 = '#B83941';
export const Red800 = '#8E2935';
export const Red900 = '#6C1721';
export const Yellow50 = '#FFFBE5';
export const Yellow100 = '#FFF6C3';
export const Yellow200 = '#FFEF9B';
export const Yellow300 = '#FFEA81';
export const Yellow400 = '#FFE350';
export const Yellow500 = '#FFD600';
export const Yellow600 = '#F4C620';
export const Yellow700 = '#F1B10B';
export const Yellow800 = '#E4A01D';
export const Yellow900 = '#D4922E';
export const Green50 = '#E8FCEA';
export const Green100 = '#D0FAD4';
export const Green200 = '#ADF7B5';
export const Green300 = '#8DF398';
export const Green400 = '#61E96F';
export const Green500 = '#3CD84C';
export const Green600 = '#14CD27';
export const Green700 = '#16B026';
export const Green800 = '#0D8518';
export const Green900 = '#0B6D14';
export const Blue50 = '#E7F4FF';
export const Blue100 = '#CFEAFF';
export const Blue200 = '#9ED4FF';
export const Blue300 = '#6EBFFF';
export const Blue400 = '#3DA9FF';
export const Blue500 = '#0D94FF';
export const Blue600 = '#0A81DF';
export const Blue700 = '#086EBF';
export const Blue800 = '#055B9F';
export const Blue900 = '#054E89';
export const Purple50 = '#F5EDFF';
export const Purple100 = '#EBDBFF';
export const Purple200 = '#DABCFF';
export const Purple300 = '#C597FF';
export const Purple400 = '#AF70FF';
export const Purple500 = '#9A4AFF';
export const Purple600 = '#8236E1';
export const Purple700 = '#722CC9';
export const Purple800 = '#6221B5';
export const Purple900 = '#501699';
export const Pink50 = '#FDE8FD';
export const Pink100 = '#FFD0FF';
export const Pink200 = '#FFB6FF';
export const Pink300 = '#FC8AFF';
export const Pink400 = '#F268F5';
export const Pink500 = '#E644EA';
export const Pink600 = '#D000D0';
export const Pink700 = '#C000C0';
export const Pink800 = '#B000B0';
export const Pink900 = '#A000A0';

const OPACITY_LEVELS = [
  0.04, 0.08, 0.12, 0.24, 0.4, 0.54, 0.64, 0.72, 0.8, 0.9,
];
const HEX_OPACITY_LEVELS = OPACITY_LEVELS.map(opacity =>
  Math.round(opacity * 255)
    .toString(16)
    .padStart(2, '0'),
);

export const OpacityWhiteColors = HEX_OPACITY_LEVELS.map(
  hex => `${White}${hex}`,
);
export const OpacityBlackColors = HEX_OPACITY_LEVELS.map(
  hex => `${Black}${hex}`,
);
export const OpacityVioletColors = HEX_OPACITY_LEVELS.map(
  hex => `${Violet500}${hex}`,
);

export const ICON_COLOR = {
  default: Gray700,
  neutral: Gray600,
  alternative: Gray500,
  assistive: Gray400,
  disabled: Gray300,
  inverse: White,
  strong: Violet500,
  destructive: Red500,
  cautionary: Yellow600,
  positive: Green600,
  opacity: OpacityBlackColors[0],
} as const;
