/**
 * Generated by orval v7.1.1 🍺
 * Do not edit manually.
 * ZEP QUIZ
 * Zep Quiz API
 * OpenAPI spec version: 0.0.1
 */
import type {
  DefinedInitialDataOptions,
  DefinedUseQueryResult,
  QueryFunction,
  QueryKey,
  UndefinedInitialDataOptions,
  UseQueryOptions,
  UseQueryResult,
  UseSuspenseQueryOptions,
  UseSuspenseQueryResult,
} from '@tanstack/react-query';
import { useQuery, useSuspenseQuery } from '@tanstack/react-query';

import type { ErrorType } from '../../httpClient';
import { httpsInstance } from '../../httpClient';
import type { BaseResponseListMapTemplateRes } from '.././_model';

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

export const getMapTemplates = (
  options?: SecondParameter<typeof httpsInstance>,
  signal?: AbortSignal,
) => {
  return httpsInstance<BaseResponseListMapTemplateRes>(
    { url: `/template/all`, method: 'GET', signal },
    options,
  );
};

export const getGetMapTemplatesQueryKey = () => {
  return [`/template/all`] as const;
};

export const getGetMapTemplatesQueryOptions = <
  TData = Awaited<ReturnType<typeof getMapTemplates>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof getMapTemplates>>, TError, TData>
  >;
  request?: SecondParameter<typeof httpsInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetMapTemplatesQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getMapTemplates>>> = ({
    signal,
  }) => getMapTemplates(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getMapTemplates>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetMapTemplatesQueryResult = NonNullable<
  Awaited<ReturnType<typeof getMapTemplates>>
>;
export type GetMapTemplatesQueryError = ErrorType<unknown>;

export function useGetMapTemplates<
  TData = Awaited<ReturnType<typeof getMapTemplates>>,
  TError = ErrorType<unknown>,
>(options: {
  query: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof getMapTemplates>>, TError, TData>
  > &
    Pick<
      DefinedInitialDataOptions<
        Awaited<ReturnType<typeof getMapTemplates>>,
        TError,
        TData
      >,
      'initialData'
    >;
  request?: SecondParameter<typeof httpsInstance>;
}): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetMapTemplates<
  TData = Awaited<ReturnType<typeof getMapTemplates>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof getMapTemplates>>, TError, TData>
  > &
    Pick<
      UndefinedInitialDataOptions<
        Awaited<ReturnType<typeof getMapTemplates>>,
        TError,
        TData
      >,
      'initialData'
    >;
  request?: SecondParameter<typeof httpsInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetMapTemplates<
  TData = Awaited<ReturnType<typeof getMapTemplates>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof getMapTemplates>>, TError, TData>
  >;
  request?: SecondParameter<typeof httpsInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useGetMapTemplates<
  TData = Awaited<ReturnType<typeof getMapTemplates>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof getMapTemplates>>, TError, TData>
  >;
  request?: SecondParameter<typeof httpsInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetMapTemplatesQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getGetMapTemplatesSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof getMapTemplates>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseSuspenseQueryOptions<
      Awaited<ReturnType<typeof getMapTemplates>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof httpsInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetMapTemplatesQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getMapTemplates>>> = ({
    signal,
  }) => getMapTemplates(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof getMapTemplates>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetMapTemplatesSuspenseQueryResult = NonNullable<
  Awaited<ReturnType<typeof getMapTemplates>>
>;
export type GetMapTemplatesSuspenseQueryError = ErrorType<unknown>;

export function useGetMapTemplatesSuspense<
  TData = Awaited<ReturnType<typeof getMapTemplates>>,
  TError = ErrorType<unknown>,
>(options: {
  query: Partial<
    UseSuspenseQueryOptions<
      Awaited<ReturnType<typeof getMapTemplates>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof httpsInstance>;
}): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetMapTemplatesSuspense<
  TData = Awaited<ReturnType<typeof getMapTemplates>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseSuspenseQueryOptions<
      Awaited<ReturnType<typeof getMapTemplates>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof httpsInstance>;
}): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetMapTemplatesSuspense<
  TData = Awaited<ReturnType<typeof getMapTemplates>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseSuspenseQueryOptions<
      Awaited<ReturnType<typeof getMapTemplates>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof httpsInstance>;
}): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useGetMapTemplatesSuspense<
  TData = Awaited<ReturnType<typeof getMapTemplates>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseSuspenseQueryOptions<
      Awaited<ReturnType<typeof getMapTemplates>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof httpsInstance>;
}): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetMapTemplatesSuspenseQueryOptions(options);

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}
