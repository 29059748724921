import type { SVGProps } from 'react';
import * as React from 'react';
export const Map = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    viewBox="0 0 24 24"
    {...props}>
    <path
      fill={props.fill || `currentColor`}
      d="M8.684 3.051a1 1 0 0 1 .632 0L15 4.946l4.368-1.456A2 2 0 0 1 22 5.387V17.28a2 2 0 0 1-1.367 1.898l-5.317 1.772a1 1 0 0 1-.632 0L9 19.054 4.632 20.51A2 2 0 0 1 2 18.613V6.72a2 2 0 0 1 1.368-1.898zM10 17.28l4 1.334V6.72l-4-1.334zM8 5.387 4 6.721v11.892l4-1.334zm8 1.334v11.892l4-1.334V5.387z"
    />
  </svg>
);
