import type { SVGProps } from 'react';
import * as React from 'react';
export const SpeakerLv2Fill = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    viewBox="0 0 24 24"
    {...props}>
    <path
      fill={props.fill || `currentColor`}
      fillRule="evenodd"
      d="M13 4a1 1 0 0 0-1.707-.707L7.586 7H4a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h3.586l3.707 3.707A1 1 0 0 0 13 20z"
      clipRule="evenodd"
    />
    <path
      fill={props.fill || `currentColor`}
      d="M19.207 6.293a1 1 0 1 0-1.414 1.414C18.64 8.555 20 9.956 20 12s-1.359 3.445-2.207 4.293a1 1 0 0 0 1.414 1.414l.034-.033C20.078 16.837 22 14.916 22 12s-1.922-4.837-2.76-5.674z"
    />
    <path
      fill={props.fill || `currentColor`}
      d="M16.707 8.793a1 1 0 1 0-1.414 1.414c.431.432.957.999.957 1.793s-.526 1.361-.957 1.793a1 1 0 0 0 1.414 1.414l.028-.028c.424-.423 1.515-1.511 1.515-3.179s-1.09-2.756-1.515-3.18z"
    />
  </svg>
);
