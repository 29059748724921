/**
 * Generated by orval v7.1.1 🍺
 * Do not edit manually.
 * ZEP QUIZ
 * Zep Quiz API
 * OpenAPI spec version: 0.0.1
 */

export type BaseResponseAdminGetQuizPlanDetailsResponseStatus =
  (typeof BaseResponseAdminGetQuizPlanDetailsResponseStatus)[keyof typeof BaseResponseAdminGetQuizPlanDetailsResponseStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const BaseResponseAdminGetQuizPlanDetailsResponseStatus = {
  PENDING: 'PENDING',
  SUCCESS: 'SUCCESS',
  FAILED: 'FAILED',
} as const;
