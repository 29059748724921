import type { SVGProps } from 'react';
import * as React from 'react';
export const SquareCircleStackFill = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    viewBox="0 0 24 24"
    {...props}>
    <path
      fill={props.fill || `currentColor`}
      d="M22 15a7 7 0 1 1-14 0 7 7 0 0 1 14 0"
    />
    <path
      fill={props.fill || `currentColor`}
      d="M4 2a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h2.81A8.25 8.25 0 0 1 16 6.81V4a2 2 0 0 0-2-2z"
    />
  </svg>
);
