export const getYoutubeId = (url: string) => {
  const args =
    /(youtube\.com|youtu\.be)\/(watch)?(\?v=)?([A-Za-z0-9\-_]+)?/.exec(url);
  return args?.[4];
};

export const createYoutubePlayer = ({
  elementId,
  youtubeId,
  start,
  url,
  triggerByTouch,
}: {
  elementId: string;
  youtubeId: string;
  url: string;
  start?: number;
  triggerByTouch?: boolean;
}): YT.Player | undefined => {
  if (url) {
    const time = getParameterByName('t', url);
    if (!start && time) {
      start = parseInt(time);
    }
  }

  const autoplay =
    (!triggerByTouch && url.includes('autoplay=0')) ||
    url.includes('autoplay=false') ||
    triggerByTouch
      ? 0
      : 1;

  const loop = url.includes('loop=1') || url.includes('loop=true') ? 1 : 0;

  const mute = url.includes('muted=1') || url.includes('muted=true') ? 1 : 0;

  try {
    return new YT.Player(elementId, {
      videoId: youtubeId,
      playerVars: {
        start,
        autoplay,
        loop,
        mute,
        playsinline: 1,
      },
    });
  } catch (e) {
    console.error('YT Error', e);
    return undefined;
  }
};

const getParameterByName = (name: string, url = window.location.href) => {
  name = name.replace(/[[\]]/g, '\\$&');
  const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
};
