import { useEffect } from 'react';
import { useUser } from '@zep/lib/auth';

export const useCheckAuthenticated = (onUnauthenticatedAction: () => void) => {
  const { isAuthenticated, isInitialization } = useUser();

  useEffect(() => {
    if (isInitialization && !isAuthenticated) {
      onUnauthenticatedAction();
    }
  }, [isAuthenticated, isInitialization, onUnauthenticatedAction]);
};
