import type { SVGProps } from 'react';
import * as React from 'react';
export const ArrowTurnRightup = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    viewBox="0 0 24 24"
    {...props}>
    <path
      fill={props.fill || `currentColor`}
      d="M14.617 5.076a1 1 0 0 1 1.09.217l3 3a1 1 0 0 1-1.414 1.414L16 8.414V13.5a5 5 0 0 1-5 5H5.5a1 1 0 1 1 0-2H11a3 3 0 0 0 3-3V8.414l-1.293 1.293a1 1 0 0 1-1.414-1.414l3-3M14.617 5.076a1 1 0 0 0-.322.215z"
    />
  </svg>
);
