import type { SVGProps } from 'react';
import * as React from 'react';
export const CloudSlashFill = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    viewBox="0 0 24 24"
    {...props}>
    <path
      fill={props.fill || `currentColor`}
      d="M5.247 8.291a6 6 0 0 0-.233 2.12A5 5 0 0 0 7 20h9q.454 0 .89-.066zM21.124 17.124 8.53 4.53a6.002 6.002 0 0 1 8.141 3.507 6 6 0 0 1 4.453 9.087M4.85 5.85a1 1 0 0 1 1.414-1.415L20.78 18.953a1 1 0 0 1-1.414 1.414z"
    />
  </svg>
);
