import type { SVGProps } from 'react';
import * as React from 'react';
export const MinusSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    viewBox="0 0 24 24"
    {...props}>
    <rect
      width={10}
      height={2}
      x={7}
      y={11}
      fill={props.fill || `currentColor`}
      rx={1}
    />
  </svg>
);
