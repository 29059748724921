import type { SVGProps } from 'react';
import * as React from 'react';
export const ArrowsUpdown = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    viewBox="0 0 24 24"
    {...props}>
    <path
      fill={props.fill || `currentColor`}
      d="M18.744 16.119a.875.875 0 1 0-1.238-1.238l-1.131 1.132V6.75a.875.875 0 0 0-1.75 0v9.263l-1.131-1.132a.875.875 0 1 0-1.238 1.238l2.625 2.625a.875.875 0 0 0 1.238 0zM11.744 7.881a.875.875 0 0 1-1.238 1.238L9.375 7.987v9.263a.875.875 0 0 1-1.75 0V7.987L6.494 9.12A.875.875 0 1 1 5.256 7.88l2.625-2.625a.875.875 0 0 1 1.238 0z"
    />
  </svg>
);
