import { useUser } from '@zep/lib/auth';
/**
 *  status === 'authenticated' 인 상태 컴포넌트에서 사용하세요
 * */
export const useUserInfo = () => {
  const { isInitialization, user } = useUser();

  if (!isInitialization) return null;

  return user;
};
