import { useState } from 'react';
import NiceModal from '@ebay/nice-modal-react';
import { NameEditModal } from '@zep/components/modals';
import { ProPlanTooltip } from '@zep/components/ProPlanTooltip';
import { trackGnbAnalytics } from '@zep/layout';
import { useUser } from '@zep/lib/auth';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@zep/ui';
import { cn } from '@zep/utils';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';

export const MyMenuDropdown = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { user, logOut } = useUser();
  const { t } = useTranslation();
  const router = useRouter();

  const menus: {
    text: string;
    textClassName?: string;
    icon: string;
    onClick: () => void;
  }[] = [
    {
      text: t('header.quizList.button'),
      icon: '/assets/icon_paper.svg',
      onClick: () => {
        trackGnbAnalytics('mylist', 'Header');
        router.push('/me/quiz');
        setIsOpen(false);
      },
    },
    {
      text: user?.username || '',
      icon: '/assets/icon_pen_gray_line.svg',
      onClick: () => {
        NiceModal.show(NameEditModal, {
          initialName: user?.username,
        });
      },
    },
    {
      textClassName: 'text-[#ff5353]',
      text: t('header.logout'),
      icon: '/assets/icon_out_red.svg',
      onClick: () => {
        logOut();
        trackGnbAnalytics('logout', 'Header');
      },
    },
  ];

  return (
    <DropdownMenu open={isOpen} onOpenChange={setIsOpen} modal={false}>
      <DropdownMenuTrigger
        className="items-centers hidden h-[40px] max-w-[140px] overflow-hidden whitespace-nowrap rounded-[8px] py-[10px] pl-xs pr-2xs text-[14px] focus:outline-none md:flex"
        tabIndex={-1}>
        <div className={'flex items-center justify-center gap-4xs'}>
          <ProPlanTooltip />
          <span className={'max-w-[78px] truncate'}>{user?.username}</span>
        </div>
        <img
          className="w-[20px]"
          src="/assets/icon_caret_down_gray.svg"
          alt="more"
        />
      </DropdownMenuTrigger>
      <DropdownMenuContent
        align="end"
        className="shadow-[0_4px_8px_2px_rgba(28, 27, 31, 0.12)] max-h-[200px] min-w-[148px] rounded-[8px] px-[8px] py-3xs text-[13px]">
        <DropdownMenuGroup>
          {menus.map(({ text, icon, onClick, textClassName }, idx) => (
            <DropdownMenuItem
              key={idx}
              onClick={onClick}
              className={'cursor-pointer p-xs text-body-sm font-medium'}>
              <img src={icon} alt="icon" />
              <span className={cn('ml-2 w-full truncate', textClassName)}>
                {text}
              </span>
            </DropdownMenuItem>
          ))}
        </DropdownMenuGroup>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
