/**
 * Generated by orval v7.1.1 🍺
 * Do not edit manually.
 * ZEP QUIZ
 * Zep Quiz API
 * OpenAPI spec version: 0.0.1
 */

export type QuizPlanWithWorkspaceDtoStatus =
  (typeof QuizPlanWithWorkspaceDtoStatus)[keyof typeof QuizPlanWithWorkspaceDtoStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const QuizPlanWithWorkspaceDtoStatus = {
  EXPIRED: 'EXPIRED',
  UNKNOWN: 'UNKNOWN',
  ACTIVE: 'ACTIVE',
  READY: 'READY',
} as const;
