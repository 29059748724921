import type { SVGProps } from 'react';
import * as React from 'react';
export const Play = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    viewBox="0 0 24 24"
    {...props}>
    <path
      fill={props.fill || `currentColor`}
      d="M6 6.741c0-1.543 1.674-2.505 3.008-1.727l9.015 5.259c1.323.771 1.323 2.683 0 3.455l-9.015 5.259C7.674 19.765 6 18.803 6 17.259zm11.015 5.26L8 6.74V17.26z"
    />
  </svg>
);
