import type { SVGProps } from 'react';
import * as React from 'react';
export const Gear = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    viewBox="0 0 24 24"
    {...props}>
    <path
      fill={props.fill || `currentColor`}
      fillRule="evenodd"
      d="M12 7.6a4.4 4.4 0 1 0 0 8.8 4.4 4.4 0 0 0 0-8.8M9.6 12a2.4 2.4 0 1 1 4.8 0 2.4 2.4 0 0 1-4.8 0"
      clipRule="evenodd"
    />
    <path
      fill={props.fill || `currentColor`}
      fillRule="evenodd"
      d="M19.97 12.001a1 1 0 0 0 .25.66l1.35 1.44a1 1 0 0 1 .12 1.17l-2 3.46a1 1 0 0 1-1.07.48l-1.88-.38a1 1 0 0 0-1.15.66l-.61 1.83a1 1 0 0 1-.95.68h-4a1 1 0 0 1-1-.68l-.61-1.83a1 1 0 0 0-1.15-.66l-1.88.38a1 1 0 0 1-1.07-.48l-2-3.46a1 1 0 0 1 .1-1.17l1.27-1.44a1 1 0 0 0 0-1.32l-1.27-1.44a1 1 0 0 1-.1-1.17l2-3.46a1 1 0 0 1 1-.48l1.93.38a1 1 0 0 0 1.15-.66l.56-1.83a1 1 0 0 1 1-.68h4a1 1 0 0 1 .95.68l.61 1.83a1 1 0 0 0 1.15.66l1.88-.38a1 1 0 0 1 1.07.48l2 3.46a1 1 0 0 1-.12 1.17l-1.28 1.44a1 1 0 0 0-.25.66m-.44 2.9-.8-.9a3 3 0 0 1 0-3.98l.8-.9-1.28-2.22-1.18.24a3 3 0 0 1-3.45-2l-.38-1.14h-2.56l-.38 1.13a3 3 0 0 1-3.45 2l-1.18-.24-1.28 2.2.8.9a3 3 0 0 1 0 4l-.8.9 1.3 2.21 1.18-.24a3 3 0 0 1 3.45 2l.36 1.14h2.56l.38-1.12a3 3 0 0 1 3.45-2l1.18.24z"
      clipRule="evenodd"
    />
  </svg>
);
