import type { SVGProps } from 'react';
import * as React from 'react';
export const CaretRight = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    viewBox="0 0 24 24"
    {...props}>
    <path fill={props.fill || `currentColor`} d="m10 7 6 5-6 5z" />
  </svg>
);
