/**
 * Generated by orval v7.1.1 🍺
 * Do not edit manually.
 * ZEP QUIZ
 * Zep Quiz API
 * OpenAPI spec version: 0.0.1
 */
import type {
  DefinedInitialDataOptions,
  DefinedUseQueryResult,
  QueryFunction,
  QueryKey,
  UndefinedInitialDataOptions,
  UseQueryOptions,
  UseQueryResult,
  UseSuspenseQueryOptions,
  UseSuspenseQueryResult,
} from '@tanstack/react-query';
import { useQuery, useSuspenseQuery } from '@tanstack/react-query';

import type { ErrorType } from '../../httpClient';
import { httpsInstance } from '../../httpClient';
import type {
  BaseResponseListCategoryDetailsRes,
  GetAllCategoriesParams,
} from '.././_model';

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

export const getAllCategories = (
  params?: GetAllCategoriesParams,
  options?: SecondParameter<typeof httpsInstance>,
  signal?: AbortSignal,
) => {
  return httpsInstance<BaseResponseListCategoryDetailsRes>(
    { url: `/category`, method: 'GET', params, signal },
    options,
  );
};

export const getGetAllCategoriesQueryKey = (
  params?: GetAllCategoriesParams,
) => {
  return [`/category`, ...(params ? [params] : [])] as const;
};

export const getGetAllCategoriesQueryOptions = <
  TData = Awaited<ReturnType<typeof getAllCategories>>,
  TError = ErrorType<unknown>,
>(
  params?: GetAllCategoriesParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getAllCategories>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof httpsInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetAllCategoriesQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getAllCategories>>
  > = ({ signal }) => getAllCategories(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getAllCategories>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetAllCategoriesQueryResult = NonNullable<
  Awaited<ReturnType<typeof getAllCategories>>
>;
export type GetAllCategoriesQueryError = ErrorType<unknown>;

export function useGetAllCategories<
  TData = Awaited<ReturnType<typeof getAllCategories>>,
  TError = ErrorType<unknown>,
>(
  params: undefined | GetAllCategoriesParams,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getAllCategories>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getAllCategories>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof httpsInstance>;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetAllCategories<
  TData = Awaited<ReturnType<typeof getAllCategories>>,
  TError = ErrorType<unknown>,
>(
  params?: GetAllCategoriesParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getAllCategories>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getAllCategories>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof httpsInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetAllCategories<
  TData = Awaited<ReturnType<typeof getAllCategories>>,
  TError = ErrorType<unknown>,
>(
  params?: GetAllCategoriesParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getAllCategories>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof httpsInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useGetAllCategories<
  TData = Awaited<ReturnType<typeof getAllCategories>>,
  TError = ErrorType<unknown>,
>(
  params?: GetAllCategoriesParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getAllCategories>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof httpsInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetAllCategoriesQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getGetAllCategoriesSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof getAllCategories>>,
  TError = ErrorType<unknown>,
>(
  params?: GetAllCategoriesParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof getAllCategories>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof httpsInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetAllCategoriesQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getAllCategories>>
  > = ({ signal }) => getAllCategories(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof getAllCategories>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetAllCategoriesSuspenseQueryResult = NonNullable<
  Awaited<ReturnType<typeof getAllCategories>>
>;
export type GetAllCategoriesSuspenseQueryError = ErrorType<unknown>;

export function useGetAllCategoriesSuspense<
  TData = Awaited<ReturnType<typeof getAllCategories>>,
  TError = ErrorType<unknown>,
>(
  params: undefined | GetAllCategoriesParams,
  options: {
    query: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof getAllCategories>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof httpsInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetAllCategoriesSuspense<
  TData = Awaited<ReturnType<typeof getAllCategories>>,
  TError = ErrorType<unknown>,
>(
  params?: GetAllCategoriesParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof getAllCategories>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof httpsInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetAllCategoriesSuspense<
  TData = Awaited<ReturnType<typeof getAllCategories>>,
  TError = ErrorType<unknown>,
>(
  params?: GetAllCategoriesParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof getAllCategories>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof httpsInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useGetAllCategoriesSuspense<
  TData = Awaited<ReturnType<typeof getAllCategories>>,
  TError = ErrorType<unknown>,
>(
  params?: GetAllCategoriesParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof getAllCategories>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof httpsInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetAllCategoriesSuspenseQueryOptions(params, options);

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}
