import type { SVGProps } from 'react';
import * as React from 'react';
export const InformationmarkSmallCircleFill = (
  props: SVGProps<SVGSVGElement>,
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    viewBox="0 0 24 24"
    {...props}>
    <path
      fill={props.fill || `currentColor`}
      fillRule="evenodd"
      d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10m0-16a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3m-1 12v-8h2v8z"
      clipRule="evenodd"
    />
  </svg>
);
