import {
  ButtonHTMLAttributes,
  ComponentPropsWithoutRef,
  CSSProperties,
  ElementType,
  MouseEvent,
  PointerEvent,
  ReactNode,
} from 'react';

import { cn } from '../../utils';

import S from './IconContainer.module.scss';

/**
 *
 * @description
 * 이 컴포넌트는 실제 아이콘의 크기의 영역을 유지하고 클릭 가능 범위를 확대시켜주는 역할을 합니다.
 * <Box /> 라는 다형성 컴포넌트를 사용 하고 있으며, flexbox, grid, width, height 등 스타일로 표현 가능한 레이아웃 레벨의 모든 속성을 사용할 수 있습니다.
 *
 * @example <IconContainer hitSlop={8}>...</IconContainer>
 */
export const IconContainer = <T extends ElementType = 'button'>(
  props: IconContainerProps<T> & ComponentPropsWithoutRef<T>,
) => {
  const {
    as = 'button',
    children,
    hitSlop = 8,
    visibleHitSlop,
    className,
    onClick,
    onPointerDown,
    ...rest
  } = props;

  const Comp = as;

  return (
    <Comp
      onClick={(event: MouseEvent) => {
        onClick && onClick(event);
        (event.currentTarget as HTMLButtonElement).blur();
      }}
      onPointerDown={(event: PointerEvent) => {
        onPointerDown && onPointerDown(event);
        (event.currentTarget as HTMLButtonElement).blur();
      }}
      className={cn(
        S.icon_container,
        { [S.visible_hit_slop]: visibleHitSlop },
        className,
      )}
      {...rest}>
      {children}
      <div
        className={S.slop}
        style={
          {
            '--slop': hitSlop,
            borderRadius: rest.borderRadius,
          } as CSSProperties
        }
        aria-hidden="true"
      />
    </Comp>
  );
};

type IconContainerProps<T extends ElementType> =
  ButtonHTMLAttributes<HTMLButtonElement> & {
    as?: T;
    /**
     * 아이콘의 영역을 유지하면서 바깥으로 기본값 8px 정도 조작 가능 범위를 넓힙니다.
     * 숫자로 받고 픽셀 단위로 표현합니다.
     */
    hitSlop?: number;
    onClick?: (event: MouseEvent) => void;
    onPointerDown?: (event: PointerEvent) => void;
    className?: string;
    visibleHitSlop?: boolean;
    children: ReactNode;
  };
