import React from 'react';
import { ProChip } from '@zep/components/chip';
import { useUser } from '@zep/lib/auth';
import { Tooltip } from '@zep/module/sign-up/components/Tooltip';

export const ProPlanTooltip = () => {
  const { user } = useUser();
  return (
    <Tooltip
      contentStyle={{
        background: 'linear-gradient(90deg, #8478FF 0%, #3DA9FF 100%)',
      }}
      arrow={{
        fill: '#6191FF',
      }}
      trigger={
        <div>
          <ProChip id={'2'} isShow={user?.planType === 'PRO'} />
        </div>
      }
      content={
        <div className={'flex items-center gap-2xs'}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="17"
            viewBox="0 0 16 17"
            fill="none">
            <path
              d="M9.05545 2.49976C9.05545 2.33131 8.94701 2.18202 8.78682 2.12993C8.62664 2.07784 8.45112 2.1348 8.35205 2.27102L3.01872 9.60435C2.93269 9.72265 2.92031 9.87921 2.98668 10.0096C3.05306 10.1399 3.18696 10.222 3.33323 10.222H6.94434V14.4998C6.94434 14.6682 7.05278 14.8175 7.21297 14.8696C7.37315 14.9217 7.54866 14.8647 7.64774 14.7285L12.9811 7.39516C13.0671 7.27686 13.0795 7.1203 13.0131 6.98995C12.9467 6.85961 12.8128 6.77753 12.6666 6.77753H9.05545V2.49976Z"
              fill="white"
            />
          </svg>
          Pro Plan
        </div>
      }
    />
  );
};
