import type { SVGProps } from 'react';
import * as React from 'react';
export const InformationmarkSmallCircle = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    viewBox="0 0 24 24"
    {...props}>
    <path
      fill={props.fill || `currentColor`}
      d="M10.5 7.5a1.5 1.5 0 1 1 3 0 1.5 1.5 0 0 1-3 0M11 10v8h2v-8z"
    />
    <path
      fill={props.fill || `currentColor`}
      fillRule="evenodd"
      d="M12 2C6.477 2 2 6.477 2 12s4.477 10 10 10 10-4.477 10-10S17.523 2 12 2M4 12a8 8 0 1 1 16 0 8 8 0 0 1-16 0"
      clipRule="evenodd"
    />
  </svg>
);
