import type { SVGProps } from 'react';
import * as React from 'react';
export const ArrowTurnUpleft = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    viewBox="0 0 24 24"
    {...props}>
    <path
      fill={props.fill || `currentColor`}
      d="M5.076 8.883a1 1 0 0 1 .217-1.09l3-3a1 1 0 0 1 1.414 1.414L8.414 7.5H13.5a5 5 0 0 1 5 5V18a1 1 0 1 1-2 0v-5.5a3 3 0 0 0-3-3H8.414l1.293 1.293a1 1 0 0 1-1.414 1.414l-3-3M5.076 8.883q.072.178.215.322z"
    />
  </svg>
);
