/**
 * Generated by orval v7.1.1 🍺
 * Do not edit manually.
 * ZEP QUIZ
 * Zep Quiz API
 * OpenAPI spec version: 0.0.1
 */
import type {
  DefinedInitialDataOptions,
  DefinedUseQueryResult,
  MutationFunction,
  QueryFunction,
  QueryKey,
  UndefinedInitialDataOptions,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
  UseSuspenseQueryOptions,
  UseSuspenseQueryResult,
} from '@tanstack/react-query';
import { useMutation, useQuery, useSuspenseQuery } from '@tanstack/react-query';

import type { BodyType, ErrorType } from '../../httpClient';
import { httpsInstance } from '../../httpClient';
import type {
  BaseResponseByte,
  CreateFreeTrialCouponsRequest,
  DownloadFreeTrialCouponListAsCsvParams,
  SearchFreeTrialCouponsParams,
  SearchResponseListFreeTrialCouponDto,
} from '.././_model';

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

export const searchFreeTrialCoupons = (
  params: SearchFreeTrialCouponsParams,
  options?: SecondParameter<typeof httpsInstance>,
  signal?: AbortSignal,
) => {
  return httpsInstance<SearchResponseListFreeTrialCouponDto>(
    { url: `/admin/free-trial-coupons`, method: 'GET', params, signal },
    options,
  );
};

export const getSearchFreeTrialCouponsQueryKey = (
  params: SearchFreeTrialCouponsParams,
) => {
  return [`/admin/free-trial-coupons`, ...(params ? [params] : [])] as const;
};

export const getSearchFreeTrialCouponsQueryOptions = <
  TData = Awaited<ReturnType<typeof searchFreeTrialCoupons>>,
  TError = ErrorType<unknown>,
>(
  params: SearchFreeTrialCouponsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof searchFreeTrialCoupons>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof httpsInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getSearchFreeTrialCouponsQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof searchFreeTrialCoupons>>
  > = ({ signal }) => searchFreeTrialCoupons(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof searchFreeTrialCoupons>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type SearchFreeTrialCouponsQueryResult = NonNullable<
  Awaited<ReturnType<typeof searchFreeTrialCoupons>>
>;
export type SearchFreeTrialCouponsQueryError = ErrorType<unknown>;

export function useSearchFreeTrialCoupons<
  TData = Awaited<ReturnType<typeof searchFreeTrialCoupons>>,
  TError = ErrorType<unknown>,
>(
  params: SearchFreeTrialCouponsParams,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof searchFreeTrialCoupons>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof searchFreeTrialCoupons>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof httpsInstance>;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useSearchFreeTrialCoupons<
  TData = Awaited<ReturnType<typeof searchFreeTrialCoupons>>,
  TError = ErrorType<unknown>,
>(
  params: SearchFreeTrialCouponsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof searchFreeTrialCoupons>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof searchFreeTrialCoupons>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof httpsInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useSearchFreeTrialCoupons<
  TData = Awaited<ReturnType<typeof searchFreeTrialCoupons>>,
  TError = ErrorType<unknown>,
>(
  params: SearchFreeTrialCouponsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof searchFreeTrialCoupons>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof httpsInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useSearchFreeTrialCoupons<
  TData = Awaited<ReturnType<typeof searchFreeTrialCoupons>>,
  TError = ErrorType<unknown>,
>(
  params: SearchFreeTrialCouponsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof searchFreeTrialCoupons>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof httpsInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getSearchFreeTrialCouponsQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getSearchFreeTrialCouponsSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof searchFreeTrialCoupons>>,
  TError = ErrorType<unknown>,
>(
  params: SearchFreeTrialCouponsParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof searchFreeTrialCoupons>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof httpsInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getSearchFreeTrialCouponsQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof searchFreeTrialCoupons>>
  > = ({ signal }) => searchFreeTrialCoupons(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof searchFreeTrialCoupons>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type SearchFreeTrialCouponsSuspenseQueryResult = NonNullable<
  Awaited<ReturnType<typeof searchFreeTrialCoupons>>
>;
export type SearchFreeTrialCouponsSuspenseQueryError = ErrorType<unknown>;

export function useSearchFreeTrialCouponsSuspense<
  TData = Awaited<ReturnType<typeof searchFreeTrialCoupons>>,
  TError = ErrorType<unknown>,
>(
  params: SearchFreeTrialCouponsParams,
  options: {
    query: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof searchFreeTrialCoupons>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof httpsInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useSearchFreeTrialCouponsSuspense<
  TData = Awaited<ReturnType<typeof searchFreeTrialCoupons>>,
  TError = ErrorType<unknown>,
>(
  params: SearchFreeTrialCouponsParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof searchFreeTrialCoupons>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof httpsInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useSearchFreeTrialCouponsSuspense<
  TData = Awaited<ReturnType<typeof searchFreeTrialCoupons>>,
  TError = ErrorType<unknown>,
>(
  params: SearchFreeTrialCouponsParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof searchFreeTrialCoupons>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof httpsInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useSearchFreeTrialCouponsSuspense<
  TData = Awaited<ReturnType<typeof searchFreeTrialCoupons>>,
  TError = ErrorType<unknown>,
>(
  params: SearchFreeTrialCouponsParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof searchFreeTrialCoupons>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof httpsInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getSearchFreeTrialCouponsSuspenseQueryOptions(
    params,
    options,
  );

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const createFreeTrialCoupons = (
  createFreeTrialCouponsRequest: BodyType<CreateFreeTrialCouponsRequest>,
  options?: SecondParameter<typeof httpsInstance>,
) => {
  return httpsInstance<BaseResponseByte>(
    {
      url: `/admin/free-trial-coupons`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: createFreeTrialCouponsRequest,
    },
    options,
  );
};

export const getCreateFreeTrialCouponsMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createFreeTrialCoupons>>,
    TError,
    { data: BodyType<CreateFreeTrialCouponsRequest> },
    TContext
  >;
  request?: SecondParameter<typeof httpsInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof createFreeTrialCoupons>>,
  TError,
  { data: BodyType<CreateFreeTrialCouponsRequest> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createFreeTrialCoupons>>,
    { data: BodyType<CreateFreeTrialCouponsRequest> }
  > = props => {
    const { data } = props ?? {};

    return createFreeTrialCoupons(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type CreateFreeTrialCouponsMutationResult = NonNullable<
  Awaited<ReturnType<typeof createFreeTrialCoupons>>
>;
export type CreateFreeTrialCouponsMutationBody =
  BodyType<CreateFreeTrialCouponsRequest>;
export type CreateFreeTrialCouponsMutationError = ErrorType<unknown>;

export const useCreateFreeTrialCoupons = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createFreeTrialCoupons>>,
    TError,
    { data: BodyType<CreateFreeTrialCouponsRequest> },
    TContext
  >;
  request?: SecondParameter<typeof httpsInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof createFreeTrialCoupons>>,
  TError,
  { data: BodyType<CreateFreeTrialCouponsRequest> },
  TContext
> => {
  const mutationOptions = getCreateFreeTrialCouponsMutationOptions(options);

  return useMutation(mutationOptions);
};
export const downloadFreeTrialCouponListAsCsv = (
  params: DownloadFreeTrialCouponListAsCsvParams,
  options?: SecondParameter<typeof httpsInstance>,
  signal?: AbortSignal,
) => {
  return httpsInstance<BaseResponseByte>(
    { url: `/admin/free-trial-coupons/csv`, method: 'GET', params, signal },
    options,
  );
};

export const getDownloadFreeTrialCouponListAsCsvQueryKey = (
  params: DownloadFreeTrialCouponListAsCsvParams,
) => {
  return [
    `/admin/free-trial-coupons/csv`,
    ...(params ? [params] : []),
  ] as const;
};

export const getDownloadFreeTrialCouponListAsCsvQueryOptions = <
  TData = Awaited<ReturnType<typeof downloadFreeTrialCouponListAsCsv>>,
  TError = ErrorType<unknown>,
>(
  params: DownloadFreeTrialCouponListAsCsvParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof downloadFreeTrialCouponListAsCsv>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof httpsInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getDownloadFreeTrialCouponListAsCsvQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof downloadFreeTrialCouponListAsCsv>>
  > = ({ signal }) =>
    downloadFreeTrialCouponListAsCsv(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof downloadFreeTrialCouponListAsCsv>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type DownloadFreeTrialCouponListAsCsvQueryResult = NonNullable<
  Awaited<ReturnType<typeof downloadFreeTrialCouponListAsCsv>>
>;
export type DownloadFreeTrialCouponListAsCsvQueryError = ErrorType<unknown>;

export function useDownloadFreeTrialCouponListAsCsv<
  TData = Awaited<ReturnType<typeof downloadFreeTrialCouponListAsCsv>>,
  TError = ErrorType<unknown>,
>(
  params: DownloadFreeTrialCouponListAsCsvParams,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof downloadFreeTrialCouponListAsCsv>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof downloadFreeTrialCouponListAsCsv>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof httpsInstance>;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useDownloadFreeTrialCouponListAsCsv<
  TData = Awaited<ReturnType<typeof downloadFreeTrialCouponListAsCsv>>,
  TError = ErrorType<unknown>,
>(
  params: DownloadFreeTrialCouponListAsCsvParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof downloadFreeTrialCouponListAsCsv>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof downloadFreeTrialCouponListAsCsv>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof httpsInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useDownloadFreeTrialCouponListAsCsv<
  TData = Awaited<ReturnType<typeof downloadFreeTrialCouponListAsCsv>>,
  TError = ErrorType<unknown>,
>(
  params: DownloadFreeTrialCouponListAsCsvParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof downloadFreeTrialCouponListAsCsv>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof httpsInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useDownloadFreeTrialCouponListAsCsv<
  TData = Awaited<ReturnType<typeof downloadFreeTrialCouponListAsCsv>>,
  TError = ErrorType<unknown>,
>(
  params: DownloadFreeTrialCouponListAsCsvParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof downloadFreeTrialCouponListAsCsv>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof httpsInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getDownloadFreeTrialCouponListAsCsvQueryOptions(
    params,
    options,
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getDownloadFreeTrialCouponListAsCsvSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof downloadFreeTrialCouponListAsCsv>>,
  TError = ErrorType<unknown>,
>(
  params: DownloadFreeTrialCouponListAsCsvParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof downloadFreeTrialCouponListAsCsv>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof httpsInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getDownloadFreeTrialCouponListAsCsvQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof downloadFreeTrialCouponListAsCsv>>
  > = ({ signal }) =>
    downloadFreeTrialCouponListAsCsv(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof downloadFreeTrialCouponListAsCsv>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type DownloadFreeTrialCouponListAsCsvSuspenseQueryResult = NonNullable<
  Awaited<ReturnType<typeof downloadFreeTrialCouponListAsCsv>>
>;
export type DownloadFreeTrialCouponListAsCsvSuspenseQueryError =
  ErrorType<unknown>;

export function useDownloadFreeTrialCouponListAsCsvSuspense<
  TData = Awaited<ReturnType<typeof downloadFreeTrialCouponListAsCsv>>,
  TError = ErrorType<unknown>,
>(
  params: DownloadFreeTrialCouponListAsCsvParams,
  options: {
    query: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof downloadFreeTrialCouponListAsCsv>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof httpsInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useDownloadFreeTrialCouponListAsCsvSuspense<
  TData = Awaited<ReturnType<typeof downloadFreeTrialCouponListAsCsv>>,
  TError = ErrorType<unknown>,
>(
  params: DownloadFreeTrialCouponListAsCsvParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof downloadFreeTrialCouponListAsCsv>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof httpsInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useDownloadFreeTrialCouponListAsCsvSuspense<
  TData = Awaited<ReturnType<typeof downloadFreeTrialCouponListAsCsv>>,
  TError = ErrorType<unknown>,
>(
  params: DownloadFreeTrialCouponListAsCsvParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof downloadFreeTrialCouponListAsCsv>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof httpsInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useDownloadFreeTrialCouponListAsCsvSuspense<
  TData = Awaited<ReturnType<typeof downloadFreeTrialCouponListAsCsv>>,
  TError = ErrorType<unknown>,
>(
  params: DownloadFreeTrialCouponListAsCsvParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof downloadFreeTrialCouponListAsCsv>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof httpsInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getDownloadFreeTrialCouponListAsCsvSuspenseQueryOptions(
    params,
    options,
  );

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}
