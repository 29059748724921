/**
 * Generated by orval v7.1.1 🍺
 * Do not edit manually.
 * ZEP QUIZ
 * Zep Quiz API
 * OpenAPI spec version: 0.0.1
 */
import type {
  DefinedInitialDataOptions,
  DefinedUseQueryResult,
  MutationFunction,
  QueryFunction,
  QueryKey,
  UndefinedInitialDataOptions,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
  UseSuspenseQueryOptions,
  UseSuspenseQueryResult,
} from '@tanstack/react-query';
import { useMutation, useQuery, useSuspenseQuery } from '@tanstack/react-query';

import type { BodyType, ErrorType } from '../../httpClient';
import { httpsInstance } from '../../httpClient';
import type {
  BaseResponseMemberDetailsRes,
  BaseResponseSuccessResponse,
  GetAllMembersParams,
  GetAllMembersV2Params,
  GetMemberInfoParams,
  GetMemberInfoV2Params,
  ResetUserTypeParams,
  ResetUserTypeV2Params,
  SearchResponseListSearchMemberRes,
  SetAdminParams,
  SetAdminV2Params,
  SetAmbassadorMemberReq,
  SuccessResponse,
} from '.././_model';

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

export const setAdminV2 = (
  params: SetAdminV2Params,
  options?: SecondParameter<typeof httpsInstance>,
) => {
  return httpsInstance<SuccessResponse>(
    { url: `/v2/admin/members/permissionType`, method: 'PATCH', params },
    options,
  );
};

export const getSetAdminV2MutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof setAdminV2>>,
    TError,
    { params: SetAdminV2Params },
    TContext
  >;
  request?: SecondParameter<typeof httpsInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof setAdminV2>>,
  TError,
  { params: SetAdminV2Params },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof setAdminV2>>,
    { params: SetAdminV2Params }
  > = props => {
    const { params } = props ?? {};

    return setAdminV2(params, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type SetAdminV2MutationResult = NonNullable<
  Awaited<ReturnType<typeof setAdminV2>>
>;

export type SetAdminV2MutationError = ErrorType<unknown>;

export const useSetAdminV2 = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof setAdminV2>>,
    TError,
    { params: SetAdminV2Params },
    TContext
  >;
  request?: SecondParameter<typeof httpsInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof setAdminV2>>,
  TError,
  { params: SetAdminV2Params },
  TContext
> => {
  const mutationOptions = getSetAdminV2MutationOptions(options);

  return useMutation(mutationOptions);
};
export const resetUserTypeV2 = (
  params: ResetUserTypeV2Params,
  options?: SecondParameter<typeof httpsInstance>,
) => {
  return httpsInstance<SuccessResponse>(
    { url: `/v2/admin/members/memberType`, method: 'PATCH', params },
    options,
  );
};

export const getResetUserTypeV2MutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof resetUserTypeV2>>,
    TError,
    { params: ResetUserTypeV2Params },
    TContext
  >;
  request?: SecondParameter<typeof httpsInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof resetUserTypeV2>>,
  TError,
  { params: ResetUserTypeV2Params },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof resetUserTypeV2>>,
    { params: ResetUserTypeV2Params }
  > = props => {
    const { params } = props ?? {};

    return resetUserTypeV2(params, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type ResetUserTypeV2MutationResult = NonNullable<
  Awaited<ReturnType<typeof resetUserTypeV2>>
>;

export type ResetUserTypeV2MutationError = ErrorType<unknown>;

export const useResetUserTypeV2 = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof resetUserTypeV2>>,
    TError,
    { params: ResetUserTypeV2Params },
    TContext
  >;
  request?: SecondParameter<typeof httpsInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof resetUserTypeV2>>,
  TError,
  { params: ResetUserTypeV2Params },
  TContext
> => {
  const mutationOptions = getResetUserTypeV2MutationOptions(options);

  return useMutation(mutationOptions);
};
export const setAmbassadorV2 = (
  setAmbassadorMemberReq: BodyType<SetAmbassadorMemberReq>,
  options?: SecondParameter<typeof httpsInstance>,
) => {
  return httpsInstance<SuccessResponse>(
    {
      url: `/v2/admin/members/ambassador`,
      method: 'PATCH',
      headers: { 'Content-Type': 'application/json' },
      data: setAmbassadorMemberReq,
    },
    options,
  );
};

export const getSetAmbassadorV2MutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof setAmbassadorV2>>,
    TError,
    { data: BodyType<SetAmbassadorMemberReq> },
    TContext
  >;
  request?: SecondParameter<typeof httpsInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof setAmbassadorV2>>,
  TError,
  { data: BodyType<SetAmbassadorMemberReq> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof setAmbassadorV2>>,
    { data: BodyType<SetAmbassadorMemberReq> }
  > = props => {
    const { data } = props ?? {};

    return setAmbassadorV2(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type SetAmbassadorV2MutationResult = NonNullable<
  Awaited<ReturnType<typeof setAmbassadorV2>>
>;
export type SetAmbassadorV2MutationBody = BodyType<SetAmbassadorMemberReq>;
export type SetAmbassadorV2MutationError = ErrorType<unknown>;

export const useSetAmbassadorV2 = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof setAmbassadorV2>>,
    TError,
    { data: BodyType<SetAmbassadorMemberReq> },
    TContext
  >;
  request?: SecondParameter<typeof httpsInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof setAmbassadorV2>>,
  TError,
  { data: BodyType<SetAmbassadorMemberReq> },
  TContext
> => {
  const mutationOptions = getSetAmbassadorV2MutationOptions(options);

  return useMutation(mutationOptions);
};
export const resetUserType = (
  params: ResetUserTypeParams,
  options?: SecondParameter<typeof httpsInstance>,
) => {
  return httpsInstance<SuccessResponse>(
    { url: `/reset/memberType`, method: 'PATCH', params },
    options,
  );
};

export const getResetUserTypeMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof resetUserType>>,
    TError,
    { params: ResetUserTypeParams },
    TContext
  >;
  request?: SecondParameter<typeof httpsInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof resetUserType>>,
  TError,
  { params: ResetUserTypeParams },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof resetUserType>>,
    { params: ResetUserTypeParams }
  > = props => {
    const { params } = props ?? {};

    return resetUserType(params, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type ResetUserTypeMutationResult = NonNullable<
  Awaited<ReturnType<typeof resetUserType>>
>;

export type ResetUserTypeMutationError = ErrorType<unknown>;

export const useResetUserType = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof resetUserType>>,
    TError,
    { params: ResetUserTypeParams },
    TContext
  >;
  request?: SecondParameter<typeof httpsInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof resetUserType>>,
  TError,
  { params: ResetUserTypeParams },
  TContext
> => {
  const mutationOptions = getResetUserTypeMutationOptions(options);

  return useMutation(mutationOptions);
};
export const setAdmin = (
  params: SetAdminParams,
  options?: SecondParameter<typeof httpsInstance>,
) => {
  return httpsInstance<SuccessResponse>(
    { url: `/edit/admin`, method: 'PATCH', params },
    options,
  );
};

export const getSetAdminMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof setAdmin>>,
    TError,
    { params: SetAdminParams },
    TContext
  >;
  request?: SecondParameter<typeof httpsInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof setAdmin>>,
  TError,
  { params: SetAdminParams },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof setAdmin>>,
    { params: SetAdminParams }
  > = props => {
    const { params } = props ?? {};

    return setAdmin(params, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type SetAdminMutationResult = NonNullable<
  Awaited<ReturnType<typeof setAdmin>>
>;

export type SetAdminMutationError = ErrorType<unknown>;

export const useSetAdmin = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof setAdmin>>,
    TError,
    { params: SetAdminParams },
    TContext
  >;
  request?: SecondParameter<typeof httpsInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof setAdmin>>,
  TError,
  { params: SetAdminParams },
  TContext
> => {
  const mutationOptions = getSetAdminMutationOptions(options);

  return useMutation(mutationOptions);
};
export const setAmbassador = (
  setAmbassadorMemberReq: BodyType<SetAmbassadorMemberReq>,
  options?: SecondParameter<typeof httpsInstance>,
) => {
  return httpsInstance<BaseResponseSuccessResponse>(
    {
      url: `/ambassador`,
      method: 'PATCH',
      headers: { 'Content-Type': 'application/json' },
      data: setAmbassadorMemberReq,
    },
    options,
  );
};

export const getSetAmbassadorMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof setAmbassador>>,
    TError,
    { data: BodyType<SetAmbassadorMemberReq> },
    TContext
  >;
  request?: SecondParameter<typeof httpsInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof setAmbassador>>,
  TError,
  { data: BodyType<SetAmbassadorMemberReq> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof setAmbassador>>,
    { data: BodyType<SetAmbassadorMemberReq> }
  > = props => {
    const { data } = props ?? {};

    return setAmbassador(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type SetAmbassadorMutationResult = NonNullable<
  Awaited<ReturnType<typeof setAmbassador>>
>;
export type SetAmbassadorMutationBody = BodyType<SetAmbassadorMemberReq>;
export type SetAmbassadorMutationError = ErrorType<unknown>;

export const useSetAmbassador = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof setAmbassador>>,
    TError,
    { data: BodyType<SetAmbassadorMemberReq> },
    TContext
  >;
  request?: SecondParameter<typeof httpsInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof setAmbassador>>,
  TError,
  { data: BodyType<SetAmbassadorMemberReq> },
  TContext
> => {
  const mutationOptions = getSetAmbassadorMutationOptions(options);

  return useMutation(mutationOptions);
};
export const getMemberInfoV2 = (
  params: GetMemberInfoV2Params,
  options?: SecondParameter<typeof httpsInstance>,
  signal?: AbortSignal,
) => {
  return httpsInstance<BaseResponseMemberDetailsRes>(
    { url: `/v2/admin/members/memberInfo`, method: 'GET', params, signal },
    options,
  );
};

export const getGetMemberInfoV2QueryKey = (params: GetMemberInfoV2Params) => {
  return [`/v2/admin/members/memberInfo`, ...(params ? [params] : [])] as const;
};

export const getGetMemberInfoV2QueryOptions = <
  TData = Awaited<ReturnType<typeof getMemberInfoV2>>,
  TError = ErrorType<unknown>,
>(
  params: GetMemberInfoV2Params,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getMemberInfoV2>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof httpsInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetMemberInfoV2QueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getMemberInfoV2>>> = ({
    signal,
  }) => getMemberInfoV2(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getMemberInfoV2>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetMemberInfoV2QueryResult = NonNullable<
  Awaited<ReturnType<typeof getMemberInfoV2>>
>;
export type GetMemberInfoV2QueryError = ErrorType<unknown>;

export function useGetMemberInfoV2<
  TData = Awaited<ReturnType<typeof getMemberInfoV2>>,
  TError = ErrorType<unknown>,
>(
  params: GetMemberInfoV2Params,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getMemberInfoV2>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getMemberInfoV2>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof httpsInstance>;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetMemberInfoV2<
  TData = Awaited<ReturnType<typeof getMemberInfoV2>>,
  TError = ErrorType<unknown>,
>(
  params: GetMemberInfoV2Params,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getMemberInfoV2>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getMemberInfoV2>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof httpsInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetMemberInfoV2<
  TData = Awaited<ReturnType<typeof getMemberInfoV2>>,
  TError = ErrorType<unknown>,
>(
  params: GetMemberInfoV2Params,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getMemberInfoV2>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof httpsInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useGetMemberInfoV2<
  TData = Awaited<ReturnType<typeof getMemberInfoV2>>,
  TError = ErrorType<unknown>,
>(
  params: GetMemberInfoV2Params,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getMemberInfoV2>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof httpsInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetMemberInfoV2QueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getGetMemberInfoV2SuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof getMemberInfoV2>>,
  TError = ErrorType<unknown>,
>(
  params: GetMemberInfoV2Params,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof getMemberInfoV2>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof httpsInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetMemberInfoV2QueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getMemberInfoV2>>> = ({
    signal,
  }) => getMemberInfoV2(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof getMemberInfoV2>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetMemberInfoV2SuspenseQueryResult = NonNullable<
  Awaited<ReturnType<typeof getMemberInfoV2>>
>;
export type GetMemberInfoV2SuspenseQueryError = ErrorType<unknown>;

export function useGetMemberInfoV2Suspense<
  TData = Awaited<ReturnType<typeof getMemberInfoV2>>,
  TError = ErrorType<unknown>,
>(
  params: GetMemberInfoV2Params,
  options: {
    query: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof getMemberInfoV2>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof httpsInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetMemberInfoV2Suspense<
  TData = Awaited<ReturnType<typeof getMemberInfoV2>>,
  TError = ErrorType<unknown>,
>(
  params: GetMemberInfoV2Params,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof getMemberInfoV2>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof httpsInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetMemberInfoV2Suspense<
  TData = Awaited<ReturnType<typeof getMemberInfoV2>>,
  TError = ErrorType<unknown>,
>(
  params: GetMemberInfoV2Params,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof getMemberInfoV2>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof httpsInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useGetMemberInfoV2Suspense<
  TData = Awaited<ReturnType<typeof getMemberInfoV2>>,
  TError = ErrorType<unknown>,
>(
  params: GetMemberInfoV2Params,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof getMemberInfoV2>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof httpsInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetMemberInfoV2SuspenseQueryOptions(params, options);

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getAllMembersV2 = (
  params?: GetAllMembersV2Params,
  options?: SecondParameter<typeof httpsInstance>,
  signal?: AbortSignal,
) => {
  return httpsInstance<SearchResponseListSearchMemberRes>(
    { url: `/v2/admin/members/all`, method: 'GET', params, signal },
    options,
  );
};

export const getGetAllMembersV2QueryKey = (params?: GetAllMembersV2Params) => {
  return [`/v2/admin/members/all`, ...(params ? [params] : [])] as const;
};

export const getGetAllMembersV2QueryOptions = <
  TData = Awaited<ReturnType<typeof getAllMembersV2>>,
  TError = ErrorType<unknown>,
>(
  params?: GetAllMembersV2Params,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getAllMembersV2>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof httpsInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetAllMembersV2QueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getAllMembersV2>>> = ({
    signal,
  }) => getAllMembersV2(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getAllMembersV2>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetAllMembersV2QueryResult = NonNullable<
  Awaited<ReturnType<typeof getAllMembersV2>>
>;
export type GetAllMembersV2QueryError = ErrorType<unknown>;

export function useGetAllMembersV2<
  TData = Awaited<ReturnType<typeof getAllMembersV2>>,
  TError = ErrorType<unknown>,
>(
  params: undefined | GetAllMembersV2Params,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getAllMembersV2>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getAllMembersV2>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof httpsInstance>;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetAllMembersV2<
  TData = Awaited<ReturnType<typeof getAllMembersV2>>,
  TError = ErrorType<unknown>,
>(
  params?: GetAllMembersV2Params,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getAllMembersV2>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getAllMembersV2>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof httpsInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetAllMembersV2<
  TData = Awaited<ReturnType<typeof getAllMembersV2>>,
  TError = ErrorType<unknown>,
>(
  params?: GetAllMembersV2Params,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getAllMembersV2>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof httpsInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useGetAllMembersV2<
  TData = Awaited<ReturnType<typeof getAllMembersV2>>,
  TError = ErrorType<unknown>,
>(
  params?: GetAllMembersV2Params,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getAllMembersV2>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof httpsInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetAllMembersV2QueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getGetAllMembersV2SuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof getAllMembersV2>>,
  TError = ErrorType<unknown>,
>(
  params?: GetAllMembersV2Params,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof getAllMembersV2>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof httpsInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetAllMembersV2QueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getAllMembersV2>>> = ({
    signal,
  }) => getAllMembersV2(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof getAllMembersV2>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetAllMembersV2SuspenseQueryResult = NonNullable<
  Awaited<ReturnType<typeof getAllMembersV2>>
>;
export type GetAllMembersV2SuspenseQueryError = ErrorType<unknown>;

export function useGetAllMembersV2Suspense<
  TData = Awaited<ReturnType<typeof getAllMembersV2>>,
  TError = ErrorType<unknown>,
>(
  params: undefined | GetAllMembersV2Params,
  options: {
    query: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof getAllMembersV2>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof httpsInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetAllMembersV2Suspense<
  TData = Awaited<ReturnType<typeof getAllMembersV2>>,
  TError = ErrorType<unknown>,
>(
  params?: GetAllMembersV2Params,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof getAllMembersV2>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof httpsInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetAllMembersV2Suspense<
  TData = Awaited<ReturnType<typeof getAllMembersV2>>,
  TError = ErrorType<unknown>,
>(
  params?: GetAllMembersV2Params,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof getAllMembersV2>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof httpsInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useGetAllMembersV2Suspense<
  TData = Awaited<ReturnType<typeof getAllMembersV2>>,
  TError = ErrorType<unknown>,
>(
  params?: GetAllMembersV2Params,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof getAllMembersV2>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof httpsInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetAllMembersV2SuspenseQueryOptions(params, options);

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getAllMembers = (
  params?: GetAllMembersParams,
  options?: SecondParameter<typeof httpsInstance>,
  signal?: AbortSignal,
) => {
  return httpsInstance<SearchResponseListSearchMemberRes>(
    { url: `/members/all`, method: 'GET', params, signal },
    options,
  );
};

export const getGetAllMembersQueryKey = (params?: GetAllMembersParams) => {
  return [`/members/all`, ...(params ? [params] : [])] as const;
};

export const getGetAllMembersQueryOptions = <
  TData = Awaited<ReturnType<typeof getAllMembers>>,
  TError = ErrorType<unknown>,
>(
  params?: GetAllMembersParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getAllMembers>>, TError, TData>
    >;
    request?: SecondParameter<typeof httpsInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetAllMembersQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getAllMembers>>> = ({
    signal,
  }) => getAllMembers(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getAllMembers>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetAllMembersQueryResult = NonNullable<
  Awaited<ReturnType<typeof getAllMembers>>
>;
export type GetAllMembersQueryError = ErrorType<unknown>;

export function useGetAllMembers<
  TData = Awaited<ReturnType<typeof getAllMembers>>,
  TError = ErrorType<unknown>,
>(
  params: undefined | GetAllMembersParams,
  options: {
    query: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getAllMembers>>, TError, TData>
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getAllMembers>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof httpsInstance>;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetAllMembers<
  TData = Awaited<ReturnType<typeof getAllMembers>>,
  TError = ErrorType<unknown>,
>(
  params?: GetAllMembersParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getAllMembers>>, TError, TData>
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getAllMembers>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof httpsInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetAllMembers<
  TData = Awaited<ReturnType<typeof getAllMembers>>,
  TError = ErrorType<unknown>,
>(
  params?: GetAllMembersParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getAllMembers>>, TError, TData>
    >;
    request?: SecondParameter<typeof httpsInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useGetAllMembers<
  TData = Awaited<ReturnType<typeof getAllMembers>>,
  TError = ErrorType<unknown>,
>(
  params?: GetAllMembersParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getAllMembers>>, TError, TData>
    >;
    request?: SecondParameter<typeof httpsInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetAllMembersQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getGetAllMembersSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof getAllMembers>>,
  TError = ErrorType<unknown>,
>(
  params?: GetAllMembersParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof getAllMembers>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof httpsInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetAllMembersQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getAllMembers>>> = ({
    signal,
  }) => getAllMembers(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof getAllMembers>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetAllMembersSuspenseQueryResult = NonNullable<
  Awaited<ReturnType<typeof getAllMembers>>
>;
export type GetAllMembersSuspenseQueryError = ErrorType<unknown>;

export function useGetAllMembersSuspense<
  TData = Awaited<ReturnType<typeof getAllMembers>>,
  TError = ErrorType<unknown>,
>(
  params: undefined | GetAllMembersParams,
  options: {
    query: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof getAllMembers>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof httpsInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetAllMembersSuspense<
  TData = Awaited<ReturnType<typeof getAllMembers>>,
  TError = ErrorType<unknown>,
>(
  params?: GetAllMembersParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof getAllMembers>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof httpsInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetAllMembersSuspense<
  TData = Awaited<ReturnType<typeof getAllMembers>>,
  TError = ErrorType<unknown>,
>(
  params?: GetAllMembersParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof getAllMembers>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof httpsInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useGetAllMembersSuspense<
  TData = Awaited<ReturnType<typeof getAllMembers>>,
  TError = ErrorType<unknown>,
>(
  params?: GetAllMembersParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof getAllMembers>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof httpsInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetAllMembersSuspenseQueryOptions(params, options);

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getMemberInfo = (
  params: GetMemberInfoParams,
  options?: SecondParameter<typeof httpsInstance>,
  signal?: AbortSignal,
) => {
  return httpsInstance<BaseResponseMemberDetailsRes>(
    { url: `/memberInfo`, method: 'GET', params, signal },
    options,
  );
};

export const getGetMemberInfoQueryKey = (params: GetMemberInfoParams) => {
  return [`/memberInfo`, ...(params ? [params] : [])] as const;
};

export const getGetMemberInfoQueryOptions = <
  TData = Awaited<ReturnType<typeof getMemberInfo>>,
  TError = ErrorType<unknown>,
>(
  params: GetMemberInfoParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getMemberInfo>>, TError, TData>
    >;
    request?: SecondParameter<typeof httpsInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetMemberInfoQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getMemberInfo>>> = ({
    signal,
  }) => getMemberInfo(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getMemberInfo>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetMemberInfoQueryResult = NonNullable<
  Awaited<ReturnType<typeof getMemberInfo>>
>;
export type GetMemberInfoQueryError = ErrorType<unknown>;

export function useGetMemberInfo<
  TData = Awaited<ReturnType<typeof getMemberInfo>>,
  TError = ErrorType<unknown>,
>(
  params: GetMemberInfoParams,
  options: {
    query: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getMemberInfo>>, TError, TData>
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getMemberInfo>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof httpsInstance>;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetMemberInfo<
  TData = Awaited<ReturnType<typeof getMemberInfo>>,
  TError = ErrorType<unknown>,
>(
  params: GetMemberInfoParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getMemberInfo>>, TError, TData>
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getMemberInfo>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof httpsInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetMemberInfo<
  TData = Awaited<ReturnType<typeof getMemberInfo>>,
  TError = ErrorType<unknown>,
>(
  params: GetMemberInfoParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getMemberInfo>>, TError, TData>
    >;
    request?: SecondParameter<typeof httpsInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useGetMemberInfo<
  TData = Awaited<ReturnType<typeof getMemberInfo>>,
  TError = ErrorType<unknown>,
>(
  params: GetMemberInfoParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getMemberInfo>>, TError, TData>
    >;
    request?: SecondParameter<typeof httpsInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetMemberInfoQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getGetMemberInfoSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof getMemberInfo>>,
  TError = ErrorType<unknown>,
>(
  params: GetMemberInfoParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof getMemberInfo>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof httpsInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetMemberInfoQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getMemberInfo>>> = ({
    signal,
  }) => getMemberInfo(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof getMemberInfo>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetMemberInfoSuspenseQueryResult = NonNullable<
  Awaited<ReturnType<typeof getMemberInfo>>
>;
export type GetMemberInfoSuspenseQueryError = ErrorType<unknown>;

export function useGetMemberInfoSuspense<
  TData = Awaited<ReturnType<typeof getMemberInfo>>,
  TError = ErrorType<unknown>,
>(
  params: GetMemberInfoParams,
  options: {
    query: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof getMemberInfo>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof httpsInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetMemberInfoSuspense<
  TData = Awaited<ReturnType<typeof getMemberInfo>>,
  TError = ErrorType<unknown>,
>(
  params: GetMemberInfoParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof getMemberInfo>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof httpsInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetMemberInfoSuspense<
  TData = Awaited<ReturnType<typeof getMemberInfo>>,
  TError = ErrorType<unknown>,
>(
  params: GetMemberInfoParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof getMemberInfo>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof httpsInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useGetMemberInfoSuspense<
  TData = Awaited<ReturnType<typeof getMemberInfo>>,
  TError = ErrorType<unknown>,
>(
  params: GetMemberInfoParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof getMemberInfo>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof httpsInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetMemberInfoSuspenseQueryOptions(params, options);

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}
