/**
 * Generated by orval v7.1.1 🍺
 * Do not edit manually.
 * ZEP QUIZ
 * Zep Quiz API
 * OpenAPI spec version: 0.0.1
 */

export type AdminCreateQuizPlanAndWorkspaceRequestCurrency =
  (typeof AdminCreateQuizPlanAndWorkspaceRequestCurrency)[keyof typeof AdminCreateQuizPlanAndWorkspaceRequestCurrency];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AdminCreateQuizPlanAndWorkspaceRequestCurrency = {
  UNKNOWN: 'UNKNOWN',
  KRW: 'KRW',
  USD: 'USD',
  JPY: 'JPY',
} as const;
