import type { SVGProps } from 'react';
import * as React from 'react';
export const SpeakerLv0Fill = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    viewBox="0 0 24 24"
    {...props}>
    <path
      fill={props.fill || `currentColor`}
      fillRule="evenodd"
      d="M13 4a1 1 0 0 0-1.707-.707L7.586 7H4a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h3.586l3.707 3.707A1 1 0 0 0 13 20z"
      clipRule="evenodd"
    />
  </svg>
);
