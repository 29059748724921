import type { SVGProps } from 'react';
import * as React from 'react';
export const ClipboardFill = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    viewBox="0 0 24 24"
    {...props}>
    <path
      fill={props.fill || `currentColor`}
      d="M10 2a1 1 0 0 0-1 1v2a1 1 0 0 0 1 1h4a1 1 0 0 0 1-1V3a1 1 0 0 0-1-1z"
    />
    <path
      fill={props.fill || `currentColor`}
      d="M6 3a2 2 0 0 0-2 2v15a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V5a2 2 0 0 0-2-2h-2v3c0 .5-.5 1-1 1H9c-.5 0-1-.5-1-1V3z"
    />
  </svg>
);
