/**
 * Generated by orval v7.1.1 🍺
 * Do not edit manually.
 * ZEP QUIZ
 * Zep Quiz API
 * OpenAPI spec version: 0.0.1
 */

export type QuizPlanWithWorkspaceDtoPlanType =
  (typeof QuizPlanWithWorkspaceDtoPlanType)[keyof typeof QuizPlanWithWorkspaceDtoPlanType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const QuizPlanWithWorkspaceDtoPlanType = {
  FREE: 'FREE',
  PRO: 'PRO',
} as const;
