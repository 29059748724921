import type { SVGProps } from 'react';
import * as React from 'react';
export const CameraFill = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    viewBox="0 0 24 24"
    {...props}>
    <path
      fill={props.fill || `currentColor`}
      d="M14 12.5a2 2 0 1 1-4 0 2 2 0 0 1 4 0"
    />
    <path
      fill={props.fill || `currentColor`}
      fillRule="evenodd"
      d="M9 4a1 1 0 0 0-.707.293L6.586 6H4a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V8a2 2 0 0 0-2-2h-2.586l-1.707-1.707A1 1 0 0 0 15 4zm7 8.5a4 4 0 1 1-8 0 4 4 0 0 1 8 0"
      clipRule="evenodd"
    />
  </svg>
);
