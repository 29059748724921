export const logVersion = () => {
  const consoleOptions = 'color: #6758FF; ';

  const arr = [
    '            .-======-:.               ',
    '          .============-...           ',
    '         :====================-.      ',
    '      .:-========================.    ',
    '    :=============================.   ',
    `  .==========:'''''''''':=========:   `,
    '  ==========.            .========:   ',
    '  ==========-.          .-========:   ',
    '  ==============-      -===========:  ',
    '  .===========-.     :==============  ',
    '   ==========.      .===============. ',
    '  :=========.            :==========  ',
    '  :=========-............-========-   ',
    '   =============================-:    ',
    '    -========================-:       ',
    '     .:-====================:         ',
    '              :===========-.          ',
    '                .:--==--:.            ',
    '',
    '  ######## ######## ########  ',
    '       ##  ##       ##     ## ',
    '      ##   ##       ##     ## ',
    '     ##    ######   ########  ',
    `    ##     ##       ##     `,
    `   ##      ##       ##     ${process.env.NEXT_PUBLIC_STAGE ?? ''}`,
    `  ######## ######## ##     ${process.env.NEXT_PUBLIC_COMMIT_SHA ?? ''}`,
    `${process.env.NEXT_PUBLIC_COMMIT_VERSION ?? ''}`,
  ];

  console.log(`%c${arr.join('\n')}`, consoleOptions);
};
