/**
 * Generated by orval v7.1.1 🍺
 * Do not edit manually.
 * ZEP QUIZ
 * Zep Quiz API
 * OpenAPI spec version: 0.0.1
 */
import type {
  DefinedInitialDataOptions,
  DefinedUseQueryResult,
  MutationFunction,
  QueryFunction,
  QueryKey,
  UndefinedInitialDataOptions,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
  UseSuspenseQueryOptions,
  UseSuspenseQueryResult,
} from '@tanstack/react-query';
import { useMutation, useQuery, useSuspenseQuery } from '@tanstack/react-query';

import type { BodyType, ErrorType } from '../../httpClient';
import { httpsInstance } from '../../httpClient';
import type {
  BaseResponseListMapTemplateRes,
  BaseResponseMapTemplateRes,
  CreateMapTemplateReq,
  UpdateMapTemplateReq,
} from '.././_model';

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

export const getAllMapTemplates = (
  options?: SecondParameter<typeof httpsInstance>,
  signal?: AbortSignal,
) => {
  return httpsInstance<BaseResponseListMapTemplateRes>(
    { url: `/admin/mapTemplates`, method: 'GET', signal },
    options,
  );
};

export const getGetAllMapTemplatesQueryKey = () => {
  return [`/admin/mapTemplates`] as const;
};

export const getGetAllMapTemplatesQueryOptions = <
  TData = Awaited<ReturnType<typeof getAllMapTemplates>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getAllMapTemplates>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof httpsInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetAllMapTemplatesQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getAllMapTemplates>>
  > = ({ signal }) => getAllMapTemplates(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getAllMapTemplates>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetAllMapTemplatesQueryResult = NonNullable<
  Awaited<ReturnType<typeof getAllMapTemplates>>
>;
export type GetAllMapTemplatesQueryError = ErrorType<unknown>;

export function useGetAllMapTemplates<
  TData = Awaited<ReturnType<typeof getAllMapTemplates>>,
  TError = ErrorType<unknown>,
>(options: {
  query: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getAllMapTemplates>>,
      TError,
      TData
    >
  > &
    Pick<
      DefinedInitialDataOptions<
        Awaited<ReturnType<typeof getAllMapTemplates>>,
        TError,
        TData
      >,
      'initialData'
    >;
  request?: SecondParameter<typeof httpsInstance>;
}): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetAllMapTemplates<
  TData = Awaited<ReturnType<typeof getAllMapTemplates>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getAllMapTemplates>>,
      TError,
      TData
    >
  > &
    Pick<
      UndefinedInitialDataOptions<
        Awaited<ReturnType<typeof getAllMapTemplates>>,
        TError,
        TData
      >,
      'initialData'
    >;
  request?: SecondParameter<typeof httpsInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetAllMapTemplates<
  TData = Awaited<ReturnType<typeof getAllMapTemplates>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getAllMapTemplates>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof httpsInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useGetAllMapTemplates<
  TData = Awaited<ReturnType<typeof getAllMapTemplates>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getAllMapTemplates>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof httpsInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetAllMapTemplatesQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getGetAllMapTemplatesSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof getAllMapTemplates>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseSuspenseQueryOptions<
      Awaited<ReturnType<typeof getAllMapTemplates>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof httpsInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetAllMapTemplatesQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getAllMapTemplates>>
  > = ({ signal }) => getAllMapTemplates(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof getAllMapTemplates>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetAllMapTemplatesSuspenseQueryResult = NonNullable<
  Awaited<ReturnType<typeof getAllMapTemplates>>
>;
export type GetAllMapTemplatesSuspenseQueryError = ErrorType<unknown>;

export function useGetAllMapTemplatesSuspense<
  TData = Awaited<ReturnType<typeof getAllMapTemplates>>,
  TError = ErrorType<unknown>,
>(options: {
  query: Partial<
    UseSuspenseQueryOptions<
      Awaited<ReturnType<typeof getAllMapTemplates>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof httpsInstance>;
}): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetAllMapTemplatesSuspense<
  TData = Awaited<ReturnType<typeof getAllMapTemplates>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseSuspenseQueryOptions<
      Awaited<ReturnType<typeof getAllMapTemplates>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof httpsInstance>;
}): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetAllMapTemplatesSuspense<
  TData = Awaited<ReturnType<typeof getAllMapTemplates>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseSuspenseQueryOptions<
      Awaited<ReturnType<typeof getAllMapTemplates>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof httpsInstance>;
}): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useGetAllMapTemplatesSuspense<
  TData = Awaited<ReturnType<typeof getAllMapTemplates>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseSuspenseQueryOptions<
      Awaited<ReturnType<typeof getAllMapTemplates>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof httpsInstance>;
}): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetAllMapTemplatesSuspenseQueryOptions(options);

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const updateMapTemplate = (
  updateMapTemplateReq: BodyType<UpdateMapTemplateReq>,
  options?: SecondParameter<typeof httpsInstance>,
) => {
  return httpsInstance<BaseResponseMapTemplateRes>(
    {
      url: `/admin/mapTemplates`,
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      data: updateMapTemplateReq,
    },
    options,
  );
};

export const getUpdateMapTemplateMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateMapTemplate>>,
    TError,
    { data: BodyType<UpdateMapTemplateReq> },
    TContext
  >;
  request?: SecondParameter<typeof httpsInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateMapTemplate>>,
  TError,
  { data: BodyType<UpdateMapTemplateReq> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateMapTemplate>>,
    { data: BodyType<UpdateMapTemplateReq> }
  > = props => {
    const { data } = props ?? {};

    return updateMapTemplate(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateMapTemplateMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateMapTemplate>>
>;
export type UpdateMapTemplateMutationBody = BodyType<UpdateMapTemplateReq>;
export type UpdateMapTemplateMutationError = ErrorType<unknown>;

export const useUpdateMapTemplate = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateMapTemplate>>,
    TError,
    { data: BodyType<UpdateMapTemplateReq> },
    TContext
  >;
  request?: SecondParameter<typeof httpsInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof updateMapTemplate>>,
  TError,
  { data: BodyType<UpdateMapTemplateReq> },
  TContext
> => {
  const mutationOptions = getUpdateMapTemplateMutationOptions(options);

  return useMutation(mutationOptions);
};
export const registerMapTemplate = (
  createMapTemplateReq: BodyType<CreateMapTemplateReq>,
  options?: SecondParameter<typeof httpsInstance>,
) => {
  return httpsInstance<BaseResponseMapTemplateRes>(
    {
      url: `/admin/mapTemplates`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: createMapTemplateReq,
    },
    options,
  );
};

export const getRegisterMapTemplateMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof registerMapTemplate>>,
    TError,
    { data: BodyType<CreateMapTemplateReq> },
    TContext
  >;
  request?: SecondParameter<typeof httpsInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof registerMapTemplate>>,
  TError,
  { data: BodyType<CreateMapTemplateReq> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof registerMapTemplate>>,
    { data: BodyType<CreateMapTemplateReq> }
  > = props => {
    const { data } = props ?? {};

    return registerMapTemplate(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type RegisterMapTemplateMutationResult = NonNullable<
  Awaited<ReturnType<typeof registerMapTemplate>>
>;
export type RegisterMapTemplateMutationBody = BodyType<CreateMapTemplateReq>;
export type RegisterMapTemplateMutationError = ErrorType<unknown>;

export const useRegisterMapTemplate = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof registerMapTemplate>>,
    TError,
    { data: BodyType<CreateMapTemplateReq> },
    TContext
  >;
  request?: SecondParameter<typeof httpsInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof registerMapTemplate>>,
  TError,
  { data: BodyType<CreateMapTemplateReq> },
  TContext
> => {
  const mutationOptions = getRegisterMapTemplateMutationOptions(options);

  return useMutation(mutationOptions);
};
