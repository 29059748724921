import ReactGA from 'react-ga4';
import { EventProperties, zepEventTracker } from '@zep/utils/analytics/';

import { StatefulPQueue } from '../common';

const queue = new StatefulPQueue({ autoStart: false });

function setUserProperties(records: EventProperties) {
  zepEventTracker.setContext(records);
  return Promise.resolve();
}

const trackEvent = (...params: Parameters<ZepAnalyticsType['track']>) => {
  ReactGA.event(String(params[0]), params[1] || {});
};

export const zepAnalytics: ZepAnalyticsType = {
  track: (eventName, eventProperties) => {
    if (!queue.isReady) {
      return queue.add(() => {
        zepEventTracker.track(eventName, eventProperties);
        return trackEvent(eventName, eventProperties);
      });
    } else {
      zepEventTracker.track(eventName, eventProperties);
      return trackEvent(eventName, eventProperties);
    }
  },

  setAmplitudeUserId: (userId: string) => {
    setUserProperties({ IS_LOGIN: true });
    zepEventTracker.setUserId(userId);
  },

  resetAmplitudeUserId: () => {
    setUserProperties({ IS_LOGIN: false });
    zepEventTracker.setUserId(undefined);
  },

  setDefaultUserProperties: () => {
    setUserProperties({}).then(() => {
      queue.setReady(true).start();
    });
  },
};

type ZepAnalyticsType = {
  track: (eventName: string, eventProperties?: EventProperties) => void;
  setAmplitudeUserId: (userId: string) => void;
  resetAmplitudeUserId: () => void;
  setDefaultUserProperties: () => void;
};
