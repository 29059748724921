import type { SVGProps } from 'react';
import * as React from 'react';
export const GlobeFill = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    viewBox="0 0 24 24"
    {...props}>
    <path
      fill={props.fill || `currentColor`}
      d="M11.121 2.038A10.01 10.01 0 0 0 2.832 8h5.126c.722-2.403 1.984-4.462 3.163-5.962M2.2 10a10.05 10.05 0 0 0 0 4h5.288a13.8 13.8 0 0 1 .012-3.965L7.505 10zM2.832 16a10.01 10.01 0 0 0 8.275 5.96C9.52 19.988 8.49 17.975 7.917 16zM12 22v-1.873A16 16 0 0 0 14.086 16H9.772c.437 1.365 1.146 2.761 2.207 4.157l.021-.03zM12.879 21.962A10.01 10.01 0 0 0 21.168 16h-5.126c-.723 2.404-1.984 4.462-3.163 5.962M21.8 14a10.1 10.1 0 0 0 0-4h-5.288a13.8 13.8 0 0 1-.012 3.965l-.005.035zM21.168 8h-5.086c-.572-1.974-1.6-3.987-3.19-5.96A10.01 10.01 0 0 1 21.169 8M12 3.873V8H9.914A16 16 0 0 1 12 3.873M12 3.873V8h2.228c-.437-1.365-1.146-2.761-2.207-4.157zM14.663 13.601q-.03.201-.065.399H12v-4h2.687c.176 1.251.146 2.46-.024 3.601M12 10v4H9.313a12.5 12.5 0 0 1 .09-4z"
    />
  </svg>
);
