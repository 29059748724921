/**
 * Generated by orval v7.1.1 🍺
 * Do not edit manually.
 * ZEP QUIZ
 * Zep Quiz API
 * OpenAPI spec version: 0.0.1
 */
import type {
  MutationFunction,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import { useMutation } from '@tanstack/react-query';

import type { BodyType, ErrorType } from '../../httpClient';
import { httpsInstance } from '../../httpClient';
import type {
  BaseResponseAuthRes,
  GetAllMyQuizzesParams,
  SearchResponseListSearchMyQuizResV1,
  SetMemberInfoReq,
  SuccessResponse,
  UpdateMemberNameReq,
} from '.././_model';

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

export const getAllMyQuizzes = (
  params?: GetAllMyQuizzesParams,
  options?: SecondParameter<typeof httpsInstance>,
) => {
  return httpsInstance<SearchResponseListSearchMyQuizResV1>(
    { url: `/members/myquiz`, method: 'POST', params },
    options,
  );
};

export const getGetAllMyQuizzesMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof getAllMyQuizzes>>,
    TError,
    { params?: GetAllMyQuizzesParams },
    TContext
  >;
  request?: SecondParameter<typeof httpsInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof getAllMyQuizzes>>,
  TError,
  { params?: GetAllMyQuizzesParams },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof getAllMyQuizzes>>,
    { params?: GetAllMyQuizzesParams }
  > = props => {
    const { params } = props ?? {};

    return getAllMyQuizzes(params, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type GetAllMyQuizzesMutationResult = NonNullable<
  Awaited<ReturnType<typeof getAllMyQuizzes>>
>;

export type GetAllMyQuizzesMutationError = ErrorType<unknown>;

export const useGetAllMyQuizzes = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof getAllMyQuizzes>>,
    TError,
    { params?: GetAllMyQuizzesParams },
    TContext
  >;
  request?: SecondParameter<typeof httpsInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof getAllMyQuizzes>>,
  TError,
  { params?: GetAllMyQuizzesParams },
  TContext
> => {
  const mutationOptions = getGetAllMyQuizzesMutationOptions(options);

  return useMutation(mutationOptions);
};
export const setMemberInfo = (
  setMemberInfoReq: BodyType<SetMemberInfoReq>,
  options?: SecondParameter<typeof httpsInstance>,
) => {
  return httpsInstance<SuccessResponse>(
    {
      url: `/members/memberInfo`,
      method: 'PATCH',
      headers: { 'Content-Type': 'application/json' },
      data: setMemberInfoReq,
    },
    options,
  );
};

export const getSetMemberInfoMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof setMemberInfo>>,
    TError,
    { data: BodyType<SetMemberInfoReq> },
    TContext
  >;
  request?: SecondParameter<typeof httpsInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof setMemberInfo>>,
  TError,
  { data: BodyType<SetMemberInfoReq> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof setMemberInfo>>,
    { data: BodyType<SetMemberInfoReq> }
  > = props => {
    const { data } = props ?? {};

    return setMemberInfo(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type SetMemberInfoMutationResult = NonNullable<
  Awaited<ReturnType<typeof setMemberInfo>>
>;
export type SetMemberInfoMutationBody = BodyType<SetMemberInfoReq>;
export type SetMemberInfoMutationError = ErrorType<unknown>;

export const useSetMemberInfo = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof setMemberInfo>>,
    TError,
    { data: BodyType<SetMemberInfoReq> },
    TContext
  >;
  request?: SecondParameter<typeof httpsInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof setMemberInfo>>,
  TError,
  { data: BodyType<SetMemberInfoReq> },
  TContext
> => {
  const mutationOptions = getSetMemberInfoMutationOptions(options);

  return useMutation(mutationOptions);
};
export const editMemberName = (
  updateMemberNameReq: BodyType<UpdateMemberNameReq>,
  options?: SecondParameter<typeof httpsInstance>,
) => {
  return httpsInstance<BaseResponseAuthRes>(
    {
      url: `/members/edit`,
      method: 'PATCH',
      headers: { 'Content-Type': 'application/json' },
      data: updateMemberNameReq,
    },
    options,
  );
};

export const getEditMemberNameMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof editMemberName>>,
    TError,
    { data: BodyType<UpdateMemberNameReq> },
    TContext
  >;
  request?: SecondParameter<typeof httpsInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof editMemberName>>,
  TError,
  { data: BodyType<UpdateMemberNameReq> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof editMemberName>>,
    { data: BodyType<UpdateMemberNameReq> }
  > = props => {
    const { data } = props ?? {};

    return editMemberName(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type EditMemberNameMutationResult = NonNullable<
  Awaited<ReturnType<typeof editMemberName>>
>;
export type EditMemberNameMutationBody = BodyType<UpdateMemberNameReq>;
export type EditMemberNameMutationError = ErrorType<unknown>;

export const useEditMemberName = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof editMemberName>>,
    TError,
    { data: BodyType<UpdateMemberNameReq> },
    TContext
  >;
  request?: SecondParameter<typeof httpsInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof editMemberName>>,
  TError,
  { data: BodyType<UpdateMemberNameReq> },
  TContext
> => {
  const mutationOptions = getEditMemberNameMutationOptions(options);

  return useMutation(mutationOptions);
};
