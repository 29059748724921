import type { SVGProps } from 'react';
import * as React from 'react';
export const ExclamationmarkSmallTriangle = (
  props: SVGProps<SVGSVGElement>,
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    viewBox="0 0 24 24"
    {...props}>
    <path
      fill={props.fill || `currentColor`}
      d="M12 13a1 1 0 0 1-1-1V9a1 1 0 1 1 2 0v3a1 1 0 0 1-1 1M10.5 15.5a1.5 1.5 0 1 1 3 0 1.5 1.5 0 0 1-3 0"
    />
    <path
      fill={props.fill || `currentColor`}
      d="M10.23 2.215c.75-1.425 2.79-1.425 3.54 0l8.344 15.853c.701 1.332-.265 2.931-1.77 2.931H3.657c-1.505 0-2.47-1.6-1.77-2.931zm10.114 16.784L12.001 3.146 3.657 19z"
    />
  </svg>
);
